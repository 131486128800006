body{
    &.page-template-template-documents{
        .documents__wrapper{
            padding: 55px 0 80px;
        }
        .document-quote{
            &__wrapper{
                background-color: $primary;
                color: #fff;
                padding: 45px;
                font-size: 18px;
                margin-top: 30px;
                @include media-breakpoint-up(lg) {
                    margin-top: 0;
                }
                em{
                    font-size: 14px;
                }
            }
        }
    }
}
