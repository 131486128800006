body.home {
	.page-highlights {
        padding-bottom: 30px;
        padding-top: 28px;
        @include media-breakpoint-up(lg) {
            padding-bottom: 0;
        }
		.title {
			font-family: $font-secondary;
			font-weight: $font-secondary-bold;
			font-size: 19px;
			color: $blue-primary;
            @include media-breakpoint-up(lg) {
                font-size: 24px;
            }
			&:after {
                content: "";
                background-color: $primary;
				display: block;
				width: 80px;
				height: 2px;
                margin: 7px 0 25px;
                @include media-breakpoint-up(lg) {
                    margin: 10px 0 40px;
                    height: 3px;
                }
			}
		}
		.content {
			margin: 25px 0 30px;
            line-height: 1.25;
            h3{
                font-size: 18px;
            }
		}
		a {
			font-family: $font-secondary;
            font-weight: $font-secondary-bold;
            display: inline-block;
            font-size: 13px;
        }
        .link {
            // @extend .link-arrow;
            // color: $primary;
        }
        .campus{
            padding-bottom: 50px;
            @include media-breakpoint-up(lg) {
                padding-bottom: 95px;
            }
        }
	}
	.news-home {
		width: 100%;
		// height: 100%;
        position: relative;
        padding-top: 40px;
        margin-top: -28px;
        @include media-breakpoint-up(lg) {
            padding-top: 0;
        }
		ul {
            @include media-breakpoint-up(lg) {
                position: absolute;
                width: 100%;
                transform: translateY(-100%);
                align-items: stretch;
            }
			>:nth-child(2) {
				margin: 0 3px;
			}
			li {
				width: 25%;
				a {
                    color: $white;
                    height: 100%;
                    display: block;
					&.all-tab {
						background-color: $primary;
					}
					&.mtl-tab {
						background-color: $mtl-color;
					}
					&.south-tab {
						background-color: $south-color;
					}
				}
			}
		}
		.tab-content {
			height: calc(100%);
			color: $white;
			.tab-pane {
                padding: 20px 20px 60px;
                @include media-breakpoint-up(xl) {
                    padding: 25px 30px 60px;
                    height: 100%;
                }
				a {
					color: $white;
					&.event-tile {
						text-decoration: none;
                        padding: 2px 0 21px;
                        @include media-breakpoint-up(xl) {
                            padding: 12px 0 20px;
                        }
						.date {
                            font-size: 13px;
                            font-weight: 500;
						}
						.news-title {
                            font-size: 16px;
                            font-weight: 700;
                            line-height: 1.2;
                            margin-bottom: 7px;
                            @include media-breakpoint-up(xl) {
                                font-size: 21px;
                                margin-bottom: 10px;
                            }
						}
						.link {
							font-size: 0.75rem;
							padding-left: 18px;
                            position: relative;
                            &:before {
								position: absolute;
								top: -2px;
								left: 0;
								content: "\2014";
								margin-right: 10px;
								text-decoration: none;
							}
							&:hover {
								text-decoration: underline;
								&:before {
									text-decoration: none;
								}
							}
						}
					}
					&.all-event-link {
						position: absolute;
						width: 100%;
						bottom: 0;
						left: 0;
						display: block;
						border-top: 1px solid rgba($white, 0.4);
                        padding: 15px 20px;
                        @include media-breakpoint-up(lg) {
                            padding: 15px 30px;
                        }
                        span{
                            @extend .link-arrow;
                        }
					}
				}
				p.event-title {
					font-family: $font-secondary;
					font-weight: $font-secondary-bold;
                    font-size: 19px;
                    text-transform: uppercase;
                    margin-bottom: 12px;
                    @include media-breakpoint-up(xl) {
                        font-size: 24px;
                        margin-bottom: 15px;
                    }
				}
				&#all-news {
					background-color: $primary;
				}
				&#mtl-news {
					background-color: $mtl-color;
				}
				&#south-news {
					background-color: $south-color;
				}
			}
		}
    }
    .programs-type{
        background-color: $grey;
        padding-top: 30px;
        // padding-bottom: 20px;
        @include media-breakpoint-up(lg) {
            padding-top: 70px;
            padding-bottom: 70px;
        }
        &__item{
            background-color: #fff;
            display: block;
            text-decoration: none;
            transition: all ease .3s;
            height: 100%;
            &:hover{
                background-color: $blue-primary;
                .programs-type__item--title{
                    color:#fff;
                }
            }
            &-wrapper{
                margin-bottom: 30px;
                @include media-breakpoint-up(lg) {
                    margin-bottom: 0;
                }
            }
            &--image{
                margin-bottom: 15px;
                @include media-breakpoint-up(md) {
                    margin-bottom: 23px;
                }
            }
            &--title{
                font-family: $font-secondary;
                font-size: 17px;
                font-weight: $font-secondary-bold;
                line-height: 1.2;
                color: $blue-primary;
                padding: 0 17px;
                display: block;
                transition: all ease .3s;
                @include media-breakpoint-up(md) {
                    padding: 0 24px;
                }
                @include media-breakpoint-only(lg) {
                    padding: 0 18px 55px;
                    font-size: 17px;
                }
                @include media-breakpoint-up(xl) {
                    padding: 0 24px 60px;
                    font-size: 23px;
                }
            }
            &--link{
                font-family: $font-secondary;
                font-size: 12px;
                font-weight: $font-secondary-bold;
                line-height: 1.2;
                color: $primary;
                text-decoration: none;
                display: inline-block;
                padding: 10px 24px 20px;
                transition: all ease .3s;
                @include media-breakpoint-up(lg) {
                    position: absolute;
                    bottom: 27px;
                    left: 40px;
                    padding: 0;
                }
                &:before{
                    content: "—";
                    padding-right: 5px;
                }
            }
        }
        &__links{
            margin-top: 54px;
            font-family: $font-secondary;
            font-size: 14px;
            font-weight: $font-secondary-bold;
            line-height: 1.2;
            color: $blue-primary;
            .mtl{
                color: $mtl-color;
            }
            .south-shore{
                color: $south-color;
            }
        }
        .wysiwyg{
            h4{
                @extend .secondary-subtitle;
                @include media-breakpoint-down(md) {
                    margin-bottom: 10px;
                }
            }
            h2{
                @extend .primary-title;
                margin-bottom: 30px;
                @include media-breakpoint-up(lg) {
                    margin-bottom: 56px;
                }
            }
        }
    }
    .about {
        background-color: #f2f2f2;
        padding: 60px 0;
        .wysiwyg{
            margin-bottom: 20px;
            @include media-breakpoint-up(lg) {
                margin-bottom: 0;
            }
            h2{
                margin-bottom: 30px;
            }
            a{
                @extend .primary-button;
                @extend .arrow;
                margin-top: 20px;
            }
        }
    }
    .latests-news{
        padding: 50px 0 50px;
        @include media-breakpoint-up(lg) {
            padding: 65px 0 55px;
        }
        h2{
            @include media-breakpoint-up(lg) {
                padding-right: 40px;
            }
        }
        &__single{
            margin-top: 25px;
            @include media-breakpoint-up(lg) {
                margin-top: 50px;
            }
            &--date{
                font-family: $font-secondary;
                font-size: 14px;
                font-weight: $font-secondary-semibold;
                line-height: 1.2;
                color: $primary;
                margin-bottom: 15px;
            }
            &--title{
                font-family: $font-secondary;
                font-size: 17px;
                font-weight: $font-secondary-bold;
                line-height: 1.2;
                color: $blue-primary;
                margin-bottom: 12px;
                @include media-breakpoint-up(lg) {
                    font-size: 21px;
                    margin-bottom: 15px;
                }
            }
            &--excerpt{
                font-family: $font-family-base;
                font-size: 14px;
                font-weight: 400;
                line-height: 1.25;
                color: $blue-primary;
                margin-bottom: 10px;
                @include media-breakpoint-up(lg) {
                    margin-bottom: 30px;
                }
            }
            &--link{
                font-family: $font-secondary;
                font-size: 12px;
                font-weight: $font-secondary-bold;
                line-height: 1.2;
                color: $primary;
                display: inline-block;
                margin-bottom: 10px;
                @include media-breakpoint-up(lg) {
                    font-size: 14px;
                    margin-bottom: 30px;
                }
            }
        }
        .informations-title{
            margin-top: 50px;
            @include media-breakpoint-up(md) {
                margin-top: 0;
            }
        }
    }
}
