.commentsyrendre{
    &__wrapper{
        padding: 30px 0;
        @include media-breakpoint-up(lg) {
            padding: 65px 0;
        }
        @include media-breakpoint-up(xl) {
            padding: 65px 15px 65px 4vw;
        }
    }
    &__single{
        padding: 2vw 0 4vw;
        &--title{
            @extend .primary-title;
        }
        &--map{
            height: 400px;
            margin-bottom: 75px;
        }
        &--mapLink{
            font-weight: 700;
            margin-top: 15px;
            margin-bottom: 45px;
            display: inline-block;
        }
        &--transport{
            font-family: $font-secondary;
            font-size: 16px;
            font-weight: 700;
            line-height: 1.2;
            color: #06204c;
            padding: 25px 0;
            img{
                width: 22px;
                margin-right: 10px;
            }
        }
        &--infos{
            a{
                font-weight: 700;
            }
        }
    }
}
