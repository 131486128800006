body {
	-webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    &.admin-bar {
        header.banner{
            top: 30px;
        }
    }
}

.brand {
	display: inline-block;
	max-width: 220px;
    width: 100%;
    @include media-breakpoint-up(lg) {
        max-width: 250px;
    }
    @include media-breakpoint-up(xl) {
        max-width: 290px;
    }
}

// .arrow-right-after {
// 	&:after {
// 		position: absolute;
// 		font-family: $icons-font-family;
// 		content: $icon-right-arrow;
// 		margin: 1px 0 0 18px;
// 	}
// }

// Title
.primary-title{
    font-family: $font-secondary;
    font-size: 25px;
    font-weight: $font-secondary-bold;
    line-height: 1.2;
    color: $blue-primary;
    @include media-breakpoint-up(lg) {
        font-size: 30px;
    }
}
.secondary-title{
    font-family: $font-secondary;
    font-size: 19px;
    font-weight: $font-secondary-bold;
    line-height: 1.2;
    color: $blue-primary;
    text-transform: uppercase;
    @include media-breakpoint-up(lg) {
        font-size: 21px;
    }
    @include media-breakpoint-up(xl) {
        font-size: 24px;
    }
}
.primary-subtitle{
    font-family: $font-secondary;
    font-size: 15px;
    font-weight: $font-secondary-bold;
    line-height: 1.2;
    color: $blue-primary;
}
.secondary-subtitle{
    font-family: $font-secondary;
    font-size: 15px;
    font-weight: $font-secondary-light;
    line-height: 1.2;
    color: $blue-primary;
}

.wysiwyg{
    font-family: $font-family-base;
    font-size: 14px;
    color: $blue-primary;
    h1{
        @extend .primary-title;
        margin-bottom: 20px;
    }
    h2{
        @extend .secondary-title;
        margin-bottom: 20px;
    }
    h3{
        @extend .primary-subtitle;
        margin-bottom: 15px;
    }
    h4{
        font-family: $font-family-base;
        font-size: 14px;
        font-weight: $font-base-bold;
        line-height: 1.2;
        color: $blue-primary;
        margin-bottom: 15px;
    }
    a{
        text-decoration: underline;
        font-weight: bolder;
    }
    p{
        & + h1, & + h2, & + h3, & + h4{
            margin-top: 30px;
        }
    }
    b, strong{
        font-weight: 700;
    }
    ul{
        list-style-type: none;
        padding: 0 0 1rem 0;
        margin: 0;
        li{
            padding-left: 1.5em;
            position: relative;
            padding-bottom: 6px;
        }
        & > li{
            &:before{
                content: '—';
                padding-right: 5px;
                color: $primary;
                position: absolute;
                left: 0;
            }
        }
        ul,ol{
            padding-left: 0;
            padding-top: 15px;
            @include media-breakpoint-up(lg) {
                padding-left: 20px;
            }
        }
        & + h1, & + h2, & + h3, & + h4{
            margin-top: 25px;
        }
    }
    ol{
        & > li{
            padding-left: 0 !important;
        }
    }
    blockquote{
        border: 2px solid $primary;
        padding: 30px 30px 20px;
        font-family: $font-secondary;
        font-size: 15px;
        font-weight: $font-secondary-bold;
        line-height: 1.3;
        color: $primary;
        margin-top: 30px;
        margin-bottom: 30px;
    }
    img{
        @extend .img-fluid;
    }
    hr{
        margin: 20px 0;
        @include media-breakpoint-up(lg) {
            margin: 35px 0;
        }
    }
    &.blue-check{
        ul {
            li{
                padding-left: 2em;
                padding-bottom: 13px;
                @include media-breakpoint-up(lg) {
                    padding-left: 2.2em;
                    padding-bottom: 20px;
                }
                &:before{
                    content: $icon-check;
                    font-family: '#{$icons-font-family}' !important;
                    font-size: 12px;
                    padding-right: 5px;
                    color: $primary;
                    position: absolute;
                    left: 0;
                    @include media-breakpoint-up(lg) {
                        font-size: 14px;
                    }
                }
            }
        }
    }
    .gallery.slick-slider {
        margin-bottom: 30px;
        @include media-breakpoint-up(lg) {
            margin-bottom: 50px;
        }
        .gallery-item {
            position: relative;
            padding-bottom: 60%;
            img {
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
            }
        }
        .slick-prev,
        .slick-next {
            position: absolute;
            z-index: 999;
            display: block;
            height: 30px;
            width: 30px;
            cursor: pointer;
            background: $primary;
            color: #FFF;
            top: 100%;
            right: 0;
            padding: 0;
            border: none;
            outline: none;
            transform: translateY(-100%);
            &:hover, &:focus {
                outline: none;
                background: $primary;
            }
            &.slick-disabled:before {
                opacity: 0.5;
            }
            i{
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
            }
        }

        .slick-prev {
            transform: rotate(180deg) translateY(100%);
            right: 30px;
        }

        .slick-next {
            right: 0;
        }
    }
}
.wysiwyg-blue-box{
    @extend .wysiwyg;
    border: 4px solid $primary;
    padding: 40px 30px;
    margin-top: 50px;
    ul{
        padding: 10px 0 0 0;
        margin: 0;
        list-style-type: none;
        li{
            padding: 6px 0;
            &::before{
                display: none;
            }
            a{
                font-family: $font-family-base;
                font-size: 14px;
                font-weight: $font-base-bold;
                line-height: 1.2;
                color: $primary;
                text-decoration: underline;
            }
        }
    }
}

// Buttons{
button, a{
    &:focus{
        outline: 0;
    }
}

.primary-button{
    @include reset-button;
    font-family: $font-secondary;
    font-weight: $font-secondary-bold;
    font-size: 14px;
    padding: 14px 19px 14px;
    background-color: $primary;
    color: #fff;
    border-radius: 0;
    display: inline-block;
    transition: all ease .2s;
    text-decoration: none;
    position: relative;
    &:hover{
        background-color: $blue-primary;
        color: #fff;
        text-decoration: none;
    }
    &.white{
        background-color: #fff;
        color: $primary;
        &:hover{
            background-color: $blue-primary;
            color: #fff;
        }
    }
    &.wide{
        width: 100%;
        display: block;
        text-align: center;
    }
    &.arrow{
        padding: 18px 50px 18px 19px;
        &:before{
            content: $icon-right-arrow;
            font-family: '#{$icons-font-family}' !important;
            position: absolute;
            right: 19px;
            top: 50%;
            transform: translateY(-50%);
            transition: all ease .2s;
        }
        &:hover{
            &:before{
                transform: translateX(5px) translateY(-50%);
            }

        }
    }
}

.link-arrow{
    font-family: $font-secondary;
    font-weight: $font-secondary-bold;
    font-size: 14px;
    line-height: 1.2;
    display: inline-block;
    position: relative;
    padding-right: 14px;
    &:before{
        content: $icon-right-arrow;
        font-family: '#{$icons-font-family}' !important;
        position: absolute;
        left: 100%;
        top: 50%;
        transform: translateY(-50%);
        transition: all ease .2s;
    }
    &:hover{
        &:before{
            transform: translateX(5px) translateY(-50%);
        }

    }
    &.blue{
        color: $primary;
    }
}

.newsletterBackdrop{
    .modal-backdrop {
        &.show {
            opacity: 0.7;
        }
    }
}
.modal-backdrop {
    background-color: $blue-primary;
    &.show {
        opacity: 0.7;
    }
}
