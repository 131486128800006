body.page-template-template-NousJoindre{
    .nousjoindre{
        &__wrapper{
            padding: 30px 0;
            @include media-breakpoint-up(lg) {
                padding: 50px 0;
            }
            @include media-breakpoint-up(xl) {
                padding: 65px 0 115px;
            }
        }
        &__intro{
            font-family: $font-secondary;
            font-size: 21px;
            font-weight: $font-secondary-light;
            line-height: 1.2;
            color: $blue-primary;
            padding-bottom: 20px;
            @include media-breakpoint-up(lg) {
                padding-bottom: 35px;
                font-size: 27px;
            }
            @include media-breakpoint-up(xl) {
                padding-bottom: 50px;
                font-size: 30px;
            }
        }
        &__single{
            border: solid 2px $grey;
            padding: 25px 20px 10px;
            height: calc(100% - 30px);
            margin-bottom: 30px;
            @include media-breakpoint-up(lg) {
                padding: 31px 25px;
            }
            &--title{
                font-family: $font-family-base;
                font-size: 24px;
                font-weight: $font-base-bold;
                line-height: 1.2;
                color: $blue-primary;
                position: relative;
                @include blue-underline;
            }
            &--map{
                font-family: $font-family-base;
                font-size: 14px;
                font-weight: $font-base-bold;
                line-height: 1.2;
                color: $primary;
                display: inline-block;
                margin-top: 0;
                margin-bottom: 20px;
                @include media-breakpoint-up(lg) {
                    margin-top: 15px;
                    margin-bottom: 30px;
                }
            }
            &--phone{
                display: flex;
                align-items: flex-start;
                justify-content: space-between;
                & > div{
                    @include media-breakpoint-up(lg) {
                        margin-bottom: 15px;
                    }
                    &:last-child{
                        text-align: right;
                    }
                }
            }
        }
        &__hightlighted{
            border: solid 2px $primary;
            padding: 31px 25px;
            height: calc(100% - 30px);
            margin-bottom: 30px;
            background: $primary;
            color: #fff;
            &--title{
                font-family: $font-family-base;
                font-size: 24px;
                font-weight: $font-base-bold;
                line-height: 1.2;
                color: #fff;
                position: relative;
                @include blue-underline;
                &:before{
                    background-color: #fff;
                }
            }
            .primary-button{
                text-align: center;
                min-width: 150px;
                margin-top: 26px;
                margin-bottom: 46px;
            }
        }
    }
}
