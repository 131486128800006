.aide_admission {
    &__wrapper {
        background-color: $primary;
        width: 100%;
        border: solid 3px $primary;
        padding: 25px;
        margin-bottom: 33px;
        color: #fff;
        @include media-breakpoint-only(md) {
            height: 100%;
        }
        @include media-breakpoint-up(lg) {
            padding: 31px 30px;
        }
        a{
            @extend .primary-button;
            @extend .primary-button.white;
            margin-top: 25px;
        }
    }
    &__title {
        font-family: $font-secondary;
        font-size: 19px;
        font-weight: $font-secondary-bold;
        line-height: 1.2;
        color: #fff;
        text-transform: uppercase;
        position: relative;
        @include blue-underline;
        @include media-breakpoint-up(lg) {
            font-size: 24px;
        }
    }
}
