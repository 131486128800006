.article-share{
    &__wrapper{
        width: 95px;
    }
    &__button{
        @include reset-button;
        background-color: $primary;
        font-family: $font-family-base;
        font-size: 10px;
        font-weight: $font-base-semibold;
        line-height: 1;
        color: #fff;
        padding: 9px 15px 8px 35px;
        border-radius: 1.5px;
        width: 100%;
        text-align: left;
        position: relative;
        z-index: 1001;
        &:after{
            display: none;
        }
        &:hover{
            background-color: darken($primary, 8%);
        }
        &[aria-expanded=true]{
            background-color: darken($primary, 8%);
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
        }
        i{
            position: absolute;
            left: 10px;
            top: 50%;
            transform: translateY(-50%);
            font-size: 12px;
            width: 14px;
            text-align: center;
        }
    }
    &__dropdown{
        min-width: 0;
        width: 100%;
        padding: 0;
        margin: 0;
        border: 0;
        border-radius: 0;
        background-color: $primary;
        &:before{
            content: '';
            background-color: $primary;
            width: 100%;
            height: 5px;
            position: absolute;
            bottom: calc(100% - 2px);
            left: 0;
            z-index: -1;
        }
        .dropdown-item{
            padding: 7px 15px 7px 35px;
            display: block;
            text-align: left;
            font-family: $font-family-base;
            font-size: 10px;
            font-weight: $font-base-semibold;
            line-height: 1;
            color: #fff;
            position: relative;
            &:hover{
                background-color: darken($primary, 8%);
            }
            i{
                position: absolute;
                left: 10px;
                top: 50%;
                transform: translateY(-50%);
                font-size: 10px;
                width: 14px;
                text-align: center;
            }
        }
    }
}
