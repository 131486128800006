body{
    &.page-template-template-agentInternationaux{
        .agentInternationaux__wrapper{
            padding: 55px 0 80px;
            .wpcf7-form{
                margin-top: 40px;
            }
        }
    }
}
