body{
    &.page-template-template-Admission{
        .admission{
            &__wrapper{
                padding-top: 30px;
                padding-bottom: 20px;
                @include media-breakpoint-up(lg) {
                    padding-top: 75px;
                    padding-bottom: 75px;
                }
            }
        }
        .boite_sidebar{
            &__wrapper{
                padding: 47px 42px 35px;
                background-color: $primary;
                color: #fff;
                margin-top: 75px;
            }
            &__title{
                // position: relative;
                // padding-bottom: 30px;
                // margin-bottom: 56px;
                font-family: $font-secondary;
                font-size: 24px;
                font-weight: $font-secondary-bold;
                line-height: 1.2;
                color: #fff;
                text-transform: uppercase;
                @include blue-underline;
            }
            &__wysiwyg{
                color: #fff;
                ul {
                    list-style-type: none;
                    padding: 0;
                    margin: 0;
                    li{
                        padding-left: 2.2em;
                        position: relative;
                        padding-bottom: 20px;
                        &:before{
                            content: $icon-check;
                            font-family: '#{$icons-font-family}' !important;
                            padding-right: 5px;
                            color: $blue-primary;
                            position: absolute;
                            left: 0;
                        }
                    }
                }
                a{
                    @extend .primary-button;
                    @extend .primary-button.white;
                }
            }
        }
    }
}
