// Grid settings
$main-sm-columns:       12;
$sidebar-sm-columns:    4;

// Icons
$icons-font-family: "teccart" !default;
$icons-font-path: "../fonts/" !default;

$icon-pdf: "\e900";
$icon-down-arrow: "\e901";
$icon-right-arrow: "\e902";
$icon-check: "\e903";
$icon-facebook: "\e904";
$icon-mail: "\e905";
$icon-moins: "\e906";
$icon-plus: "\e907";
$icon-share: "\e908";
$icon-facebook_logo: "\e909";
$icon-instagram_logo: "\e90a";

// Fonts
$font-family-base: 'Open Sans', sans-serif;
$font-base-semibold: 700;
$font-base-bold: 700;
$font-size-base: 0.875rem;
$font-secondary: 'Montserrat', sans-serif;
$font-secondary-light: 300;
$font-secondary-normal: 400;
$font-secondary-medium: 500;
$font-secondary-semibold: 600;
$font-secondary-bold: 700;

// Colors
$primary: #0787e6;
$blue-primary: #06204c;
$grey: #ebeef1;
$white: #FFFFFF;
$mtl-color: #F78505;
$south-color: #C61C78;
