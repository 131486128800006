body.page-template-template-projetEducatif{
    .projetEducatif{
        &__wrapper{
            padding: 30px 0;
            @include media-breakpoint-up(lg) {
                padding: 45px 0;
            }
            @include media-breakpoint-up(xl) {
                padding: 65px 0 85px;
            }
        }
        &__intro{
            font-family: $font-secondary;
            font-size: 18px;
            font-weight: $font-secondary-light;
            line-height: 1.2;
            color: $blue-primary;
            padding-bottom: 14px;
            @include media-breakpoint-up(lg) {
                font-size: 25px;
                padding-bottom: 30px;
            }
            @include media-breakpoint-up(xl) {
                font-size: 30px;
                padding-bottom: 50px;
            }
        }
        &__single{
            padding: 30px 20px 10px;
            border: 1px solid $grey;
            position: relative;
            margin-bottom: 30px;
            @include media-breakpoint-up(md) {
                padding: 30px 30px 10px;
            }
            @include media-breakpoint-up(lg) {
                height: 100%;
                padding: 45px 30px 60px;
                margin-bottom: 0;
            }
            &--icon{
                width: 32px;
                position: absolute;
                top: 30px;
                left: 20px;
                @include media-breakpoint-up(md) {
                    left: 30px;
                }
                @include media-breakpoint-up(lg) {
                    width: 50px;
                    top: 45px;
                    left: 30px;
                }
            }
            &--title{
                font-family: $font-secondary;
                font-size: 17px;
                font-weight: $font-secondary-bold;
                line-height: 1.2;
                color: $blue-primary;
                margin-bottom: 30px;
                padding-left: 50px;
                @include media-breakpoint-up(lg) {
                    margin-bottom: 66px;
                    padding-left: 75px;
                    font-size: 20px;
                }
            }
        }
    }
}
