body.home {
	.news-home {
		ul {
			li {
				a {
					&.brossard-tab {
						background-color: $primary;
					}
					&.longueuil-tab {
						background-color: $mtl-color;
					}
					&.candiac-tab {
						background-color: $south-color;
					}
				}
			}
		}
		.tab-content {
			.tab-pane {
				&#brossard-news {
					background-color: $primary;
				}
				&#longueuil-news {
					background-color: $mtl-color;
				}
				&#candiac-news {
					background-color: $south-color;
				}
			}
		}
	}
}