.program-sidebar-sticky{
    @include media-breakpoint-down(lg) {
        position: sticky;
        top: -20px;
        background-color: #fff;
        z-index: 1;
        padding-bottom: 5px;
    }
    &:before{
        @include media-breakpoint-up(lg) {
            content: '';
            position: absolute;
            width: 50vw;
            height: 100%;
            top: 0;
            right: -30px;
            z-index: -1;
            background-color: $grey;
        }
    }
}
.programmeDetails{
    &__sidebar{
        position: relative;
        padding-top: 30px;
        @include media-breakpoint-up(lg) {
            padding-top: 65px;
        }
        &--title{
            font-family: $font-secondary;
            font-size: 15px;
            font-weight: $font-secondary-bold;
            line-height: 1.2;
            color: $blue-primary;
            margin-bottom: 15px;
            text-transform: uppercase;
        }
        &--button{
            font-size: 12px;
            padding: 12px 15px 12px;
        }
        &--nav{
            padding: 0;
            margin: 0 0 80px 0;
            list-style: none;
            width: 100%;
            @include media-breakpoint-up(lg) {
                display: block;
                position: relative !important;
                float: none;
                background: transparent;
                border: 0;
                width: auto;
                transform: none !important;
            }
            li{
                @include media-breakpoint-up(lg) {
                    margin-bottom: 15px;
                }
                a{
                    font-family: $font-secondary;
                    font-size: 15px;
                    font-weight: $font-secondary-normal;
                    line-height: 1.2;
                    color: $blue-primary;
                    padding: 8px 15px;
                    @include media-breakpoint-up(lg) {
                        white-space: initial;
                        padding: 0;
                        display: inline-block;
                        width: auto;
                    }
                    &:before{
                        content: '—';
                        padding-right: 5px;
                        display: none;
                    }
                    &:hover, &:focus{
                        background: $primary;
                        color:#fff;
                        @include media-breakpoint-up(lg) {
                            background: transparent;
                            color: $blue-primary;
                        }
                    }
                    &.active{
                        font-weight: $font-secondary-bold;
                        color:#fff;
                        @include media-breakpoint-up(lg) {
                            color: $primary;
                            background: transparent;
                            &:before{
                                display: inline-block;
                            }
                        }
                    }
                }
            }
        }
        &--actions{
            h3{
                font-family: $font-secondary;
                font-size: 15px;
                font-weight: $font-secondary-medium;
                line-height: 1.2;
                color: $blue-primary;
                margin-bottom: 25px;
            }
            .primary-button{
                margin-bottom: 20px;
            }
        }
        .article-share__wrapper{
            margin-bottom: 5px;
            @include media-breakpoint-up(md) {
                margin-bottom: 0;
            }
            @include media-breakpoint-up(lg) {
                margin-bottom: 50px;
            }
        }
    }
}
