// Search form
.search-form {
    @extend .form-inline;
}
.search-form label {
    font-weight: normal;
    @extend .form-group;
}
.search-form .search-field {
    @extend .form-control;
}
.search-form .search-submit {
    @extend .btn;
    @extend .btn-secondary;
}

form.wpcf7-form{
    input, textarea{
        outline: 0;
    }
    .wpcf7-form-control-wrap{
        display: block;
        width: 100%;
    }
    .wpcf7-text, .wpcf7-tel, .wpcf7-email, .wpcf7-date, .wpcf7-file{
        background: transparent;
        padding: 7px 10px;
        border: 1px solid $primary;
        width: 100%;
        border-radius: 0;
        margin-bottom: 30px;
        appearance: none;
        font-family: $font-family-base;
        font-size: 13px;
        line-height: 1.2;
        color: $blue-primary;
        &::-webkit-input-placeholder {
            color: inherit
        }
        &::-moz-placeholder {
            color: inherit
        }
        &:-ms-input-placeholder {
            color: inherit
        }
        &:-moz-placeholder {
            color: inherit
        }
        &.wpcf7-not-valid{
            border-color: red;
            color: red;
        }
    }
    .wpcf7-text, .wpcf7-tel, .wpcf7-email, .wpcf7-date, .wpcf7-select, .wpcf7-file{
        position: relative;
        & + .wpcf7-not-valid-tip{
            font-size: 10px;
            color: red;
            margin-top: -28px;
            position: absolute;
            width: 100%;
            left: 0;
            top: 100%;
        }
    }
    label{
        font-family: $font-family-base;
        font-size: 14px;
        font-weight: $font-base-bold;
        line-height: 1.2;
        color: $blue-primary;
        cursor: pointer;
    }
    .wpcf7-file{
        border-style: dashed;
    }
    .wpcf7-list-item{
        &.first {
            margin: 0;
        }
    }
    .wpcf7-textarea{
        background: transparent;
        font-family: $font-family-base;
        font-size: 13px;
        line-height: 1.2;
        color: $blue-primary;
        border: 1px solid $primary;
        width: 100%;
        resize: none;
        border-radius: 0;
        margin-bottom: 40px;
        padding: 15px 20px;
        appearance: none;
    }
    .wpcf7-select{
        width: 100%;
        padding: 7px 10px;
        border-radius: 0;
        background-color: #fff;
        box-shadow: none;
        border: 1px solid $primary;
        appearance: none;
        font-family: $font-family-base;
        font-size: 13px;
        line-height: 1.2;
        color: $blue-primary;
        margin-bottom: 30px;
        position: relative;
        background: url('../images/carret.svg') no-repeat;
        background-size:11px auto;
        background-position: center right 20px;
    }
    .wpcf7-recaptcha{
        transform: scale(0.81);
        transform-origin: 0 0;
        margin-bottom: 40px;
        @include media-breakpoint-up(md){
            transform: scale(1);
        }
    }
    .wpcf7-validation-errors, .wpcf7-acceptance-missing{
        border: 0;
        color: red;
        padding: 0;
        margin-bottom: 50px;
    }
    .wpcf7-submit{
        min-width: 150px;
    }
}
