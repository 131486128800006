.emploi-details{
    &__wrapper{
        padding: 30px 0;
        @include media-breakpoint-up(lg) {
            padding: 45px 0;
        }
        @include media-breakpoint-up(xl) {
            padding: 65px 0 85px;
        }
        .secondary-title{
            text-transform: initial;
        }
        .emploi-date{
            font-family: $font-secondary;
            font-size: 14px;
            font-weight: $font-secondary-semibold;
            line-height: 1.2;
            color: $primary;
            margin-bottom: 10px;
        }
        .article-share__wrapper{
            margin: 30px 0 40px;
        }
    }
    &__characteristic{
        list-style-type: none;
        padding: 0 0 15px 0;
    }
    &__form{
        margin-top: 80px;
    }
    &__sidebar{
        position: sticky;
        top: 30px;
        .primary-button{
            margin-top: 30px;
        }
    }
}
