.newsletter-form{
    &--wrapper{
        position: relative;
        form{
            margin-top: 20px;
        }
        .form-newsletter{
            display: flex;
            justify-content: space-between;
            align-items: center;
            // padding-bottom: 3px;
            position: relative;
            background: #fff;

            .wpcf7-email{
                background: transparent;
                font-family: $font-secondary;
                font-size: 12px;
                font-weight: 600;
                color: $blue-primary;
                border: 0;
                border-radius: 0;
                padding: 12px 12px 12px 17px;
                margin: 0;
                width: 100%;
                @include media-breakpoint-up(md) {
                    font-size: 14px;
                    padding: 16px 16px 16px 24px;
                }
                @include media-breakpoint-only(lg) {
                    padding: 12px 12px 12px 17px;
                    font-size: 12px;
                }
                &::-webkit-input-placeholder {
                    color: inherit;
                }
                &::-moz-placeholder {
                    color: inherit;
                }
                &:-ms-input-placeholder {
                    color: inherit;
                }
                &:-moz-placeholder {
                    color: inherit;
                }
                &:focus{
                    outline: 0;
                }
                &.wpcf7-not-valid{
                    color: red;
                }
                & + .wpcf7-not-valid-tip{
                    display: none;
                }
            }
            .wpcf7-submit{
                background-size: 40px auto;
                background-position: center center;
                padding: 12px 10px;
                font-size: 11px;
                border-radius: 0;
                margin-left: auto;
                min-width: 95px;
                @include media-breakpoint-up(md) {
                    font-size: 15px;
                    padding: 15px;
                }
                @include media-breakpoint-only(lg) {
                    font-size: 11px;
                }
                &.loading{
                    background: $primary url(../images/loader.gif) no-repeat;
                    background-size: 40px auto;
                    background-position: center center;
                    pointer-events: none;
                    text-indent: -9999px;
                }
            }
            .ajax-loader{
                display: none !important;
            }
            & + .wpcf7-response-output{
                display: none !important;
            }
            // &-success{
            //     position: absolute;
            //     width: 100%;
            //     min-height: 100%;
            //     background: #fff;
            //     font-size: 13px;
            //     font-style: italic;
            //     display: none;
            // }
        }
    }
}

// Modal
.modalNewsletter{
    .modal-dialog{
        max-width: 550px;
    }
    .modal-content{
        border-radius: 0;
        border: 0;
        padding: 0 15px;
        @include media-breakpoint-up(lg){
            padding: 0 68px;
        }
    }
    .modal-header{
        text-align: center;
        display: block;
        padding-top: 40px;
        padding-bottom: 0;
        border: 0;
        h3{
            display: inline-block;
        }
        p{
            color: $blue-primary;
        }
    }
    .modal-body{
        padding-top: 0;
    }
}
