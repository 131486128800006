header.banner {
    position: absolute;
    width: 100%;
    top: 0;
    z-index: 1;
    .brand {
        margin-top: 10px;
        margin-bottom: 19px;
    }
	.top-bar {
		.nav-top {
            padding: 6px 0 7px;
            justify-content: flex-end;
			a {
				font-family: $font-secondary;
				font-weight: $font-secondary-medium;
				font-size: 11px;
				text-transform: uppercase;
                color: $white;
                letter-spacing: -0.1;
                &:hover{
                    color: $primary;
                    text-decoration: none;
                }
			}
		}
		.nav-top-primary {
			li {
                padding-right: 14px;
                @include media-breakpoint-up(xl) {
                    padding-right: 20px;
                }
                &:last-child {
                    padding-right: 0;
                }
			}
		}
    }
}
.nav-primary{
    &__wrapper, &__menu{
        & > * {
            margin-right: 25px;
            @include media-breakpoint-up(xl) {
                margin-right: 45px;
            }
            &:last-child {
                margin-right: 0;
            }
        }
    }
    &__wrapper {
        display: flex;
        justify-content: flex-end;
        position: relative;
        margin-top: 14px;
        @include media-breakpoint-up(xl) {
            margin-top: 24px;
        }
        .dropdown-toggle, .nav-link{
            @include reset-button;
            font-family: $font-secondary;
            font-weight: $font-secondary-semibold;
            font-size: 15px;
            color: $white;
            padding: 0 0 10px 0;
        }
        .dropdown-toggle {
            position: relative;
            direction: block;
            &:before {
                content:"";
                display: block;
                width: 9px;
                height: 5px;
                background: transparent url('../images/arrow.png') left top no-repeat;
                background-size: contain;
                position: absolute;
                left: 50%;
                bottom: 0;
                transform: translateX(-50%);
                transition: all ease .3s;
            }
            &:after{
                content: " ";
                top: 0;
                left: 50%;
                border: solid transparent;
                height: 0;
                width: 0;
                position: absolute;
                pointer-events: none;
                border-color: rgba(136, 183, 213, 0);
                border-bottom-color: #fff;
                border-width: 30px;
                margin-left: -30px;
                display: none;
            }
            &:hover{
                &:before{
                    transform: translateX(-50%) translateY(5px);
                }
            }
            &[aria-expanded="true"]{
                &:before{
                    display: none;
                }
                &:after{
                    display: block;
                }
            }
        }
        .dropdown-menu{
            top: 40px !important;
            right: 0 !important;
            left: auto !important;
            transform: translateX(0) !important;
            border: 0;
            border-radius: 0;
            margin: 0;
            padding: 28px 39px;
            a{
                display: inline-block;
                font-family: $font-family-base;
                font-size: 12px;
                line-height: 1.2;
                color: $blue-primary;
                margin: 3px 0;
                padding: 0;
                &:active, &:hover{
                    background-color: transparent;
                }
                &.col-title {
                    font-family: $font-secondary;
                    font-weight: $font-secondary-bold;
                    text-transform: uppercase;
                    text-decoration: none;
                    color: $primary;
                }
            }
            .sub-menu{
                padding: 0;
                list-style-type: none;
                padding-right: 20px;
                padding-top: 5px;
                & > .menu-item{
                    padding: 5px 0;
                }
            }
            .menu-item{
                padding: 5px 0;
                line-height: 1.2;
                &.no-title{
                    & > a{
                        display: none;
                    }
                    & > .sub-menu{
                        padding-top: 0;
                        margin-top: -5px;
                    }
                }
                &.col-title{
                    & > a{
                        font-family: $font-secondary;
                        font-weight: $font-secondary-bold;
                        text-transform: uppercase;
                        text-decoration: none;
                    }
                    &.blue > a{
                        color: $primary
                    }
                    &.orange > a{
                        color: $mtl-color
                    }
                    &.red > a{
                        color: $south-color
                    }
                    &.hide {
                        padding-top: 0;
                        & > a{
                            display: none;
                        }
                        & > .sub-menu {
                            padding-top: 0;
                        }
                    }

                }
                &.bold > a{
                    font-weight: $font-secondary-bold;
                    text-decoration: underline;
                }
            }
            .discover_navigation{
                flex-wrap: nowrap;
                margin-right: 180px;
                & > li{
                    width: 180px;
                }
            }
            .programmes_navigation{
                flex-wrap: nowrap;
                margin-right: 180px;
                & > li{
                    width: 220px;
                }
            }
            .etudiant_navigation__wrapper{
                flex-wrap: nowrap;
                padding: 0;
                list-style-type: none;
                flex-direction: row;
                display: flex;
                & > li{
                    width: 220px;
                }
            }
            .documents_navigation{
                padding: 0;
                list-style-type: none;
                a{
                    font-weight: $font-secondary-bold;
                    text-decoration: underline;
                }
            }
        }
    }
}
