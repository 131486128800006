.alertModal {
    .modal-content {
        border-radius: 0;
        border: 0;
        border-bottom: 10px solid #fff;
        background: #ef4130;
    }
    .modal-header{
        border-bottom: 0;
        padding: 0;
        .close{
            font-style: italic;
            font-size: 15px;
            text-shadow: none;
            opacity: 1;
            margin: 10px 15px 10px auto;
            padding: 0;
            color: #fff;
            &:hover{
                color: #fff;
            }
        }
    }
    .modal-body{
        color: #fff;
        font-family: $font-secondary;
        font-weight: $font-secondary;
        padding: 0 25px 10px;
        h5{
            font-family: $font-secondary;
            font-weight: $font-secondary-bold;
            font-size: 22px;
            text-transform: uppercase;
            margin-bottom: 15px;
            line-height: 1.2;
            @include media-breakpoint-up(lg) {
                font-size: 30px;
            }
        }
    }
}
