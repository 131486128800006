body.page-template-template-mission{
    .mission{
        &__wrapper{
            padding: 30px 0 0;
            overflow: hidden;
            @include media-breakpoint-up(md) {
                padding: 30px 0;
            }
            @include media-breakpoint-up(lg) {
                padding: 65px 0 70px;
            }
        }
        &__intro{
            font-family: $font-family-base;
            font-size: 18px;
            line-height: 1.2;
            color: $blue-primary;
            padding-bottom: 65px;
        }
        &__quote{
            background: $primary;
            padding: 30px 0;
            font-family: $font-secondary;
            font-size: 14px;
            font-weight: $font-secondary-bold;
            line-height: 1.25;
            color: #fff;
            margin-top: 10px;
            position: relative;
            @include media-breakpoint-up(md) {
                padding: 35px 25px;
                margin-top: 0;
            }
            @include media-breakpoint-up(xl) {
                padding: 45px 27px;
                margin-top: -15px;
                font-size: 24px;
            }
            &:before{
                content: '';
                width: 100vw;
                height: 100%;
                position: absolute;
                top: 0;
                left: 50%;
                transform: translateX(-50%);
                background: $primary;
                z-index: -1;;
                @include media-breakpoint-up(md) {
                    display: none;
                }
            }
            h4{
                @extend .primary-subtitle;
                margin-bottom: 18px;
            }
            p{
                margin-bottom: 0;
            }
        }
    }
    .usefullinks__wrapper{
        border: 2px solid $primary;
        padding: 50px 60px;
        font-family: $font-family-base;
        font-size: 18px;
        line-height: 1.3;
        // color: $primary;
        margin-top: 70px;
        margin-bottom: 30px;
    }
}
