body.page-template-template-Contact{
    .contact-form__wrapper{
        padding-top: 25px;
        padding-bottom: 25px;
        @include media-breakpoint-up(lg) {
            padding-top: 55px;
            padding-bottom: 55px;
        }
    }
    .contact-quote{
        &__wrapper{
            background-color: $primary;
            color: #fff;
            padding: 45px;
            font-size: 18px;
            margin-top: 30px;
            @include media-breakpoint-up(lg) {
                margin-top: 0;
            }
            em{
                font-size: 14px;
            }
        }
    }
}
