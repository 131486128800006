/* Slider */
.slick-slider
{
    position: relative;

    display: block;
    box-sizing: border-box;

    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;

    -webkit-touch-callout: none;
    -khtml-user-select: none;
    -ms-touch-action: pan-y;
        touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;
}

.slick-list
{
    position: relative;

    display: block;
    overflow: hidden;

    margin: 0;
    padding: 0;
}
.slick-list:focus
{
    outline: none;
}
.slick-list.dragging
{
    cursor: pointer;
    cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list
{
    -webkit-transform: translate3d(0, 0, 0);
       -moz-transform: translate3d(0, 0, 0);
        -ms-transform: translate3d(0, 0, 0);
         -o-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
}

.slick-track
{
    position: relative;
    top: 0;
    left: 0;

    display: block;
    margin-left: auto;
    margin-right: auto;
}
.slick-track:before,
.slick-track:after
{
    display: table;

    content: '';
}
.slick-track:after
{
    clear: both;
}
.slick-loading .slick-track
{
    visibility: hidden;
}

.slick-slide
{
    display: none;
    float: left;

    height: 100%;
    min-height: 1px;
}
[dir='rtl'] .slick-slide
{
    float: right;
}
.slick-slide img
{
    display: block;
}
.slick-slide.slick-loading img
{
    display: none;
}
.slick-slide.dragging img
{
    pointer-events: none;
}
.slick-initialized .slick-slide
{
    display: block;
}
.slick-loading .slick-slide
{
    visibility: hidden;
}
.slick-vertical .slick-slide
{
    display: block;

    height: auto;

    border: 1px solid transparent;
}
.slick-arrow.slick-hidden {
    display: none;
}

@charset "UTF-8";
@font-face {
  font-family: "teccart";
  src: url("../../../teccart/dist/fonts/teccart.eot?c12jqb");
  src: url("../../../teccart/dist/fonts/teccart.eot?c12jqb#iefix") format("embedded-opentype"), url("../../../teccart/dist/fonts/teccart.ttf?c12jqb") format("truetype"), url("../../../teccart/dist/fonts/teccart.woff?c12jqb") format("woff"), url("../../../teccart/dist/fonts/teccart.svg?c12jqb#teccart") format("svg");
  font-weight: normal;
  font-style: normal; }

/*!
 * Bootstrap v4.1.3 (https://getbootstrap.com/)
 * Copyright 2011-2018 The Bootstrap Authors
 * Copyright 2011-2018 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */
:root {
  --blue: #007bff;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #dc3545;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --green: #28a745;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #FFFFFF;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --primary: #0787e6;
  --secondary: #6c757d;
  --success: #28a745;
  --info: #17a2b8;
  --warning: #ffc107;
  --danger: #dc3545;
  --light: #f8f9fa;
  --dark: #343a40;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace; }

*,
*::before,
*::after {
  box-sizing: border-box; }

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: transparent; }

@-ms-viewport {
  width: device-width; }

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block; }

body {
  margin: 0;
  font-family: "Open Sans", sans-serif;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  background-color: #FFFFFF; }

[tabindex="-1"]:focus {
  outline: 0 !important; }

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible; }

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem; }

p {
  margin-top: 0;
  margin-bottom: 1rem; }

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0; }

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit; }

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem; }

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0; }

dt {
  font-weight: 700; }

dd {
  margin-bottom: .5rem;
  margin-left: 0; }

blockquote {
  margin: 0 0 1rem; }

dfn {
  font-style: italic; }

b,
strong {
  font-weight: bolder; }

small {
  font-size: 80%; }

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline; }

sub {
  bottom: -.25em; }

sup {
  top: -.5em; }

a {
  color: #0787e6;
  text-decoration: none;
  background-color: transparent;
  -webkit-text-decoration-skip: objects; }
  a:hover {
    color: #055b9c;
    text-decoration: underline; }

a:not([href]):not([tabindex]) {
  color: inherit;
  text-decoration: none; }
  a:not([href]):not([tabindex]):hover, a:not([href]):not([tabindex]):focus {
    color: inherit;
    text-decoration: none; }
  a:not([href]):not([tabindex]):focus {
    outline: 0; }

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em; }

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar; }

figure {
  margin: 0 0 1rem; }

img {
  vertical-align: middle;
  border-style: none; }

svg {
  overflow: hidden;
  vertical-align: middle; }

table {
  border-collapse: collapse; }

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom; }

th {
  text-align: inherit; }

label {
  display: inline-block;
  margin-bottom: 0.5rem; }

button {
  border-radius: 0; }

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color; }

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

button,
input {
  overflow: visible; }

button,
select {
  text-transform: none; }

button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; }

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none; }

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0; }

input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"] {
  -webkit-appearance: listbox; }

textarea {
  overflow: auto;
  resize: vertical; }

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0; }

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal; }

progress {
  vertical-align: baseline; }

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none; }

[type="search"]::-webkit-search-cancel-button,
[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button; }

output {
  display: inline-block; }

summary {
  display: list-item;
  cursor: pointer; }

template {
  display: none; }

[hidden] {
  display: none !important; }

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: 0.5rem;
  font-family: inherit;
  font-weight: 500;
  line-height: 1.2;
  color: inherit; }

h1, .h1 {
  font-size: 2.1875rem; }

h2, .h2 {
  font-size: 1.75rem; }

h3, .h3 {
  font-size: 1.53125rem; }

h4, .h4 {
  font-size: 1.3125rem; }

h5, .h5 {
  font-size: 1.09375rem; }

h6, .h6 {
  font-size: 0.875rem; }

.lead {
  font-size: 1.09375rem;
  font-weight: 300; }

.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.2; }

.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.2; }

.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.2; }

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2; }

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1); }

small,
.small {
  font-size: 80%;
  font-weight: 400; }

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3; }

.list-unstyled, .comment-list {
  padding-left: 0;
  list-style: none; }

.list-inline {
  padding-left: 0;
  list-style: none; }

.list-inline-item {
  display: inline-block; }
  .list-inline-item:not(:last-child) {
    margin-right: 0.5rem; }

.initialism {
  font-size: 90%;
  text-transform: uppercase; }

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.09375rem; }

.blockquote-footer {
  display: block;
  font-size: 80%;
  color: #6c757d; }
  .blockquote-footer::before {
    content: "\2014 \00A0"; }

.img-fluid, .wysiwyg img, .wysiwyg-blue-box img, .wp-caption > img {
  max-width: 100%;
  height: auto; }

.img-thumbnail {
  padding: 0.25rem;
  background-color: #FFFFFF;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  max-width: 100%;
  height: auto; }

.figure, .wp-caption {
  display: inline-block; }

.figure-img, .wp-caption > img {
  margin-bottom: 0.5rem;
  line-height: 1; }

.figure-caption, .wp-caption-text {
  font-size: 90%;
  color: #6c757d; }

code {
  font-size: 87.5%;
  color: #e83e8c;
  word-break: break-word; }
  a > code {
    color: inherit; }

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 87.5%;
  color: #FFFFFF;
  background-color: #212529;
  border-radius: 0.2rem; }
  kbd kbd {
    padding: 0;
    font-size: 100%;
    font-weight: 700; }

pre {
  display: block;
  font-size: 87.5%;
  color: #212529; }
  pre code {
    font-size: inherit;
    color: inherit;
    word-break: normal; }

.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll; }

.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }
  @media (min-width: 576px) {
    .container {
      max-width: 540px; } }
  @media (min-width: 768px) {
    .container {
      max-width: 720px; } }
  @media (min-width: 992px) {
    .container {
      max-width: 960px; } }
  @media (min-width: 1200px) {
    .container {
      max-width: 1140px; } }

.container-fluid {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px; }

.no-gutters {
  margin-right: 0;
  margin-left: 0; }
  .no-gutters > .col,
  .no-gutters > [class*="col-"] {
    padding-right: 0;
    padding-left: 0; }

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px; }

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%; }

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: none; }

.col-1 {
  flex: 0 0 8.3333333333%;
  max-width: 8.3333333333%; }

.col-2 {
  flex: 0 0 16.6666666667%;
  max-width: 16.6666666667%; }

.col-3 {
  flex: 0 0 25%;
  max-width: 25%; }

.col-4 {
  flex: 0 0 33.3333333333%;
  max-width: 33.3333333333%; }

.col-5 {
  flex: 0 0 41.6666666667%;
  max-width: 41.6666666667%; }

.col-6 {
  flex: 0 0 50%;
  max-width: 50%; }

.col-7 {
  flex: 0 0 58.3333333333%;
  max-width: 58.3333333333%; }

.col-8 {
  flex: 0 0 66.6666666667%;
  max-width: 66.6666666667%; }

.col-9 {
  flex: 0 0 75%;
  max-width: 75%; }

.col-10 {
  flex: 0 0 83.3333333333%;
  max-width: 83.3333333333%; }

.col-11 {
  flex: 0 0 91.6666666667%;
  max-width: 91.6666666667%; }

.col-12 {
  flex: 0 0 100%;
  max-width: 100%; }

.order-first {
  order: -1; }

.order-last {
  order: 13; }

.order-0 {
  order: 0; }

.order-1 {
  order: 1; }

.order-2 {
  order: 2; }

.order-3 {
  order: 3; }

.order-4 {
  order: 4; }

.order-5 {
  order: 5; }

.order-6 {
  order: 6; }

.order-7 {
  order: 7; }

.order-8 {
  order: 8; }

.order-9 {
  order: 9; }

.order-10 {
  order: 10; }

.order-11 {
  order: 11; }

.order-12 {
  order: 12; }

.offset-1 {
  margin-left: 8.3333333333%; }

.offset-2 {
  margin-left: 16.6666666667%; }

.offset-3 {
  margin-left: 25%; }

.offset-4 {
  margin-left: 33.3333333333%; }

.offset-5 {
  margin-left: 41.6666666667%; }

.offset-6 {
  margin-left: 50%; }

.offset-7 {
  margin-left: 58.3333333333%; }

.offset-8 {
  margin-left: 66.6666666667%; }

.offset-9 {
  margin-left: 75%; }

.offset-10 {
  margin-left: 83.3333333333%; }

.offset-11 {
  margin-left: 91.6666666667%; }

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-sm-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%; }
  .col-sm-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%; }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-sm-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%; }
  .col-sm-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%; }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-sm-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%; }
  .col-sm-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%; }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-sm-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%; }
  .col-sm-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%; }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-sm-first {
    order: -1; }
  .order-sm-last {
    order: 13; }
  .order-sm-0 {
    order: 0; }
  .order-sm-1 {
    order: 1; }
  .order-sm-2 {
    order: 2; }
  .order-sm-3 {
    order: 3; }
  .order-sm-4 {
    order: 4; }
  .order-sm-5 {
    order: 5; }
  .order-sm-6 {
    order: 6; }
  .order-sm-7 {
    order: 7; }
  .order-sm-8 {
    order: 8; }
  .order-sm-9 {
    order: 9; }
  .order-sm-10 {
    order: 10; }
  .order-sm-11 {
    order: 11; }
  .order-sm-12 {
    order: 12; }
  .offset-sm-0 {
    margin-left: 0; }
  .offset-sm-1 {
    margin-left: 8.3333333333%; }
  .offset-sm-2 {
    margin-left: 16.6666666667%; }
  .offset-sm-3 {
    margin-left: 25%; }
  .offset-sm-4 {
    margin-left: 33.3333333333%; }
  .offset-sm-5 {
    margin-left: 41.6666666667%; }
  .offset-sm-6 {
    margin-left: 50%; }
  .offset-sm-7 {
    margin-left: 58.3333333333%; }
  .offset-sm-8 {
    margin-left: 66.6666666667%; }
  .offset-sm-9 {
    margin-left: 75%; }
  .offset-sm-10 {
    margin-left: 83.3333333333%; }
  .offset-sm-11 {
    margin-left: 91.6666666667%; } }

@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-md-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%; }
  .col-md-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%; }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-md-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%; }
  .col-md-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%; }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-md-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%; }
  .col-md-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%; }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-md-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%; }
  .col-md-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%; }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-md-first {
    order: -1; }
  .order-md-last {
    order: 13; }
  .order-md-0 {
    order: 0; }
  .order-md-1 {
    order: 1; }
  .order-md-2 {
    order: 2; }
  .order-md-3 {
    order: 3; }
  .order-md-4 {
    order: 4; }
  .order-md-5 {
    order: 5; }
  .order-md-6 {
    order: 6; }
  .order-md-7 {
    order: 7; }
  .order-md-8 {
    order: 8; }
  .order-md-9 {
    order: 9; }
  .order-md-10 {
    order: 10; }
  .order-md-11 {
    order: 11; }
  .order-md-12 {
    order: 12; }
  .offset-md-0 {
    margin-left: 0; }
  .offset-md-1 {
    margin-left: 8.3333333333%; }
  .offset-md-2 {
    margin-left: 16.6666666667%; }
  .offset-md-3 {
    margin-left: 25%; }
  .offset-md-4 {
    margin-left: 33.3333333333%; }
  .offset-md-5 {
    margin-left: 41.6666666667%; }
  .offset-md-6 {
    margin-left: 50%; }
  .offset-md-7 {
    margin-left: 58.3333333333%; }
  .offset-md-8 {
    margin-left: 66.6666666667%; }
  .offset-md-9 {
    margin-left: 75%; }
  .offset-md-10 {
    margin-left: 83.3333333333%; }
  .offset-md-11 {
    margin-left: 91.6666666667%; } }

@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-lg-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%; }
  .col-lg-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%; }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-lg-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%; }
  .col-lg-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%; }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-lg-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%; }
  .col-lg-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%; }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-lg-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%; }
  .col-lg-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%; }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-lg-first {
    order: -1; }
  .order-lg-last {
    order: 13; }
  .order-lg-0 {
    order: 0; }
  .order-lg-1 {
    order: 1; }
  .order-lg-2 {
    order: 2; }
  .order-lg-3 {
    order: 3; }
  .order-lg-4 {
    order: 4; }
  .order-lg-5 {
    order: 5; }
  .order-lg-6 {
    order: 6; }
  .order-lg-7 {
    order: 7; }
  .order-lg-8 {
    order: 8; }
  .order-lg-9 {
    order: 9; }
  .order-lg-10 {
    order: 10; }
  .order-lg-11 {
    order: 11; }
  .order-lg-12 {
    order: 12; }
  .offset-lg-0 {
    margin-left: 0; }
  .offset-lg-1 {
    margin-left: 8.3333333333%; }
  .offset-lg-2 {
    margin-left: 16.6666666667%; }
  .offset-lg-3 {
    margin-left: 25%; }
  .offset-lg-4 {
    margin-left: 33.3333333333%; }
  .offset-lg-5 {
    margin-left: 41.6666666667%; }
  .offset-lg-6 {
    margin-left: 50%; }
  .offset-lg-7 {
    margin-left: 58.3333333333%; }
  .offset-lg-8 {
    margin-left: 66.6666666667%; }
  .offset-lg-9 {
    margin-left: 75%; }
  .offset-lg-10 {
    margin-left: 83.3333333333%; }
  .offset-lg-11 {
    margin-left: 91.6666666667%; } }

@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-xl-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%; }
  .col-xl-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%; }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-xl-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%; }
  .col-xl-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%; }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-xl-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%; }
  .col-xl-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%; }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-xl-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%; }
  .col-xl-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%; }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-xl-first {
    order: -1; }
  .order-xl-last {
    order: 13; }
  .order-xl-0 {
    order: 0; }
  .order-xl-1 {
    order: 1; }
  .order-xl-2 {
    order: 2; }
  .order-xl-3 {
    order: 3; }
  .order-xl-4 {
    order: 4; }
  .order-xl-5 {
    order: 5; }
  .order-xl-6 {
    order: 6; }
  .order-xl-7 {
    order: 7; }
  .order-xl-8 {
    order: 8; }
  .order-xl-9 {
    order: 9; }
  .order-xl-10 {
    order: 10; }
  .order-xl-11 {
    order: 11; }
  .order-xl-12 {
    order: 12; }
  .offset-xl-0 {
    margin-left: 0; }
  .offset-xl-1 {
    margin-left: 8.3333333333%; }
  .offset-xl-2 {
    margin-left: 16.6666666667%; }
  .offset-xl-3 {
    margin-left: 25%; }
  .offset-xl-4 {
    margin-left: 33.3333333333%; }
  .offset-xl-5 {
    margin-left: 41.6666666667%; }
  .offset-xl-6 {
    margin-left: 50%; }
  .offset-xl-7 {
    margin-left: 58.3333333333%; }
  .offset-xl-8 {
    margin-left: 66.6666666667%; }
  .offset-xl-9 {
    margin-left: 75%; }
  .offset-xl-10 {
    margin-left: 83.3333333333%; }
  .offset-xl-11 {
    margin-left: 91.6666666667%; } }

.table {
  width: 100%;
  margin-bottom: 1rem;
  background-color: transparent; }
  .table th,
  .table td {
    padding: 0.75rem;
    vertical-align: top;
    border-top: 1px solid #dee2e6; }
  .table thead th {
    vertical-align: bottom;
    border-bottom: 2px solid #dee2e6; }
  .table tbody + tbody {
    border-top: 2px solid #dee2e6; }
  .table .table {
    background-color: #FFFFFF; }

.table-sm th,
.table-sm td {
  padding: 0.3rem; }

.table-bordered {
  border: 1px solid #dee2e6; }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #dee2e6; }
  .table-bordered thead th,
  .table-bordered thead td {
    border-bottom-width: 2px; }

.table-borderless th,
.table-borderless td,
.table-borderless thead th,
.table-borderless tbody + tbody {
  border: 0; }

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05); }

.table-hover tbody tr:hover {
  background-color: rgba(0, 0, 0, 0.075); }

.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: #baddf8; }

.table-hover .table-primary:hover {
  background-color: #a3d2f6; }
  .table-hover .table-primary:hover > td,
  .table-hover .table-primary:hover > th {
    background-color: #a3d2f6; }

.table-secondary,
.table-secondary > th,
.table-secondary > td {
  background-color: #d6d8db; }

.table-hover .table-secondary:hover {
  background-color: #c8cbcf; }
  .table-hover .table-secondary:hover > td,
  .table-hover .table-secondary:hover > th {
    background-color: #c8cbcf; }

.table-success,
.table-success > th,
.table-success > td {
  background-color: #c3e6cb; }

.table-hover .table-success:hover {
  background-color: #b1dfbb; }
  .table-hover .table-success:hover > td,
  .table-hover .table-success:hover > th {
    background-color: #b1dfbb; }

.table-info,
.table-info > th,
.table-info > td {
  background-color: #bee5eb; }

.table-hover .table-info:hover {
  background-color: #abdde5; }
  .table-hover .table-info:hover > td,
  .table-hover .table-info:hover > th {
    background-color: #abdde5; }

.table-warning,
.table-warning > th,
.table-warning > td {
  background-color: #ffeeba; }

.table-hover .table-warning:hover {
  background-color: #ffe8a1; }
  .table-hover .table-warning:hover > td,
  .table-hover .table-warning:hover > th {
    background-color: #ffe8a1; }

.table-danger,
.table-danger > th,
.table-danger > td {
  background-color: #f5c6cb; }

.table-hover .table-danger:hover {
  background-color: #f1b0b7; }
  .table-hover .table-danger:hover > td,
  .table-hover .table-danger:hover > th {
    background-color: #f1b0b7; }

.table-light,
.table-light > th,
.table-light > td {
  background-color: #fdfdfe; }

.table-hover .table-light:hover {
  background-color: #ececf6; }
  .table-hover .table-light:hover > td,
  .table-hover .table-light:hover > th {
    background-color: #ececf6; }

.table-dark,
.table-dark > th,
.table-dark > td {
  background-color: #c6c8ca; }

.table-hover .table-dark:hover {
  background-color: #b9bbbe; }
  .table-hover .table-dark:hover > td,
  .table-hover .table-dark:hover > th {
    background-color: #b9bbbe; }

.table-active,
.table-active > th,
.table-active > td {
  background-color: rgba(0, 0, 0, 0.075); }

.table-hover .table-active:hover {
  background-color: rgba(0, 0, 0, 0.075); }
  .table-hover .table-active:hover > td,
  .table-hover .table-active:hover > th {
    background-color: rgba(0, 0, 0, 0.075); }

.table .thead-dark th {
  color: #FFFFFF;
  background-color: #212529;
  border-color: #32383e; }

.table .thead-light th {
  color: #495057;
  background-color: #e9ecef;
  border-color: #dee2e6; }

.table-dark {
  color: #FFFFFF;
  background-color: #212529; }
  .table-dark th,
  .table-dark td,
  .table-dark thead th {
    border-color: #32383e; }
  .table-dark.table-bordered {
    border: 0; }
  .table-dark.table-striped tbody tr:nth-of-type(odd) {
    background-color: rgba(255, 255, 255, 0.05); }
  .table-dark.table-hover tbody tr:hover {
    background-color: rgba(255, 255, 255, 0.075); }

@media (max-width: 575.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar; }
    .table-responsive-sm > .table-bordered {
      border: 0; } }

@media (max-width: 767.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar; }
    .table-responsive-md > .table-bordered {
      border: 0; } }

@media (max-width: 991.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar; }
    .table-responsive-lg > .table-bordered {
      border: 0; } }

@media (max-width: 1199.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar; }
    .table-responsive-xl > .table-bordered {
      border: 0; } }

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar; }
  .table-responsive > .table-bordered {
    border: 0; }

.form-control, .comment-form input[type="text"],
.comment-form input[type="email"],
.comment-form input[type="url"],
.comment-form textarea, .search-form .search-field {
  display: block;
  width: 100%;
  height: calc(2.0625rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #495057;
  background-color: #FFFFFF;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media screen and (prefers-reduced-motion: reduce) {
    .form-control, .comment-form input[type="text"],
    .comment-form input[type="email"],
    .comment-form input[type="url"],
    .comment-form textarea, .search-form .search-field {
      transition: none; } }
  .form-control::-ms-expand, .comment-form input[type="text"]::-ms-expand,
  .comment-form input[type="email"]::-ms-expand,
  .comment-form input[type="url"]::-ms-expand,
  .comment-form textarea::-ms-expand, .search-form .search-field::-ms-expand {
    background-color: transparent;
    border: 0; }
  .form-control:focus, .comment-form input[type="text"]:focus,
  .comment-form input[type="email"]:focus,
  .comment-form input[type="url"]:focus,
  .comment-form textarea:focus, .search-form .search-field:focus {
    color: #495057;
    background-color: #FFFFFF;
    border-color: #72c0fb;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(7, 135, 230, 0.25); }
  .form-control::placeholder, .comment-form input[type="text"]::placeholder,
  .comment-form input[type="email"]::placeholder,
  .comment-form input[type="url"]::placeholder,
  .comment-form textarea::placeholder, .search-form .search-field::placeholder {
    color: #6c757d;
    opacity: 1; }
  .form-control:disabled, .comment-form input[type="text"]:disabled,
  .comment-form input[type="email"]:disabled,
  .comment-form input[type="url"]:disabled,
  .comment-form textarea:disabled, .search-form .search-field:disabled, .form-control[readonly], .comment-form input[readonly][type="text"],
  .comment-form input[readonly][type="email"],
  .comment-form input[readonly][type="url"],
  .comment-form textarea[readonly], .search-form [readonly].search-field {
    background-color: #e9ecef;
    opacity: 1; }

select.form-control:focus::-ms-value, .search-form select.search-field:focus::-ms-value {
  color: #495057;
  background-color: #FFFFFF; }

.form-control-file,
.form-control-range {
  display: block;
  width: 100%; }

.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5; }

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.09375rem;
  line-height: 1.5; }

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.765625rem;
  line-height: 1.5; }

.form-control-plaintext {
  display: block;
  width: 100%;
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  margin-bottom: 0;
  line-height: 1.5;
  color: #212529;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0; }
  .form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
    padding-right: 0;
    padding-left: 0; }

.form-control-sm {
  height: calc(1.6484375rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.765625rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

.form-control-lg {
  height: calc(2.640625rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 1.09375rem;
  line-height: 1.5;
  border-radius: 0.3rem; }

select.form-control[size], .search-form select[size].search-field, select.form-control[multiple], .search-form select[multiple].search-field {
  height: auto; }

textarea.form-control,
.comment-form textarea, .search-form textarea.search-field {
  height: auto; }

.form-group, .comment-form p, .search-form label {
  margin-bottom: 1rem; }

.form-text {
  display: block;
  margin-top: 0.25rem; }

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px; }
  .form-row > .col,
  .form-row > [class*="col-"] {
    padding-right: 5px;
    padding-left: 5px; }

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem; }

.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem; }
  .form-check-input:disabled ~ .form-check-label {
    color: #6c757d; }

.form-check-label {
  margin-bottom: 0; }

.form-check-inline {
  display: inline-flex;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem; }
  .form-check-inline .form-check-input {
    position: static;
    margin-top: 0;
    margin-right: 0.3125rem;
    margin-left: 0; }

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #28a745; }

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.765625rem;
  line-height: 1.5;
  color: #FFFFFF;
  background-color: rgba(40, 167, 69, 0.9);
  border-radius: 0.25rem; }

.was-validated .form-control:valid, .was-validated .comment-form input[type="text"]:valid, .comment-form .was-validated input[type="text"]:valid, .was-validated
.comment-form input[type="email"]:valid,
.comment-form .was-validated input[type="email"]:valid, .was-validated
.comment-form input[type="url"]:valid,
.comment-form .was-validated input[type="url"]:valid, .was-validated
.comment-form textarea:valid,
.comment-form .was-validated textarea:valid, .was-validated .search-form .search-field:valid, .search-form .was-validated .search-field:valid, .form-control.is-valid, .comment-form input.is-valid[type="text"],
.comment-form input.is-valid[type="email"],
.comment-form input.is-valid[type="url"],
.comment-form textarea.is-valid, .search-form .is-valid.search-field, .was-validated
.custom-select:valid,
.custom-select.is-valid {
  border-color: #28a745; }
  .was-validated .form-control:valid:focus, .was-validated .comment-form input[type="text"]:valid:focus, .comment-form .was-validated input[type="text"]:valid:focus, .was-validated
  .comment-form input[type="email"]:valid:focus,
  .comment-form .was-validated input[type="email"]:valid:focus, .was-validated
  .comment-form input[type="url"]:valid:focus,
  .comment-form .was-validated input[type="url"]:valid:focus, .was-validated
  .comment-form textarea:valid:focus,
  .comment-form .was-validated textarea:valid:focus, .was-validated .search-form .search-field:valid:focus, .search-form .was-validated .search-field:valid:focus, .form-control.is-valid:focus, .comment-form input.is-valid[type="text"]:focus,
  .comment-form input.is-valid[type="email"]:focus,
  .comment-form input.is-valid[type="url"]:focus,
  .comment-form textarea.is-valid:focus, .search-form .is-valid.search-field:focus, .was-validated
  .custom-select:valid:focus,
  .custom-select.is-valid:focus {
    border-color: #28a745;
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }
  .was-validated .form-control:valid ~ .valid-feedback, .was-validated .comment-form input[type="text"]:valid ~ .valid-feedback, .comment-form .was-validated input[type="text"]:valid ~ .valid-feedback, .was-validated
  .comment-form input[type="email"]:valid ~ .valid-feedback,
  .comment-form .was-validated input[type="email"]:valid ~ .valid-feedback, .was-validated
  .comment-form input[type="url"]:valid ~ .valid-feedback,
  .comment-form .was-validated input[type="url"]:valid ~ .valid-feedback, .was-validated
  .comment-form textarea:valid ~ .valid-feedback,
  .comment-form .was-validated textarea:valid ~ .valid-feedback, .was-validated .search-form .search-field:valid ~ .valid-feedback, .search-form .was-validated .search-field:valid ~ .valid-feedback,
  .was-validated .form-control:valid ~ .valid-tooltip,
  .was-validated .comment-form input[type="text"]:valid ~ .valid-tooltip, .comment-form
  .was-validated input[type="text"]:valid ~ .valid-tooltip,
  .was-validated
  .comment-form input[type="email"]:valid ~ .valid-tooltip,
  .comment-form
  .was-validated input[type="email"]:valid ~ .valid-tooltip,
  .was-validated
  .comment-form input[type="url"]:valid ~ .valid-tooltip,
  .comment-form
  .was-validated input[type="url"]:valid ~ .valid-tooltip,
  .was-validated
  .comment-form textarea:valid ~ .valid-tooltip,
  .comment-form
  .was-validated textarea:valid ~ .valid-tooltip,
  .was-validated .search-form .search-field:valid ~ .valid-tooltip, .search-form
  .was-validated .search-field:valid ~ .valid-tooltip, .form-control.is-valid ~ .valid-feedback, .comment-form input.is-valid[type="text"] ~ .valid-feedback,
  .comment-form input.is-valid[type="email"] ~ .valid-feedback,
  .comment-form input.is-valid[type="url"] ~ .valid-feedback,
  .comment-form textarea.is-valid ~ .valid-feedback, .search-form .is-valid.search-field ~ .valid-feedback,
  .form-control.is-valid ~ .valid-tooltip, .comment-form input.is-valid[type="text"] ~ .valid-tooltip,
  .comment-form input.is-valid[type="email"] ~ .valid-tooltip,
  .comment-form input.is-valid[type="url"] ~ .valid-tooltip,
  .comment-form textarea.is-valid ~ .valid-tooltip, .search-form .is-valid.search-field ~ .valid-tooltip, .was-validated
  .custom-select:valid ~ .valid-feedback,
  .was-validated
  .custom-select:valid ~ .valid-tooltip,
  .custom-select.is-valid ~ .valid-feedback,
  .custom-select.is-valid ~ .valid-tooltip {
    display: block; }

.was-validated .form-control-file:valid ~ .valid-feedback,
.was-validated .form-control-file:valid ~ .valid-tooltip, .form-control-file.is-valid ~ .valid-feedback,
.form-control-file.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #28a745; }

.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip, .form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .custom-control-input:valid ~ .custom-control-label, .custom-control-input.is-valid ~ .custom-control-label {
  color: #28a745; }
  .was-validated .custom-control-input:valid ~ .custom-control-label::before, .custom-control-input.is-valid ~ .custom-control-label::before {
    background-color: #71dd8a; }

.was-validated .custom-control-input:valid ~ .valid-feedback,
.was-validated .custom-control-input:valid ~ .valid-tooltip, .custom-control-input.is-valid ~ .valid-feedback,
.custom-control-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before, .custom-control-input.is-valid:checked ~ .custom-control-label::before {
  background-color: #34ce57; }

.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before, .custom-control-input.is-valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #FFFFFF, 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }

.was-validated .custom-file-input:valid ~ .custom-file-label, .custom-file-input.is-valid ~ .custom-file-label {
  border-color: #28a745; }
  .was-validated .custom-file-input:valid ~ .custom-file-label::after, .custom-file-input.is-valid ~ .custom-file-label::after {
    border-color: inherit; }

.was-validated .custom-file-input:valid ~ .valid-feedback,
.was-validated .custom-file-input:valid ~ .valid-tooltip, .custom-file-input.is-valid ~ .valid-feedback,
.custom-file-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .custom-file-input:valid:focus ~ .custom-file-label, .custom-file-input.is-valid:focus ~ .custom-file-label {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545; }

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.765625rem;
  line-height: 1.5;
  color: #FFFFFF;
  background-color: rgba(220, 53, 69, 0.9);
  border-radius: 0.25rem; }

.was-validated .form-control:invalid, .was-validated .comment-form input[type="text"]:invalid, .comment-form .was-validated input[type="text"]:invalid, .was-validated
.comment-form input[type="email"]:invalid,
.comment-form .was-validated input[type="email"]:invalid, .was-validated
.comment-form input[type="url"]:invalid,
.comment-form .was-validated input[type="url"]:invalid, .was-validated
.comment-form textarea:invalid,
.comment-form .was-validated textarea:invalid, .was-validated .search-form .search-field:invalid, .search-form .was-validated .search-field:invalid, .form-control.is-invalid, .comment-form input.is-invalid[type="text"],
.comment-form input.is-invalid[type="email"],
.comment-form input.is-invalid[type="url"],
.comment-form textarea.is-invalid, .search-form .is-invalid.search-field, .was-validated
.custom-select:invalid,
.custom-select.is-invalid {
  border-color: #dc3545; }
  .was-validated .form-control:invalid:focus, .was-validated .comment-form input[type="text"]:invalid:focus, .comment-form .was-validated input[type="text"]:invalid:focus, .was-validated
  .comment-form input[type="email"]:invalid:focus,
  .comment-form .was-validated input[type="email"]:invalid:focus, .was-validated
  .comment-form input[type="url"]:invalid:focus,
  .comment-form .was-validated input[type="url"]:invalid:focus, .was-validated
  .comment-form textarea:invalid:focus,
  .comment-form .was-validated textarea:invalid:focus, .was-validated .search-form .search-field:invalid:focus, .search-form .was-validated .search-field:invalid:focus, .form-control.is-invalid:focus, .comment-form input.is-invalid[type="text"]:focus,
  .comment-form input.is-invalid[type="email"]:focus,
  .comment-form input.is-invalid[type="url"]:focus,
  .comment-form textarea.is-invalid:focus, .search-form .is-invalid.search-field:focus, .was-validated
  .custom-select:invalid:focus,
  .custom-select.is-invalid:focus {
    border-color: #dc3545;
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }
  .was-validated .form-control:invalid ~ .invalid-feedback, .was-validated .comment-form input[type="text"]:invalid ~ .invalid-feedback, .comment-form .was-validated input[type="text"]:invalid ~ .invalid-feedback, .was-validated
  .comment-form input[type="email"]:invalid ~ .invalid-feedback,
  .comment-form .was-validated input[type="email"]:invalid ~ .invalid-feedback, .was-validated
  .comment-form input[type="url"]:invalid ~ .invalid-feedback,
  .comment-form .was-validated input[type="url"]:invalid ~ .invalid-feedback, .was-validated
  .comment-form textarea:invalid ~ .invalid-feedback,
  .comment-form .was-validated textarea:invalid ~ .invalid-feedback, .was-validated .search-form .search-field:invalid ~ .invalid-feedback, .search-form .was-validated .search-field:invalid ~ .invalid-feedback,
  .was-validated .form-control:invalid ~ .invalid-tooltip,
  .was-validated .comment-form input[type="text"]:invalid ~ .invalid-tooltip, .comment-form
  .was-validated input[type="text"]:invalid ~ .invalid-tooltip,
  .was-validated
  .comment-form input[type="email"]:invalid ~ .invalid-tooltip,
  .comment-form
  .was-validated input[type="email"]:invalid ~ .invalid-tooltip,
  .was-validated
  .comment-form input[type="url"]:invalid ~ .invalid-tooltip,
  .comment-form
  .was-validated input[type="url"]:invalid ~ .invalid-tooltip,
  .was-validated
  .comment-form textarea:invalid ~ .invalid-tooltip,
  .comment-form
  .was-validated textarea:invalid ~ .invalid-tooltip,
  .was-validated .search-form .search-field:invalid ~ .invalid-tooltip, .search-form
  .was-validated .search-field:invalid ~ .invalid-tooltip, .form-control.is-invalid ~ .invalid-feedback, .comment-form input.is-invalid[type="text"] ~ .invalid-feedback,
  .comment-form input.is-invalid[type="email"] ~ .invalid-feedback,
  .comment-form input.is-invalid[type="url"] ~ .invalid-feedback,
  .comment-form textarea.is-invalid ~ .invalid-feedback, .search-form .is-invalid.search-field ~ .invalid-feedback,
  .form-control.is-invalid ~ .invalid-tooltip, .comment-form input.is-invalid[type="text"] ~ .invalid-tooltip,
  .comment-form input.is-invalid[type="email"] ~ .invalid-tooltip,
  .comment-form input.is-invalid[type="url"] ~ .invalid-tooltip,
  .comment-form textarea.is-invalid ~ .invalid-tooltip, .search-form .is-invalid.search-field ~ .invalid-tooltip, .was-validated
  .custom-select:invalid ~ .invalid-feedback,
  .was-validated
  .custom-select:invalid ~ .invalid-tooltip,
  .custom-select.is-invalid ~ .invalid-feedback,
  .custom-select.is-invalid ~ .invalid-tooltip {
    display: block; }

.was-validated .form-control-file:invalid ~ .invalid-feedback,
.was-validated .form-control-file:invalid ~ .invalid-tooltip, .form-control-file.is-invalid ~ .invalid-feedback,
.form-control-file.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #dc3545; }

.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip, .form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .custom-control-input:invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label {
  color: #dc3545; }
  .was-validated .custom-control-input:invalid ~ .custom-control-label::before, .custom-control-input.is-invalid ~ .custom-control-label::before {
    background-color: #efa2a9; }

.was-validated .custom-control-input:invalid ~ .invalid-feedback,
.was-validated .custom-control-input:invalid ~ .invalid-tooltip, .custom-control-input.is-invalid ~ .invalid-feedback,
.custom-control-input.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before, .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  background-color: #e4606d; }

.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before, .custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #FFFFFF, 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }

.was-validated .custom-file-input:invalid ~ .custom-file-label, .custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #dc3545; }
  .was-validated .custom-file-input:invalid ~ .custom-file-label::after, .custom-file-input.is-invalid ~ .custom-file-label::after {
    border-color: inherit; }

.was-validated .custom-file-input:invalid ~ .invalid-feedback,
.was-validated .custom-file-input:invalid ~ .invalid-tooltip, .custom-file-input.is-invalid ~ .invalid-feedback,
.custom-file-input.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .custom-file-input:invalid:focus ~ .custom-file-label, .custom-file-input.is-invalid:focus ~ .custom-file-label {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }

.form-inline, .search-form {
  display: flex;
  flex-flow: row wrap;
  align-items: center; }
  .form-inline .form-check, .search-form .form-check {
    width: 100%; }
  @media (min-width: 576px) {
    .form-inline label, .search-form label {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 0; }
    .form-inline .form-group, .search-form .form-group, .form-inline .comment-form p, .comment-form .form-inline p, .search-form .comment-form p, .comment-form .search-form p, .form-inline .search-form label, .search-form .form-inline label, .search-form label {
      display: flex;
      flex: 0 0 auto;
      flex-flow: row wrap;
      align-items: center;
      margin-bottom: 0; }
    .form-inline .form-control, .search-form .form-control, .form-inline .comment-form input[type="text"], .comment-form .form-inline input[type="text"], .search-form .comment-form input[type="text"], .comment-form .search-form input[type="text"], .form-inline
    .comment-form input[type="email"],
    .comment-form .form-inline input[type="email"], .search-form
    .comment-form input[type="email"],
    .comment-form .search-form input[type="email"], .form-inline
    .comment-form input[type="url"],
    .comment-form .form-inline input[type="url"], .search-form
    .comment-form input[type="url"],
    .comment-form .search-form input[type="url"], .form-inline
    .comment-form textarea,
    .comment-form .form-inline textarea, .search-form
    .comment-form textarea,
    .comment-form .search-form textarea, .search-form .search-field {
      display: inline-block;
      width: auto;
      vertical-align: middle; }
    .form-inline .form-control-plaintext, .search-form .form-control-plaintext {
      display: inline-block; }
    .form-inline .input-group, .search-form .input-group,
    .form-inline .custom-select, .search-form .custom-select {
      width: auto; }
    .form-inline .form-check, .search-form .form-check {
      display: flex;
      align-items: center;
      justify-content: center;
      width: auto;
      padding-left: 0; }
    .form-inline .form-check-input, .search-form .form-check-input {
      position: relative;
      margin-top: 0;
      margin-right: 0.25rem;
      margin-left: 0; }
    .form-inline .custom-control, .search-form .custom-control {
      align-items: center;
      justify-content: center; }
    .form-inline .custom-control-label, .search-form .custom-control-label {
      margin-bottom: 0; } }

.btn, .comment-form input[type="submit"], .search-form .search-submit {
  display: inline-block;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  user-select: none;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media screen and (prefers-reduced-motion: reduce) {
    .btn, .comment-form input[type="submit"], .search-form .search-submit {
      transition: none; } }
  .btn:hover, .comment-form input[type="submit"]:hover, .search-form .search-submit:hover, .btn:focus, .comment-form input[type="submit"]:focus, .search-form .search-submit:focus {
    text-decoration: none; }
  .btn:focus, .comment-form input[type="submit"]:focus, .search-form .search-submit:focus, .btn.focus, .comment-form input.focus[type="submit"], .search-form .focus.search-submit {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(7, 135, 230, 0.25); }
  .btn.disabled, .comment-form input.disabled[type="submit"], .search-form .disabled.search-submit, .btn:disabled, .comment-form input[type="submit"]:disabled, .search-form .search-submit:disabled {
    opacity: 0.65; }
  .btn:not(:disabled):not(.disabled), .comment-form input[type="submit"]:not(:disabled):not(.disabled), .search-form .search-submit:not(:disabled):not(.disabled) {
    cursor: pointer; }

a.btn.disabled, .search-form a.disabled.search-submit,
fieldset:disabled a.btn,
fieldset:disabled .search-form a.search-submit, .search-form
fieldset:disabled a.search-submit {
  pointer-events: none; }

.btn-primary {
  color: #FFFFFF;
  background-color: #0787e6;
  border-color: #0787e6; }
  .btn-primary:hover {
    color: #FFFFFF;
    background-color: #0671c1;
    border-color: #056ab5; }
  .btn-primary:focus, .btn-primary.focus {
    box-shadow: 0 0 0 0.2rem rgba(7, 135, 230, 0.5); }
  .btn-primary.disabled, .btn-primary:disabled {
    color: #FFFFFF;
    background-color: #0787e6;
    border-color: #0787e6; }
  .btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active,
  .show > .btn-primary.dropdown-toggle {
    color: #FFFFFF;
    background-color: #056ab5;
    border-color: #0563a8; }
    .btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-primary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(7, 135, 230, 0.5); }

.btn-secondary, .comment-form input[type="submit"], .search-form .search-submit {
  color: #FFFFFF;
  background-color: #6c757d;
  border-color: #6c757d; }
  .btn-secondary:hover, .comment-form input[type="submit"]:hover, .search-form .search-submit:hover {
    color: #FFFFFF;
    background-color: #5a6268;
    border-color: #545b62; }
  .btn-secondary:focus, .comment-form input[type="submit"]:focus, .search-form .search-submit:focus, .btn-secondary.focus, .comment-form input.focus[type="submit"], .search-form .focus.search-submit {
    box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5); }
  .btn-secondary.disabled, .comment-form input.disabled[type="submit"], .search-form .disabled.search-submit, .btn-secondary:disabled, .comment-form input[type="submit"]:disabled, .search-form .search-submit:disabled {
    color: #FFFFFF;
    background-color: #6c757d;
    border-color: #6c757d; }
  .btn-secondary:not(:disabled):not(.disabled):active, .comment-form input[type="submit"]:not(:disabled):not(.disabled):active, .search-form .search-submit:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active, .comment-form input[type="submit"]:not(:disabled):not(.disabled).active, .search-form .search-submit:not(:disabled):not(.disabled).active,
  .show > .btn-secondary.dropdown-toggle, .comment-form
  .show > input.dropdown-toggle[type="submit"], .search-form
  .show > .dropdown-toggle.search-submit {
    color: #FFFFFF;
    background-color: #545b62;
    border-color: #4e555b; }
    .btn-secondary:not(:disabled):not(.disabled):active:focus, .comment-form input[type="submit"]:not(:disabled):not(.disabled):active:focus, .search-form .search-submit:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus, .comment-form input[type="submit"]:not(:disabled):not(.disabled).active:focus, .search-form .search-submit:not(:disabled):not(.disabled).active:focus,
    .show > .btn-secondary.dropdown-toggle:focus, .comment-form
    .show > input.dropdown-toggle[type="submit"]:focus, .search-form
    .show > .dropdown-toggle.search-submit:focus {
      box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5); }

.btn-success {
  color: #FFFFFF;
  background-color: #28a745;
  border-color: #28a745; }
  .btn-success:hover {
    color: #FFFFFF;
    background-color: #218838;
    border-color: #1e7e34; }
  .btn-success:focus, .btn-success.focus {
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5); }
  .btn-success.disabled, .btn-success:disabled {
    color: #FFFFFF;
    background-color: #28a745;
    border-color: #28a745; }
  .btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active,
  .show > .btn-success.dropdown-toggle {
    color: #FFFFFF;
    background-color: #1e7e34;
    border-color: #1c7430; }
    .btn-success:not(:disabled):not(.disabled):active:focus, .btn-success:not(:disabled):not(.disabled).active:focus,
    .show > .btn-success.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5); }

.btn-info {
  color: #FFFFFF;
  background-color: #17a2b8;
  border-color: #17a2b8; }
  .btn-info:hover {
    color: #FFFFFF;
    background-color: #138496;
    border-color: #117a8b; }
  .btn-info:focus, .btn-info.focus {
    box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5); }
  .btn-info.disabled, .btn-info:disabled {
    color: #FFFFFF;
    background-color: #17a2b8;
    border-color: #17a2b8; }
  .btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active,
  .show > .btn-info.dropdown-toggle {
    color: #FFFFFF;
    background-color: #117a8b;
    border-color: #10707f; }
    .btn-info:not(:disabled):not(.disabled):active:focus, .btn-info:not(:disabled):not(.disabled).active:focus,
    .show > .btn-info.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5); }

.btn-warning {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107; }
  .btn-warning:hover {
    color: #212529;
    background-color: #e0a800;
    border-color: #d39e00; }
  .btn-warning:focus, .btn-warning.focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5); }
  .btn-warning.disabled, .btn-warning:disabled {
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107; }
  .btn-warning:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled).active,
  .show > .btn-warning.dropdown-toggle {
    color: #212529;
    background-color: #d39e00;
    border-color: #c69500; }
    .btn-warning:not(:disabled):not(.disabled):active:focus, .btn-warning:not(:disabled):not(.disabled).active:focus,
    .show > .btn-warning.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5); }

.btn-danger {
  color: #FFFFFF;
  background-color: #dc3545;
  border-color: #dc3545; }
  .btn-danger:hover {
    color: #FFFFFF;
    background-color: #c82333;
    border-color: #bd2130; }
  .btn-danger:focus, .btn-danger.focus {
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5); }
  .btn-danger.disabled, .btn-danger:disabled {
    color: #FFFFFF;
    background-color: #dc3545;
    border-color: #dc3545; }
  .btn-danger:not(:disabled):not(.disabled):active, .btn-danger:not(:disabled):not(.disabled).active,
  .show > .btn-danger.dropdown-toggle {
    color: #FFFFFF;
    background-color: #bd2130;
    border-color: #b21f2d; }
    .btn-danger:not(:disabled):not(.disabled):active:focus, .btn-danger:not(:disabled):not(.disabled).active:focus,
    .show > .btn-danger.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5); }

.btn-light {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa; }
  .btn-light:hover {
    color: #212529;
    background-color: #e2e6ea;
    border-color: #dae0e5; }
  .btn-light:focus, .btn-light.focus {
    box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }
  .btn-light.disabled, .btn-light:disabled {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
  .btn-light:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled).active,
  .show > .btn-light.dropdown-toggle {
    color: #212529;
    background-color: #dae0e5;
    border-color: #d3d9df; }
    .btn-light:not(:disabled):not(.disabled):active:focus, .btn-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-light.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }

.btn-dark {
  color: #FFFFFF;
  background-color: #343a40;
  border-color: #343a40; }
  .btn-dark:hover {
    color: #FFFFFF;
    background-color: #23272b;
    border-color: #1d2124; }
  .btn-dark:focus, .btn-dark.focus {
    box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }
  .btn-dark.disabled, .btn-dark:disabled {
    color: #FFFFFF;
    background-color: #343a40;
    border-color: #343a40; }
  .btn-dark:not(:disabled):not(.disabled):active, .btn-dark:not(:disabled):not(.disabled).active,
  .show > .btn-dark.dropdown-toggle {
    color: #FFFFFF;
    background-color: #1d2124;
    border-color: #171a1d; }
    .btn-dark:not(:disabled):not(.disabled):active:focus, .btn-dark:not(:disabled):not(.disabled).active:focus,
    .show > .btn-dark.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }

.btn-outline-primary {
  color: #0787e6;
  background-color: transparent;
  background-image: none;
  border-color: #0787e6; }
  .btn-outline-primary:hover {
    color: #FFFFFF;
    background-color: #0787e6;
    border-color: #0787e6; }
  .btn-outline-primary:focus, .btn-outline-primary.focus {
    box-shadow: 0 0 0 0.2rem rgba(7, 135, 230, 0.5); }
  .btn-outline-primary.disabled, .btn-outline-primary:disabled {
    color: #0787e6;
    background-color: transparent; }
  .btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-primary.dropdown-toggle {
    color: #FFFFFF;
    background-color: #0787e6;
    border-color: #0787e6; }
    .btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-primary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(7, 135, 230, 0.5); }

.btn-outline-secondary {
  color: #6c757d;
  background-color: transparent;
  background-image: none;
  border-color: #6c757d; }
  .btn-outline-secondary:hover {
    color: #FFFFFF;
    background-color: #6c757d;
    border-color: #6c757d; }
  .btn-outline-secondary:focus, .btn-outline-secondary.focus {
    box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5); }
  .btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
    color: #6c757d;
    background-color: transparent; }
  .btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-secondary.dropdown-toggle {
    color: #FFFFFF;
    background-color: #6c757d;
    border-color: #6c757d; }
    .btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-secondary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5); }

.btn-outline-success {
  color: #28a745;
  background-color: transparent;
  background-image: none;
  border-color: #28a745; }
  .btn-outline-success:hover {
    color: #FFFFFF;
    background-color: #28a745;
    border-color: #28a745; }
  .btn-outline-success:focus, .btn-outline-success.focus {
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5); }
  .btn-outline-success.disabled, .btn-outline-success:disabled {
    color: #28a745;
    background-color: transparent; }
  .btn-outline-success:not(:disabled):not(.disabled):active, .btn-outline-success:not(:disabled):not(.disabled).active,
  .show > .btn-outline-success.dropdown-toggle {
    color: #FFFFFF;
    background-color: #28a745;
    border-color: #28a745; }
    .btn-outline-success:not(:disabled):not(.disabled):active:focus, .btn-outline-success:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-success.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5); }

.btn-outline-info {
  color: #17a2b8;
  background-color: transparent;
  background-image: none;
  border-color: #17a2b8; }
  .btn-outline-info:hover {
    color: #FFFFFF;
    background-color: #17a2b8;
    border-color: #17a2b8; }
  .btn-outline-info:focus, .btn-outline-info.focus {
    box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5); }
  .btn-outline-info.disabled, .btn-outline-info:disabled {
    color: #17a2b8;
    background-color: transparent; }
  .btn-outline-info:not(:disabled):not(.disabled):active, .btn-outline-info:not(:disabled):not(.disabled).active,
  .show > .btn-outline-info.dropdown-toggle {
    color: #FFFFFF;
    background-color: #17a2b8;
    border-color: #17a2b8; }
    .btn-outline-info:not(:disabled):not(.disabled):active:focus, .btn-outline-info:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-info.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5); }

.btn-outline-warning {
  color: #ffc107;
  background-color: transparent;
  background-image: none;
  border-color: #ffc107; }
  .btn-outline-warning:hover {
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107; }
  .btn-outline-warning:focus, .btn-outline-warning.focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5); }
  .btn-outline-warning.disabled, .btn-outline-warning:disabled {
    color: #ffc107;
    background-color: transparent; }
  .btn-outline-warning:not(:disabled):not(.disabled):active, .btn-outline-warning:not(:disabled):not(.disabled).active,
  .show > .btn-outline-warning.dropdown-toggle {
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107; }
    .btn-outline-warning:not(:disabled):not(.disabled):active:focus, .btn-outline-warning:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-warning.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5); }

.btn-outline-danger {
  color: #dc3545;
  background-color: transparent;
  background-image: none;
  border-color: #dc3545; }
  .btn-outline-danger:hover {
    color: #FFFFFF;
    background-color: #dc3545;
    border-color: #dc3545; }
  .btn-outline-danger:focus, .btn-outline-danger.focus {
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5); }
  .btn-outline-danger.disabled, .btn-outline-danger:disabled {
    color: #dc3545;
    background-color: transparent; }
  .btn-outline-danger:not(:disabled):not(.disabled):active, .btn-outline-danger:not(:disabled):not(.disabled).active,
  .show > .btn-outline-danger.dropdown-toggle {
    color: #FFFFFF;
    background-color: #dc3545;
    border-color: #dc3545; }
    .btn-outline-danger:not(:disabled):not(.disabled):active:focus, .btn-outline-danger:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-danger.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5); }

.btn-outline-light {
  color: #f8f9fa;
  background-color: transparent;
  background-image: none;
  border-color: #f8f9fa; }
  .btn-outline-light:hover {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
  .btn-outline-light:focus, .btn-outline-light.focus {
    box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }
  .btn-outline-light.disabled, .btn-outline-light:disabled {
    color: #f8f9fa;
    background-color: transparent; }
  .btn-outline-light:not(:disabled):not(.disabled):active, .btn-outline-light:not(:disabled):not(.disabled).active,
  .show > .btn-outline-light.dropdown-toggle {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
    .btn-outline-light:not(:disabled):not(.disabled):active:focus, .btn-outline-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-light.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }

.btn-outline-dark {
  color: #343a40;
  background-color: transparent;
  background-image: none;
  border-color: #343a40; }
  .btn-outline-dark:hover {
    color: #FFFFFF;
    background-color: #343a40;
    border-color: #343a40; }
  .btn-outline-dark:focus, .btn-outline-dark.focus {
    box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }
  .btn-outline-dark.disabled, .btn-outline-dark:disabled {
    color: #343a40;
    background-color: transparent; }
  .btn-outline-dark:not(:disabled):not(.disabled):active, .btn-outline-dark:not(:disabled):not(.disabled).active,
  .show > .btn-outline-dark.dropdown-toggle {
    color: #FFFFFF;
    background-color: #343a40;
    border-color: #343a40; }
    .btn-outline-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-dark:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-dark.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }

.btn-link {
  font-weight: 400;
  color: #0787e6;
  background-color: transparent; }
  .btn-link:hover {
    color: #055b9c;
    text-decoration: underline;
    background-color: transparent;
    border-color: transparent; }
  .btn-link:focus, .btn-link.focus {
    text-decoration: underline;
    border-color: transparent;
    box-shadow: none; }
  .btn-link:disabled, .btn-link.disabled {
    color: #6c757d;
    pointer-events: none; }

.btn-lg, .btn-group-lg > .btn, .comment-form .btn-group-lg > input[type="submit"], .search-form .btn-group-lg > .search-submit {
  padding: 0.5rem 1rem;
  font-size: 1.09375rem;
  line-height: 1.5;
  border-radius: 0.3rem; }

.btn-sm, .btn-group-sm > .btn, .comment-form .btn-group-sm > input[type="submit"], .search-form .btn-group-sm > .search-submit {
  padding: 0.25rem 0.5rem;
  font-size: 0.765625rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

.btn-block {
  display: block;
  width: 100%; }
  .btn-block + .btn-block {
    margin-top: 0.5rem; }

input[type="submit"].btn-block,
input[type="reset"].btn-block,
input[type="button"].btn-block {
  width: 100%; }

.fade {
  transition: opacity 0.15s linear; }
  @media screen and (prefers-reduced-motion: reduce) {
    .fade {
      transition: none; } }
  .fade:not(.show) {
    opacity: 0; }

.collapse:not(.show) {
  display: none; }

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease; }
  @media screen and (prefers-reduced-motion: reduce) {
    .collapsing {
      transition: none; } }

.dropup,
.dropright,
.dropdown,
.dropleft {
  position: relative; }

.dropdown-toggle::after {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent; }

.dropdown-toggle:empty::after {
  margin-left: 0; }

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 0.875rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #FFFFFF;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem; }

.dropdown-menu-right {
  right: 0;
  left: auto; }

.dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem; }

.dropup .dropdown-toggle::after {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent; }

.dropup .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropright .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem; }

.dropright .dropdown-toggle::after {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid; }

.dropright .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropright .dropdown-toggle::after {
  vertical-align: 0; }

.dropleft .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem; }

.dropleft .dropdown-toggle::after {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: ""; }

.dropleft .dropdown-toggle::after {
  display: none; }

.dropleft .dropdown-toggle::before {
  display: inline-block;
  width: 0;
  height: 0;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent; }

.dropleft .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropleft .dropdown-toggle::before {
  vertical-align: 0; }

.dropdown-menu[x-placement^="top"], .dropdown-menu[x-placement^="right"], .dropdown-menu[x-placement^="bottom"], .dropdown-menu[x-placement^="left"] {
  right: auto;
  bottom: auto; }

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #e9ecef; }

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0; }
  .dropdown-item:hover, .dropdown-item:focus {
    color: #16181b;
    text-decoration: none;
    background-color: #f8f9fa; }
  .dropdown-item.active, .dropdown-item:active {
    color: #FFFFFF;
    text-decoration: none;
    background-color: #0787e6; }
  .dropdown-item.disabled, .dropdown-item:disabled {
    color: #6c757d;
    background-color: transparent; }

.dropdown-menu.show {
  display: block; }

.dropdown-header {
  display: block;
  padding: 0.5rem 1.5rem;
  margin-bottom: 0;
  font-size: 0.765625rem;
  color: #6c757d;
  white-space: nowrap; }

.dropdown-item-text {
  display: block;
  padding: 0.25rem 1.5rem;
  color: #212529; }

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle; }
  .btn-group > .btn, .comment-form .btn-group > input[type="submit"], .search-form .btn-group > .search-submit,
  .btn-group-vertical > .btn, .comment-form
  .btn-group-vertical > input[type="submit"], .search-form
  .btn-group-vertical > .search-submit {
    position: relative;
    flex: 0 1 auto; }
    .btn-group > .btn:hover, .comment-form .btn-group > input[type="submit"]:hover, .search-form .btn-group > .search-submit:hover,
    .btn-group-vertical > .btn:hover, .comment-form
    .btn-group-vertical > input[type="submit"]:hover, .search-form
    .btn-group-vertical > .search-submit:hover {
      z-index: 1; }
    .btn-group > .btn:focus, .comment-form .btn-group > input[type="submit"]:focus, .search-form .btn-group > .search-submit:focus, .btn-group > .btn:active, .comment-form .btn-group > input[type="submit"]:active, .search-form .btn-group > .search-submit:active, .btn-group > .btn.active, .comment-form .btn-group > input.active[type="submit"], .search-form .btn-group > .active.search-submit,
    .btn-group-vertical > .btn:focus, .comment-form
    .btn-group-vertical > input[type="submit"]:focus, .search-form
    .btn-group-vertical > .search-submit:focus,
    .btn-group-vertical > .btn:active, .comment-form
    .btn-group-vertical > input[type="submit"]:active, .search-form
    .btn-group-vertical > .search-submit:active,
    .btn-group-vertical > .btn.active, .comment-form
    .btn-group-vertical > input.active[type="submit"], .search-form
    .btn-group-vertical > .active.search-submit {
      z-index: 1; }
  .btn-group .btn + .btn, .btn-group .comment-form input[type="submit"] + .btn, .comment-form .btn-group input[type="submit"] + .btn, .btn-group .search-form .search-submit + .btn, .search-form .btn-group .search-submit + .btn, .btn-group .comment-form .btn + input[type="submit"], .comment-form .btn-group .btn + input[type="submit"], .btn-group .comment-form input[type="submit"] + input[type="submit"], .comment-form .btn-group input[type="submit"] + input[type="submit"], .btn-group .search-form .comment-form .search-submit + input[type="submit"], .comment-form .btn-group .search-form .search-submit + input[type="submit"], .search-form .btn-group .comment-form .search-submit + input[type="submit"], .comment-form .search-form .btn-group .search-submit + input[type="submit"], .btn-group .search-form .btn + .search-submit, .search-form .btn-group .btn + .search-submit, .btn-group .comment-form .search-form input[type="submit"] + .search-submit, .search-form .btn-group .comment-form input[type="submit"] + .search-submit, .comment-form .btn-group .search-form input[type="submit"] + .search-submit, .search-form .comment-form .btn-group input[type="submit"] + .search-submit, .btn-group .search-form .search-submit + .search-submit, .search-form .btn-group .search-submit + .search-submit,
  .btn-group .btn + .btn-group,
  .btn-group .comment-form input[type="submit"] + .btn-group, .comment-form
  .btn-group input[type="submit"] + .btn-group,
  .btn-group .search-form .search-submit + .btn-group, .search-form
  .btn-group .search-submit + .btn-group,
  .btn-group .btn-group + .btn,
  .btn-group .comment-form .btn-group + input[type="submit"], .comment-form
  .btn-group .btn-group + input[type="submit"],
  .btn-group .search-form .btn-group + .search-submit, .search-form
  .btn-group .btn-group + .search-submit,
  .btn-group .btn-group + .btn-group,
  .btn-group-vertical .btn + .btn,
  .btn-group-vertical .comment-form input[type="submit"] + .btn, .comment-form
  .btn-group-vertical input[type="submit"] + .btn,
  .btn-group-vertical .search-form .search-submit + .btn, .search-form
  .btn-group-vertical .search-submit + .btn,
  .btn-group-vertical .comment-form .btn + input[type="submit"], .comment-form
  .btn-group-vertical .btn + input[type="submit"],
  .btn-group-vertical .comment-form input[type="submit"] + input[type="submit"], .comment-form
  .btn-group-vertical input[type="submit"] + input[type="submit"],
  .btn-group-vertical .search-form .comment-form .search-submit + input[type="submit"], .comment-form
  .btn-group-vertical .search-form .search-submit + input[type="submit"], .search-form
  .btn-group-vertical .comment-form .search-submit + input[type="submit"], .comment-form .search-form
  .btn-group-vertical .search-submit + input[type="submit"],
  .btn-group-vertical .search-form .btn + .search-submit, .search-form
  .btn-group-vertical .btn + .search-submit,
  .btn-group-vertical .comment-form .search-form input[type="submit"] + .search-submit, .search-form
  .btn-group-vertical .comment-form input[type="submit"] + .search-submit, .comment-form
  .btn-group-vertical .search-form input[type="submit"] + .search-submit, .search-form .comment-form
  .btn-group-vertical input[type="submit"] + .search-submit,
  .btn-group-vertical .search-form .search-submit + .search-submit, .search-form
  .btn-group-vertical .search-submit + .search-submit,
  .btn-group-vertical .btn + .btn-group,
  .btn-group-vertical .comment-form input[type="submit"] + .btn-group, .comment-form
  .btn-group-vertical input[type="submit"] + .btn-group,
  .btn-group-vertical .search-form .search-submit + .btn-group, .search-form
  .btn-group-vertical .search-submit + .btn-group,
  .btn-group-vertical .btn-group + .btn,
  .btn-group-vertical .comment-form .btn-group + input[type="submit"], .comment-form
  .btn-group-vertical .btn-group + input[type="submit"],
  .btn-group-vertical .search-form .btn-group + .search-submit, .search-form
  .btn-group-vertical .btn-group + .search-submit,
  .btn-group-vertical .btn-group + .btn-group {
    margin-left: -1px; }

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start; }
  .btn-toolbar .input-group {
    width: auto; }

.btn-group > .btn:first-child, .comment-form .btn-group > input[type="submit"]:first-child, .search-form .btn-group > .search-submit:first-child {
  margin-left: 0; }

.btn-group > .btn:not(:last-child):not(.dropdown-toggle), .comment-form .btn-group > input[type="submit"]:not(:last-child):not(.dropdown-toggle), .search-form .btn-group > .search-submit:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn, .comment-form
.btn-group > .btn-group:not(:last-child) > input[type="submit"], .search-form
.btn-group > .btn-group:not(:last-child) > .search-submit {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.btn-group > .btn:not(:first-child), .comment-form .btn-group > input[type="submit"]:not(:first-child), .search-form .btn-group > .search-submit:not(:first-child),
.btn-group > .btn-group:not(:first-child) > .btn, .comment-form
.btn-group > .btn-group:not(:first-child) > input[type="submit"], .search-form
.btn-group > .btn-group:not(:first-child) > .search-submit {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.dropdown-toggle-split {
  padding-right: 0.5625rem;
  padding-left: 0.5625rem; }
  .dropdown-toggle-split::after,
  .dropup .dropdown-toggle-split::after,
  .dropright .dropdown-toggle-split::after {
    margin-left: 0; }
  .dropleft .dropdown-toggle-split::before {
    margin-right: 0; }

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split, .comment-form .btn-group-sm > input[type="submit"] + .dropdown-toggle-split, .search-form .btn-group-sm > .search-submit + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem; }

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split, .comment-form .btn-group-lg > input[type="submit"] + .dropdown-toggle-split, .search-form .btn-group-lg > .search-submit + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem; }

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center; }
  .btn-group-vertical .btn, .btn-group-vertical .comment-form input[type="submit"], .comment-form .btn-group-vertical input[type="submit"], .btn-group-vertical .search-form .search-submit, .search-form .btn-group-vertical .search-submit,
  .btn-group-vertical .btn-group {
    width: 100%; }
  .btn-group-vertical > .btn + .btn, .comment-form .btn-group-vertical > input[type="submit"] + .btn, .search-form .btn-group-vertical > .search-submit + .btn, .comment-form .btn-group-vertical > .btn + input[type="submit"], .comment-form .btn-group-vertical > input[type="submit"] + input[type="submit"], .search-form .comment-form .btn-group-vertical > .search-submit + input[type="submit"], .comment-form .search-form .btn-group-vertical > .search-submit + input[type="submit"], .search-form .btn-group-vertical > .btn + .search-submit, .comment-form .search-form .btn-group-vertical > input[type="submit"] + .search-submit, .search-form .comment-form .btn-group-vertical > input[type="submit"] + .search-submit, .search-form .btn-group-vertical > .search-submit + .search-submit,
  .btn-group-vertical > .btn + .btn-group, .comment-form
  .btn-group-vertical > input[type="submit"] + .btn-group, .search-form
  .btn-group-vertical > .search-submit + .btn-group,
  .btn-group-vertical > .btn-group + .btn, .comment-form
  .btn-group-vertical > .btn-group + input[type="submit"], .search-form
  .btn-group-vertical > .btn-group + .search-submit,
  .btn-group-vertical > .btn-group + .btn-group {
    margin-top: -1px;
    margin-left: 0; }
  .btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle), .comment-form .btn-group-vertical > input[type="submit"]:not(:last-child):not(.dropdown-toggle), .search-form .btn-group-vertical > .search-submit:not(:last-child):not(.dropdown-toggle),
  .btn-group-vertical > .btn-group:not(:last-child) > .btn, .comment-form
  .btn-group-vertical > .btn-group:not(:last-child) > input[type="submit"], .search-form
  .btn-group-vertical > .btn-group:not(:last-child) > .search-submit {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0; }
  .btn-group-vertical > .btn:not(:first-child), .comment-form .btn-group-vertical > input[type="submit"]:not(:first-child), .search-form .btn-group-vertical > .search-submit:not(:first-child),
  .btn-group-vertical > .btn-group:not(:first-child) > .btn, .comment-form
  .btn-group-vertical > .btn-group:not(:first-child) > input[type="submit"], .search-form
  .btn-group-vertical > .btn-group:not(:first-child) > .search-submit {
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.btn-group-toggle > .btn, .comment-form .btn-group-toggle > input[type="submit"], .search-form .btn-group-toggle > .search-submit,
.btn-group-toggle > .btn-group > .btn, .comment-form
.btn-group-toggle > .btn-group > input[type="submit"], .search-form
.btn-group-toggle > .btn-group > .search-submit {
  margin-bottom: 0; }
  .btn-group-toggle > .btn input[type="radio"], .comment-form .btn-group-toggle > input[type="submit"] input[type="radio"], .search-form .btn-group-toggle > .search-submit input[type="radio"],
  .btn-group-toggle > .btn input[type="checkbox"], .comment-form
  .btn-group-toggle > input[type="submit"] input[type="checkbox"], .search-form
  .btn-group-toggle > .search-submit input[type="checkbox"],
  .btn-group-toggle > .btn-group > .btn input[type="radio"], .comment-form
  .btn-group-toggle > .btn-group > input[type="submit"] input[type="radio"], .search-form
  .btn-group-toggle > .btn-group > .search-submit input[type="radio"],
  .btn-group-toggle > .btn-group > .btn input[type="checkbox"], .comment-form
  .btn-group-toggle > .btn-group > input[type="submit"] input[type="checkbox"], .search-form
  .btn-group-toggle > .btn-group > .search-submit input[type="checkbox"] {
    position: absolute;
    clip: rect(0, 0, 0, 0);
    pointer-events: none; }

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%; }
  .input-group > .form-control, .comment-form .input-group > input[type="text"],
  .comment-form .input-group > input[type="email"],
  .comment-form .input-group > input[type="url"],
  .comment-form .input-group > textarea, .search-form .input-group > .search-field,
  .input-group > .custom-select,
  .input-group > .custom-file {
    position: relative;
    flex: 1 1 auto;
    width: 1%;
    margin-bottom: 0; }
    .input-group > .form-control + .form-control, .comment-form .input-group > input[type="text"] + .form-control,
    .comment-form .input-group > input[type="email"] + .form-control,
    .comment-form .input-group > input[type="url"] + .form-control,
    .comment-form .input-group > textarea + .form-control, .search-form .input-group > .search-field + .form-control, .comment-form .input-group > .form-control + input[type="text"], .comment-form .input-group > input[type="text"] + input[type="text"], .comment-form .input-group > input[type="email"] + input[type="text"], .comment-form .input-group > input[type="url"] + input[type="text"], .comment-form .input-group > textarea + input[type="text"], .search-form .comment-form .input-group > .search-field + input[type="text"], .comment-form .search-form .input-group > .search-field + input[type="text"],
    .comment-form .input-group > .form-control + input[type="email"], .comment-form .input-group > input[type="text"] + input[type="email"], .comment-form .input-group > input[type="email"] + input[type="email"], .comment-form .input-group > input[type="url"] + input[type="email"], .comment-form .input-group > textarea + input[type="email"], .search-form
    .comment-form .input-group > .search-field + input[type="email"],
    .comment-form .search-form .input-group > .search-field + input[type="email"],
    .comment-form .input-group > .form-control + input[type="url"], .comment-form .input-group > input[type="text"] + input[type="url"], .comment-form .input-group > input[type="email"] + input[type="url"], .comment-form .input-group > input[type="url"] + input[type="url"], .comment-form .input-group > textarea + input[type="url"], .search-form
    .comment-form .input-group > .search-field + input[type="url"],
    .comment-form .search-form .input-group > .search-field + input[type="url"],
    .comment-form .input-group > .form-control + textarea, .comment-form .input-group > input[type="text"] + textarea, .comment-form .input-group > input[type="email"] + textarea, .comment-form .input-group > input[type="url"] + textarea, .comment-form .input-group > textarea + textarea, .search-form
    .comment-form .input-group > .search-field + textarea,
    .comment-form .search-form .input-group > .search-field + textarea, .search-form .input-group > .form-control + .search-field, .comment-form .search-form .input-group > input[type="text"] + .search-field, .search-form .comment-form .input-group > input[type="text"] + .search-field,
    .comment-form .search-form .input-group > input[type="email"] + .search-field, .search-form
    .comment-form .input-group > input[type="email"] + .search-field,
    .comment-form .search-form .input-group > input[type="url"] + .search-field, .search-form
    .comment-form .input-group > input[type="url"] + .search-field,
    .comment-form .search-form .input-group > textarea + .search-field, .search-form
    .comment-form .input-group > textarea + .search-field, .search-form .input-group > .search-field + .search-field,
    .input-group > .form-control + .custom-select, .comment-form
    .input-group > input[type="text"] + .custom-select,
    .comment-form
    .input-group > input[type="email"] + .custom-select,
    .comment-form
    .input-group > input[type="url"] + .custom-select,
    .comment-form
    .input-group > textarea + .custom-select, .search-form
    .input-group > .search-field + .custom-select,
    .input-group > .form-control + .custom-file, .comment-form
    .input-group > input[type="text"] + .custom-file,
    .comment-form
    .input-group > input[type="email"] + .custom-file,
    .comment-form
    .input-group > input[type="url"] + .custom-file,
    .comment-form
    .input-group > textarea + .custom-file, .search-form
    .input-group > .search-field + .custom-file,
    .input-group > .custom-select + .form-control, .comment-form
    .input-group > .custom-select + input[type="text"],
    .comment-form
    .input-group > .custom-select + input[type="email"],
    .comment-form
    .input-group > .custom-select + input[type="url"],
    .comment-form
    .input-group > .custom-select + textarea, .search-form
    .input-group > .custom-select + .search-field,
    .input-group > .custom-select + .custom-select,
    .input-group > .custom-select + .custom-file,
    .input-group > .custom-file + .form-control, .comment-form
    .input-group > .custom-file + input[type="text"],
    .comment-form
    .input-group > .custom-file + input[type="email"],
    .comment-form
    .input-group > .custom-file + input[type="url"],
    .comment-form
    .input-group > .custom-file + textarea, .search-form
    .input-group > .custom-file + .search-field,
    .input-group > .custom-file + .custom-select,
    .input-group > .custom-file + .custom-file {
      margin-left: -1px; }
  .input-group > .form-control:focus, .comment-form .input-group > input[type="text"]:focus,
  .comment-form .input-group > input[type="email"]:focus,
  .comment-form .input-group > input[type="url"]:focus,
  .comment-form .input-group > textarea:focus, .search-form .input-group > .search-field:focus,
  .input-group > .custom-select:focus,
  .input-group > .custom-file .custom-file-input:focus ~ .custom-file-label {
    z-index: 3; }
  .input-group > .custom-file .custom-file-input:focus {
    z-index: 4; }
  .input-group > .form-control:not(:last-child), .comment-form .input-group > input[type="text"]:not(:last-child),
  .comment-form .input-group > input[type="email"]:not(:last-child),
  .comment-form .input-group > input[type="url"]:not(:last-child),
  .comment-form .input-group > textarea:not(:last-child), .search-form .input-group > .search-field:not(:last-child),
  .input-group > .custom-select:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0; }
  .input-group > .form-control:not(:first-child), .comment-form .input-group > input[type="text"]:not(:first-child),
  .comment-form .input-group > input[type="email"]:not(:first-child),
  .comment-form .input-group > input[type="url"]:not(:first-child),
  .comment-form .input-group > textarea:not(:first-child), .search-form .input-group > .search-field:not(:first-child),
  .input-group > .custom-select:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0; }
  .input-group > .custom-file {
    display: flex;
    align-items: center; }
    .input-group > .custom-file:not(:last-child) .custom-file-label,
    .input-group > .custom-file:not(:last-child) .custom-file-label::after {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0; }
    .input-group > .custom-file:not(:first-child) .custom-file-label {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0; }

.input-group-prepend,
.input-group-append {
  display: flex; }
  .input-group-prepend .btn, .input-group-prepend .comment-form input[type="submit"], .comment-form .input-group-prepend input[type="submit"], .input-group-prepend .search-form .search-submit, .search-form .input-group-prepend .search-submit,
  .input-group-append .btn,
  .input-group-append .comment-form input[type="submit"], .comment-form
  .input-group-append input[type="submit"],
  .input-group-append .search-form .search-submit, .search-form
  .input-group-append .search-submit {
    position: relative;
    z-index: 2; }
  .input-group-prepend .btn + .btn, .input-group-prepend .comment-form input[type="submit"] + .btn, .comment-form .input-group-prepend input[type="submit"] + .btn, .input-group-prepend .search-form .search-submit + .btn, .search-form .input-group-prepend .search-submit + .btn, .input-group-prepend .comment-form .btn + input[type="submit"], .comment-form .input-group-prepend .btn + input[type="submit"], .input-group-prepend .comment-form input[type="submit"] + input[type="submit"], .comment-form .input-group-prepend input[type="submit"] + input[type="submit"], .input-group-prepend .search-form .comment-form .search-submit + input[type="submit"], .comment-form .input-group-prepend .search-form .search-submit + input[type="submit"], .search-form .input-group-prepend .comment-form .search-submit + input[type="submit"], .comment-form .search-form .input-group-prepend .search-submit + input[type="submit"], .input-group-prepend .search-form .btn + .search-submit, .search-form .input-group-prepend .btn + .search-submit, .input-group-prepend .comment-form .search-form input[type="submit"] + .search-submit, .search-form .input-group-prepend .comment-form input[type="submit"] + .search-submit, .comment-form .input-group-prepend .search-form input[type="submit"] + .search-submit, .search-form .comment-form .input-group-prepend input[type="submit"] + .search-submit, .input-group-prepend .search-form .search-submit + .search-submit, .search-form .input-group-prepend .search-submit + .search-submit,
  .input-group-prepend .btn + .input-group-text,
  .input-group-prepend .comment-form input[type="submit"] + .input-group-text, .comment-form
  .input-group-prepend input[type="submit"] + .input-group-text,
  .input-group-prepend .search-form .search-submit + .input-group-text, .search-form
  .input-group-prepend .search-submit + .input-group-text,
  .input-group-prepend .input-group-text + .input-group-text,
  .input-group-prepend .input-group-text + .btn,
  .input-group-prepend .comment-form .input-group-text + input[type="submit"], .comment-form
  .input-group-prepend .input-group-text + input[type="submit"],
  .input-group-prepend .search-form .input-group-text + .search-submit, .search-form
  .input-group-prepend .input-group-text + .search-submit,
  .input-group-append .btn + .btn,
  .input-group-append .comment-form input[type="submit"] + .btn, .comment-form
  .input-group-append input[type="submit"] + .btn,
  .input-group-append .search-form .search-submit + .btn, .search-form
  .input-group-append .search-submit + .btn,
  .input-group-append .comment-form .btn + input[type="submit"], .comment-form
  .input-group-append .btn + input[type="submit"],
  .input-group-append .comment-form input[type="submit"] + input[type="submit"], .comment-form
  .input-group-append input[type="submit"] + input[type="submit"],
  .input-group-append .search-form .comment-form .search-submit + input[type="submit"], .comment-form
  .input-group-append .search-form .search-submit + input[type="submit"], .search-form
  .input-group-append .comment-form .search-submit + input[type="submit"], .comment-form .search-form
  .input-group-append .search-submit + input[type="submit"],
  .input-group-append .search-form .btn + .search-submit, .search-form
  .input-group-append .btn + .search-submit,
  .input-group-append .comment-form .search-form input[type="submit"] + .search-submit, .search-form
  .input-group-append .comment-form input[type="submit"] + .search-submit, .comment-form
  .input-group-append .search-form input[type="submit"] + .search-submit, .search-form .comment-form
  .input-group-append input[type="submit"] + .search-submit,
  .input-group-append .search-form .search-submit + .search-submit, .search-form
  .input-group-append .search-submit + .search-submit,
  .input-group-append .btn + .input-group-text,
  .input-group-append .comment-form input[type="submit"] + .input-group-text, .comment-form
  .input-group-append input[type="submit"] + .input-group-text,
  .input-group-append .search-form .search-submit + .input-group-text, .search-form
  .input-group-append .search-submit + .input-group-text,
  .input-group-append .input-group-text + .input-group-text,
  .input-group-append .input-group-text + .btn,
  .input-group-append .comment-form .input-group-text + input[type="submit"], .comment-form
  .input-group-append .input-group-text + input[type="submit"],
  .input-group-append .search-form .input-group-text + .search-submit, .search-form
  .input-group-append .input-group-text + .search-submit {
    margin-left: -1px; }

.input-group-prepend {
  margin-right: -1px; }

.input-group-append {
  margin-left: -1px; }

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: 0.25rem; }
  .input-group-text input[type="radio"],
  .input-group-text input[type="checkbox"] {
    margin-top: 0; }

.input-group-lg > .form-control, .comment-form .input-group-lg > input[type="text"],
.comment-form .input-group-lg > input[type="email"],
.comment-form .input-group-lg > input[type="url"],
.comment-form .input-group-lg > textarea, .search-form .input-group-lg > .search-field,
.input-group-lg > .input-group-prepend > .input-group-text,
.input-group-lg > .input-group-append > .input-group-text,
.input-group-lg > .input-group-prepend > .btn, .comment-form
.input-group-lg > .input-group-prepend > input[type="submit"], .search-form
.input-group-lg > .input-group-prepend > .search-submit,
.input-group-lg > .input-group-append > .btn, .comment-form
.input-group-lg > .input-group-append > input[type="submit"], .search-form
.input-group-lg > .input-group-append > .search-submit {
  height: calc(2.640625rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 1.09375rem;
  line-height: 1.5;
  border-radius: 0.3rem; }

.input-group-sm > .form-control, .comment-form .input-group-sm > input[type="text"],
.comment-form .input-group-sm > input[type="email"],
.comment-form .input-group-sm > input[type="url"],
.comment-form .input-group-sm > textarea, .search-form .input-group-sm > .search-field,
.input-group-sm > .input-group-prepend > .input-group-text,
.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-prepend > .btn, .comment-form
.input-group-sm > .input-group-prepend > input[type="submit"], .search-form
.input-group-sm > .input-group-prepend > .search-submit,
.input-group-sm > .input-group-append > .btn, .comment-form
.input-group-sm > .input-group-append > input[type="submit"], .search-form
.input-group-sm > .input-group-append > .search-submit {
  height: calc(1.6484375rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.765625rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

.input-group > .input-group-prepend > .btn, .comment-form .input-group > .input-group-prepend > input[type="submit"], .search-form .input-group > .input-group-prepend > .search-submit,
.input-group > .input-group-prepend > .input-group-text,
.input-group > .input-group-append:not(:last-child) > .btn, .comment-form
.input-group > .input-group-append:not(:last-child) > input[type="submit"], .search-form
.input-group > .input-group-append:not(:last-child) > .search-submit,
.input-group > .input-group-append:not(:last-child) > .input-group-text,
.input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle), .comment-form
.input-group > .input-group-append:last-child > input[type="submit"]:not(:last-child):not(.dropdown-toggle), .search-form
.input-group > .input-group-append:last-child > .search-submit:not(:last-child):not(.dropdown-toggle),
.input-group > .input-group-append:last-child > .input-group-text:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.input-group > .input-group-append > .btn, .comment-form .input-group > .input-group-append > input[type="submit"], .search-form .input-group > .input-group-append > .search-submit,
.input-group > .input-group-append > .input-group-text,
.input-group > .input-group-prepend:not(:first-child) > .btn, .comment-form
.input-group > .input-group-prepend:not(:first-child) > input[type="submit"], .search-form
.input-group > .input-group-prepend:not(:first-child) > .search-submit,
.input-group > .input-group-prepend:not(:first-child) > .input-group-text,
.input-group > .input-group-prepend:first-child > .btn:not(:first-child), .comment-form
.input-group > .input-group-prepend:first-child > input[type="submit"]:not(:first-child), .search-form
.input-group > .input-group-prepend:first-child > .search-submit:not(:first-child),
.input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.custom-control {
  position: relative;
  display: block;
  min-height: 1.3125rem;
  padding-left: 1.5rem; }

.custom-control-inline {
  display: inline-flex;
  margin-right: 1rem; }

.custom-control-input {
  position: absolute;
  z-index: -1;
  opacity: 0; }
  .custom-control-input:checked ~ .custom-control-label::before {
    color: #FFFFFF;
    background-color: #0787e6; }
  .custom-control-input:focus ~ .custom-control-label::before {
    box-shadow: 0 0 0 1px #FFFFFF, 0 0 0 0.2rem rgba(7, 135, 230, 0.25); }
  .custom-control-input:active ~ .custom-control-label::before {
    color: #FFFFFF;
    background-color: #a3d6fc; }
  .custom-control-input:disabled ~ .custom-control-label {
    color: #6c757d; }
    .custom-control-input:disabled ~ .custom-control-label::before {
      background-color: #e9ecef; }

.custom-control-label {
  position: relative;
  margin-bottom: 0; }
  .custom-control-label::before {
    position: absolute;
    top: 0.15625rem;
    left: -1.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    pointer-events: none;
    content: "";
    user-select: none;
    background-color: #dee2e6; }
  .custom-control-label::after {
    position: absolute;
    top: 0.15625rem;
    left: -1.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    content: "";
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 50% 50%; }

.custom-checkbox .custom-control-label::before {
  border-radius: 0.25rem; }

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #0787e6; }

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%23FFFFFF' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E"); }

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
  background-color: #0787e6; }

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 4'%3E%3Cpath stroke='%23FFFFFF' d='M0 2h4'/%3E%3C/svg%3E"); }

.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(7, 135, 230, 0.5); }

.custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label::before {
  background-color: rgba(7, 135, 230, 0.5); }

.custom-radio .custom-control-label::before {
  border-radius: 50%; }

.custom-radio .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #0787e6; }

.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3E%3Ccircle r='3' fill='%23FFFFFF'/%3E%3C/svg%3E"); }

.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(7, 135, 230, 0.5); }

.custom-select {
  display: inline-block;
  width: 100%;
  height: calc(2.0625rem + 2px);
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  line-height: 1.5;
  color: #495057;
  vertical-align: middle;
  background: #FFFFFF url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3E%3Cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3E%3C/svg%3E") no-repeat right 0.75rem center;
  background-size: 8px 10px;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  appearance: none; }
  .custom-select:focus {
    border-color: #72c0fb;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(114, 192, 251, 0.5); }
    .custom-select:focus::-ms-value {
      color: #495057;
      background-color: #FFFFFF; }
  .custom-select[multiple], .custom-select[size]:not([size="1"]) {
    height: auto;
    padding-right: 0.75rem;
    background-image: none; }
  .custom-select:disabled {
    color: #6c757d;
    background-color: #e9ecef; }
  .custom-select::-ms-expand {
    opacity: 0; }

.custom-select-sm {
  height: calc(1.6484375rem + 2px);
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  font-size: 75%; }

.custom-select-lg {
  height: calc(2.640625rem + 2px);
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  font-size: 125%; }

.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: calc(2.0625rem + 2px);
  margin-bottom: 0; }

.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: calc(2.0625rem + 2px);
  margin: 0;
  opacity: 0; }
  .custom-file-input:focus ~ .custom-file-label {
    border-color: #72c0fb;
    box-shadow: 0 0 0 0.2rem rgba(7, 135, 230, 0.25); }
    .custom-file-input:focus ~ .custom-file-label::after {
      border-color: #72c0fb; }
  .custom-file-input:disabled ~ .custom-file-label {
    background-color: #e9ecef; }
  .custom-file-input:lang(en) ~ .custom-file-label::after {
    content: "Browse"; }

.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: calc(2.0625rem + 2px);
  padding: 0.375rem 0.75rem;
  line-height: 1.5;
  color: #495057;
  background-color: #FFFFFF;
  border: 1px solid #ced4da;
  border-radius: 0.25rem; }
  .custom-file-label::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 3;
    display: block;
    height: 2.0625rem;
    padding: 0.375rem 0.75rem;
    line-height: 1.5;
    color: #495057;
    content: "Browse";
    background-color: #e9ecef;
    border-left: 1px solid #ced4da;
    border-radius: 0 0.25rem 0.25rem 0; }

.custom-range {
  width: 100%;
  padding-left: 0;
  background-color: transparent;
  appearance: none; }
  .custom-range:focus {
    outline: none; }
    .custom-range:focus::-webkit-slider-thumb {
      box-shadow: 0 0 0 1px #FFFFFF, 0 0 0 0.2rem rgba(7, 135, 230, 0.25); }
    .custom-range:focus::-moz-range-thumb {
      box-shadow: 0 0 0 1px #FFFFFF, 0 0 0 0.2rem rgba(7, 135, 230, 0.25); }
    .custom-range:focus::-ms-thumb {
      box-shadow: 0 0 0 1px #FFFFFF, 0 0 0 0.2rem rgba(7, 135, 230, 0.25); }
  .custom-range::-moz-focus-outer {
    border: 0; }
  .custom-range::-webkit-slider-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: -0.25rem;
    background-color: #0787e6;
    border: 0;
    border-radius: 1rem;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media screen and (prefers-reduced-motion: reduce) {
      .custom-range::-webkit-slider-thumb {
        transition: none; } }
    .custom-range::-webkit-slider-thumb:active {
      background-color: #a3d6fc; }
  .custom-range::-webkit-slider-runnable-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #dee2e6;
    border-color: transparent;
    border-radius: 1rem; }
  .custom-range::-moz-range-thumb {
    width: 1rem;
    height: 1rem;
    background-color: #0787e6;
    border: 0;
    border-radius: 1rem;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media screen and (prefers-reduced-motion: reduce) {
      .custom-range::-moz-range-thumb {
        transition: none; } }
    .custom-range::-moz-range-thumb:active {
      background-color: #a3d6fc; }
  .custom-range::-moz-range-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #dee2e6;
    border-color: transparent;
    border-radius: 1rem; }
  .custom-range::-ms-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: 0;
    margin-right: 0.2rem;
    margin-left: 0.2rem;
    background-color: #0787e6;
    border: 0;
    border-radius: 1rem;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media screen and (prefers-reduced-motion: reduce) {
      .custom-range::-ms-thumb {
        transition: none; } }
    .custom-range::-ms-thumb:active {
      background-color: #a3d6fc; }
  .custom-range::-ms-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: transparent;
    border-color: transparent;
    border-width: 0.5rem; }
  .custom-range::-ms-fill-lower {
    background-color: #dee2e6;
    border-radius: 1rem; }
  .custom-range::-ms-fill-upper {
    margin-right: 15px;
    background-color: #dee2e6;
    border-radius: 1rem; }

.custom-control-label::before,
.custom-file-label,
.custom-select {
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media screen and (prefers-reduced-motion: reduce) {
    .custom-control-label::before,
    .custom-file-label,
    .custom-select {
      transition: none; } }

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }

.nav-link {
  display: block;
  padding: 0.5rem 1rem; }
  .nav-link:hover, .nav-link:focus {
    text-decoration: none; }
  .nav-link.disabled {
    color: #6c757d; }

.nav-tabs {
  border-bottom: 1px solid #dee2e6; }
  .nav-tabs .nav-item {
    margin-bottom: -1px; }
  .nav-tabs .nav-link {
    border: 1px solid transparent;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem; }
    .nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
      border-color: #e9ecef #e9ecef #dee2e6; }
    .nav-tabs .nav-link.disabled {
      color: #6c757d;
      background-color: transparent;
      border-color: transparent; }
  .nav-tabs .nav-link.active,
  .nav-tabs .nav-item.show .nav-link {
    color: #495057;
    background-color: #FFFFFF;
    border-color: #dee2e6 #dee2e6 #FFFFFF; }
  .nav-tabs .dropdown-menu {
    margin-top: -1px;
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.nav-pills .nav-link {
  border-radius: 0.25rem; }

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #FFFFFF;
  background-color: #0787e6; }

.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center; }

.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center; }

.tab-content > .tab-pane {
  display: none; }

.tab-content > .active {
  display: block; }

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1rem; }
  .navbar > .container,
  .navbar > .container-fluid {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between; }

.navbar-brand {
  display: inline-block;
  padding-top: 0.3359375rem;
  padding-bottom: 0.3359375rem;
  margin-right: 1rem;
  font-size: 1.09375rem;
  line-height: inherit;
  white-space: nowrap; }
  .navbar-brand:hover, .navbar-brand:focus {
    text-decoration: none; }

.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }
  .navbar-nav .nav-link {
    padding-right: 0;
    padding-left: 0; }
  .navbar-nav .dropdown-menu {
    position: static;
    float: none; }

.navbar-text {
  display: inline-block;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem; }

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center; }

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.09375rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.25rem; }
  .navbar-toggler:hover, .navbar-toggler:focus {
    text-decoration: none; }
  .navbar-toggler:not(:disabled):not(.disabled) {
    cursor: pointer; }

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: no-repeat center center;
  background-size: 100% 100%; }

@media (max-width: 575.98px) {
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-sm .navbar-nav {
      flex-direction: row; }
      .navbar-expand-sm .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-sm .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-sm > .container,
    .navbar-expand-sm > .container-fluid {
      flex-wrap: nowrap; }
    .navbar-expand-sm .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-sm .navbar-toggler {
      display: none; } }

@media (max-width: 767.98px) {
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 768px) {
  .navbar-expand-md {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-md .navbar-nav {
      flex-direction: row; }
      .navbar-expand-md .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-md .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-md > .container,
    .navbar-expand-md > .container-fluid {
      flex-wrap: nowrap; }
    .navbar-expand-md .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-md .navbar-toggler {
      display: none; } }

@media (max-width: 991.98px) {
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-lg .navbar-nav {
      flex-direction: row; }
      .navbar-expand-lg .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-lg .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-lg > .container,
    .navbar-expand-lg > .container-fluid {
      flex-wrap: nowrap; }
    .navbar-expand-lg .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-lg .navbar-toggler {
      display: none; } }

@media (max-width: 1199.98px) {
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-xl .navbar-nav {
      flex-direction: row; }
      .navbar-expand-xl .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-xl .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-xl > .container,
    .navbar-expand-xl > .container-fluid {
      flex-wrap: nowrap; }
    .navbar-expand-xl .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-xl .navbar-toggler {
      display: none; } }

.navbar-expand {
  flex-flow: row nowrap;
  justify-content: flex-start; }
  .navbar-expand > .container,
  .navbar-expand > .container-fluid {
    padding-right: 0;
    padding-left: 0; }
  .navbar-expand .navbar-nav {
    flex-direction: row; }
    .navbar-expand .navbar-nav .dropdown-menu {
      position: absolute; }
    .navbar-expand .navbar-nav .nav-link {
      padding-right: 0.5rem;
      padding-left: 0.5rem; }
  .navbar-expand > .container,
  .navbar-expand > .container-fluid {
    flex-wrap: nowrap; }
  .navbar-expand .navbar-collapse {
    display: flex !important;
    flex-basis: auto; }
  .navbar-expand .navbar-toggler {
    display: none; }

.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9); }
  .navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
    color: rgba(0, 0, 0, 0.9); }

.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.5); }
  .navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
    color: rgba(0, 0, 0, 0.7); }
  .navbar-light .navbar-nav .nav-link.disabled {
    color: rgba(0, 0, 0, 0.3); }

.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
  color: rgba(0, 0, 0, 0.9); }

.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.5);
  border-color: rgba(0, 0, 0, 0.1); }

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(0, 0, 0, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E"); }

.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.5); }
  .navbar-light .navbar-text a {
    color: rgba(0, 0, 0, 0.9); }
    .navbar-light .navbar-text a:hover, .navbar-light .navbar-text a:focus {
      color: rgba(0, 0, 0, 0.9); }

.navbar-dark .navbar-brand {
  color: #FFFFFF; }
  .navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
    color: #FFFFFF; }

.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.5); }
  .navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
    color: rgba(255, 255, 255, 0.75); }
  .navbar-dark .navbar-nav .nav-link.disabled {
    color: rgba(255, 255, 255, 0.25); }

.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active {
  color: #FFFFFF; }

.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.5);
  border-color: rgba(255, 255, 255, 0.1); }

.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255, 255, 255, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E"); }

.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.5); }
  .navbar-dark .navbar-text a {
    color: #FFFFFF; }
    .navbar-dark .navbar-text a:hover, .navbar-dark .navbar-text a:focus {
      color: #FFFFFF; }

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #FFFFFF;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem; }
  .card > hr {
    margin-right: 0;
    margin-left: 0; }
  .card > .list-group:first-child .list-group-item:first-child {
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem; }
  .card > .list-group:last-child .list-group-item:last-child {
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem; }

.card-body {
  flex: 1 1 auto;
  padding: 1.25rem; }

.card-title {
  margin-bottom: 0.75rem; }

.card-subtitle {
  margin-top: -0.375rem;
  margin-bottom: 0; }

.card-text:last-child {
  margin-bottom: 0; }

.card-link:hover {
  text-decoration: none; }

.card-link + .card-link {
  margin-left: 1.25rem; }

.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125); }
  .card-header:first-child {
    border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0; }
  .card-header + .list-group .list-group-item:first-child {
    border-top: 0; }

.card-footer {
  padding: 0.75rem 1.25rem;
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 1px solid rgba(0, 0, 0, 0.125); }
  .card-footer:last-child {
    border-radius: 0 0 calc(0.25rem - 1px) calc(0.25rem - 1px); }

.card-header-tabs {
  margin-right: -0.625rem;
  margin-bottom: -0.75rem;
  margin-left: -0.625rem;
  border-bottom: 0; }

.card-header-pills {
  margin-right: -0.625rem;
  margin-left: -0.625rem; }

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem; }

.card-img {
  width: 100%;
  border-radius: calc(0.25rem - 1px); }

.card-img-top {
  width: 100%;
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px); }

.card-img-bottom {
  width: 100%;
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px); }

.card-deck {
  display: flex;
  flex-direction: column; }
  .card-deck .card {
    margin-bottom: 15px; }
  @media (min-width: 576px) {
    .card-deck {
      flex-flow: row wrap;
      margin-right: -15px;
      margin-left: -15px; }
      .card-deck .card {
        display: flex;
        flex: 1 0 0%;
        flex-direction: column;
        margin-right: 15px;
        margin-bottom: 0;
        margin-left: 15px; } }

.card-group {
  display: flex;
  flex-direction: column; }
  .card-group > .card {
    margin-bottom: 15px; }
  @media (min-width: 576px) {
    .card-group {
      flex-flow: row wrap; }
      .card-group > .card {
        flex: 1 0 0%;
        margin-bottom: 0; }
        .card-group > .card + .card {
          margin-left: 0;
          border-left: 0; }
        .card-group > .card:first-child {
          border-top-right-radius: 0;
          border-bottom-right-radius: 0; }
          .card-group > .card:first-child .card-img-top,
          .card-group > .card:first-child .card-header {
            border-top-right-radius: 0; }
          .card-group > .card:first-child .card-img-bottom,
          .card-group > .card:first-child .card-footer {
            border-bottom-right-radius: 0; }
        .card-group > .card:last-child {
          border-top-left-radius: 0;
          border-bottom-left-radius: 0; }
          .card-group > .card:last-child .card-img-top,
          .card-group > .card:last-child .card-header {
            border-top-left-radius: 0; }
          .card-group > .card:last-child .card-img-bottom,
          .card-group > .card:last-child .card-footer {
            border-bottom-left-radius: 0; }
        .card-group > .card:only-child {
          border-radius: 0.25rem; }
          .card-group > .card:only-child .card-img-top,
          .card-group > .card:only-child .card-header {
            border-top-left-radius: 0.25rem;
            border-top-right-radius: 0.25rem; }
          .card-group > .card:only-child .card-img-bottom,
          .card-group > .card:only-child .card-footer {
            border-bottom-right-radius: 0.25rem;
            border-bottom-left-radius: 0.25rem; }
        .card-group > .card:not(:first-child):not(:last-child):not(:only-child) {
          border-radius: 0; }
          .card-group > .card:not(:first-child):not(:last-child):not(:only-child) .card-img-top,
          .card-group > .card:not(:first-child):not(:last-child):not(:only-child) .card-img-bottom,
          .card-group > .card:not(:first-child):not(:last-child):not(:only-child) .card-header,
          .card-group > .card:not(:first-child):not(:last-child):not(:only-child) .card-footer {
            border-radius: 0; } }

.card-columns .card {
  margin-bottom: 0.75rem; }

@media (min-width: 576px) {
  .card-columns {
    column-count: 3;
    column-gap: 1.25rem;
    orphans: 1;
    widows: 1; }
    .card-columns .card {
      display: inline-block;
      width: 100%; } }

.accordion .card:not(:first-of-type):not(:last-of-type) {
  border-bottom: 0;
  border-radius: 0; }

.accordion .card:not(:first-of-type) .card-header:first-child {
  border-radius: 0; }

.accordion .card:first-of-type {
  border-bottom: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0; }

.accordion .card:last-of-type {
  border-top-left-radius: 0;
  border-top-right-radius: 0; }

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0.75rem 1rem;
  margin-bottom: 1rem;
  list-style: none;
  background-color: #e9ecef;
  border-radius: 0.25rem; }

.breadcrumb-item + .breadcrumb-item {
  padding-left: 0.5rem; }
  .breadcrumb-item + .breadcrumb-item::before {
    display: inline-block;
    padding-right: 0.5rem;
    color: #6c757d;
    content: "/"; }

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: underline; }

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: none; }

.breadcrumb-item.active {
  color: #6c757d; }

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 0.25rem; }

.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #0787e6;
  background-color: #FFFFFF;
  border: 1px solid #dee2e6; }
  .page-link:hover {
    z-index: 2;
    color: #055b9c;
    text-decoration: none;
    background-color: #e9ecef;
    border-color: #dee2e6; }
  .page-link:focus {
    z-index: 2;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(7, 135, 230, 0.25); }
  .page-link:not(:disabled):not(.disabled) {
    cursor: pointer; }

.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem; }

.page-item:last-child .page-link {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem; }

.page-item.active .page-link {
  z-index: 1;
  color: #FFFFFF;
  background-color: #0787e6;
  border-color: #0787e6; }

.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  cursor: auto;
  background-color: #FFFFFF;
  border-color: #dee2e6; }

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.09375rem;
  line-height: 1.5; }

.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem; }

.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem; }

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.765625rem;
  line-height: 1.5; }

.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem; }

.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem; }

.badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem; }
  .badge:empty {
    display: none; }

.btn .badge, .comment-form input[type="submit"] .badge, .search-form .search-submit .badge {
  position: relative;
  top: -1px; }

.badge-pill {
  padding-right: 0.6em;
  padding-left: 0.6em;
  border-radius: 10rem; }

.badge-primary {
  color: #FFFFFF;
  background-color: #0787e6; }
  .badge-primary[href]:hover, .badge-primary[href]:focus {
    color: #FFFFFF;
    text-decoration: none;
    background-color: #056ab5; }

.badge-secondary {
  color: #FFFFFF;
  background-color: #6c757d; }
  .badge-secondary[href]:hover, .badge-secondary[href]:focus {
    color: #FFFFFF;
    text-decoration: none;
    background-color: #545b62; }

.badge-success {
  color: #FFFFFF;
  background-color: #28a745; }
  .badge-success[href]:hover, .badge-success[href]:focus {
    color: #FFFFFF;
    text-decoration: none;
    background-color: #1e7e34; }

.badge-info {
  color: #FFFFFF;
  background-color: #17a2b8; }
  .badge-info[href]:hover, .badge-info[href]:focus {
    color: #FFFFFF;
    text-decoration: none;
    background-color: #117a8b; }

.badge-warning {
  color: #212529;
  background-color: #ffc107; }
  .badge-warning[href]:hover, .badge-warning[href]:focus {
    color: #212529;
    text-decoration: none;
    background-color: #d39e00; }

.badge-danger {
  color: #FFFFFF;
  background-color: #dc3545; }
  .badge-danger[href]:hover, .badge-danger[href]:focus {
    color: #FFFFFF;
    text-decoration: none;
    background-color: #bd2130; }

.badge-light {
  color: #212529;
  background-color: #f8f9fa; }
  .badge-light[href]:hover, .badge-light[href]:focus {
    color: #212529;
    text-decoration: none;
    background-color: #dae0e5; }

.badge-dark {
  color: #FFFFFF;
  background-color: #343a40; }
  .badge-dark[href]:hover, .badge-dark[href]:focus {
    color: #FFFFFF;
    text-decoration: none;
    background-color: #1d2124; }

.jumbotron {
  padding: 2rem 1rem;
  margin-bottom: 2rem;
  background-color: #e9ecef;
  border-radius: 0.3rem; }
  @media (min-width: 576px) {
    .jumbotron {
      padding: 4rem 2rem; } }

.jumbotron-fluid {
  padding-right: 0;
  padding-left: 0;
  border-radius: 0; }

.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem; }

.alert-heading {
  color: inherit; }

.alert-link {
  font-weight: 700; }

.alert-dismissible {
  padding-right: 3.8125rem; }
  .alert-dismissible .close {
    position: absolute;
    top: 0;
    right: 0;
    padding: 0.75rem 1.25rem;
    color: inherit; }

.alert-primary {
  color: #044678;
  background-color: #cde7fa;
  border-color: #baddf8; }
  .alert-primary hr {
    border-top-color: #a3d2f6; }
  .alert-primary .alert-link {
    color: #022947; }

.alert-secondary {
  color: #383d41;
  background-color: #e2e3e5;
  border-color: #d6d8db; }
  .alert-secondary hr {
    border-top-color: #c8cbcf; }
  .alert-secondary .alert-link {
    color: #202326; }

.alert-success {
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb; }
  .alert-success hr {
    border-top-color: #b1dfbb; }
  .alert-success .alert-link {
    color: #0b2e13; }

.alert-info {
  color: #0c5460;
  background-color: #d1ecf1;
  border-color: #bee5eb; }
  .alert-info hr {
    border-top-color: #abdde5; }
  .alert-info .alert-link {
    color: #062c33; }

.alert-warning {
  color: #856404;
  background-color: #fff3cd;
  border-color: #ffeeba; }
  .alert-warning hr {
    border-top-color: #ffe8a1; }
  .alert-warning .alert-link {
    color: #533f03; }

.alert-danger {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb; }
  .alert-danger hr {
    border-top-color: #f1b0b7; }
  .alert-danger .alert-link {
    color: #491217; }

.alert-light {
  color: #818182;
  background-color: #fefefe;
  border-color: #fdfdfe; }
  .alert-light hr {
    border-top-color: #ececf6; }
  .alert-light .alert-link {
    color: #686868; }

.alert-dark {
  color: #1b1e21;
  background-color: #d6d8d9;
  border-color: #c6c8ca; }
  .alert-dark hr {
    border-top-color: #b9bbbe; }
  .alert-dark .alert-link {
    color: #040505; }

@keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0; }
  to {
    background-position: 0 0; } }

.progress {
  display: flex;
  height: 1rem;
  overflow: hidden;
  font-size: 0.65625rem;
  background-color: #e9ecef;
  border-radius: 0.25rem; }

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #FFFFFF;
  text-align: center;
  white-space: nowrap;
  background-color: #0787e6;
  transition: width 0.6s ease; }
  @media screen and (prefers-reduced-motion: reduce) {
    .progress-bar {
      transition: none; } }

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 1rem 1rem; }

.progress-bar-animated {
  animation: progress-bar-stripes 1s linear infinite; }

.media {
  display: flex;
  align-items: flex-start; }

.media-body {
  flex: 1; }

.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0; }

.list-group-item-action {
  width: 100%;
  color: #495057;
  text-align: inherit; }
  .list-group-item-action:hover, .list-group-item-action:focus {
    color: #495057;
    text-decoration: none;
    background-color: #f8f9fa; }
  .list-group-item-action:active {
    color: #212529;
    background-color: #e9ecef; }

.list-group-item {
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  margin-bottom: -1px;
  background-color: #FFFFFF;
  border: 1px solid rgba(0, 0, 0, 0.125); }
  .list-group-item:first-child {
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem; }
  .list-group-item:last-child {
    margin-bottom: 0;
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem; }
  .list-group-item:hover, .list-group-item:focus {
    z-index: 1;
    text-decoration: none; }
  .list-group-item.disabled, .list-group-item:disabled {
    color: #6c757d;
    background-color: #FFFFFF; }
  .list-group-item.active {
    z-index: 2;
    color: #FFFFFF;
    background-color: #0787e6;
    border-color: #0787e6; }

.list-group-flush .list-group-item {
  border-right: 0;
  border-left: 0;
  border-radius: 0; }

.list-group-flush:first-child .list-group-item:first-child {
  border-top: 0; }

.list-group-flush:last-child .list-group-item:last-child {
  border-bottom: 0; }

.list-group-item-primary {
  color: #044678;
  background-color: #baddf8; }
  .list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
    color: #044678;
    background-color: #a3d2f6; }
  .list-group-item-primary.list-group-item-action.active {
    color: #FFFFFF;
    background-color: #044678;
    border-color: #044678; }

.list-group-item-secondary {
  color: #383d41;
  background-color: #d6d8db; }
  .list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
    color: #383d41;
    background-color: #c8cbcf; }
  .list-group-item-secondary.list-group-item-action.active {
    color: #FFFFFF;
    background-color: #383d41;
    border-color: #383d41; }

.list-group-item-success {
  color: #155724;
  background-color: #c3e6cb; }
  .list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
    color: #155724;
    background-color: #b1dfbb; }
  .list-group-item-success.list-group-item-action.active {
    color: #FFFFFF;
    background-color: #155724;
    border-color: #155724; }

.list-group-item-info {
  color: #0c5460;
  background-color: #bee5eb; }
  .list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
    color: #0c5460;
    background-color: #abdde5; }
  .list-group-item-info.list-group-item-action.active {
    color: #FFFFFF;
    background-color: #0c5460;
    border-color: #0c5460; }

.list-group-item-warning {
  color: #856404;
  background-color: #ffeeba; }
  .list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
    color: #856404;
    background-color: #ffe8a1; }
  .list-group-item-warning.list-group-item-action.active {
    color: #FFFFFF;
    background-color: #856404;
    border-color: #856404; }

.list-group-item-danger {
  color: #721c24;
  background-color: #f5c6cb; }
  .list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
    color: #721c24;
    background-color: #f1b0b7; }
  .list-group-item-danger.list-group-item-action.active {
    color: #FFFFFF;
    background-color: #721c24;
    border-color: #721c24; }

.list-group-item-light {
  color: #818182;
  background-color: #fdfdfe; }
  .list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
    color: #818182;
    background-color: #ececf6; }
  .list-group-item-light.list-group-item-action.active {
    color: #FFFFFF;
    background-color: #818182;
    border-color: #818182; }

.list-group-item-dark {
  color: #1b1e21;
  background-color: #c6c8ca; }
  .list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
    color: #1b1e21;
    background-color: #b9bbbe; }
  .list-group-item-dark.list-group-item-action.active {
    color: #FFFFFF;
    background-color: #1b1e21;
    border-color: #1b1e21; }

.close {
  float: right;
  font-size: 1.3125rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #FFFFFF;
  opacity: .5; }
  .close:not(:disabled):not(.disabled) {
    cursor: pointer; }
    .close:not(:disabled):not(.disabled):hover, .close:not(:disabled):not(.disabled):focus {
      color: #000;
      text-decoration: none;
      opacity: .75; }

button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
  -webkit-appearance: none; }

.modal-open {
  overflow: hidden; }
  .modal-open .modal {
    overflow-x: hidden;
    overflow-y: auto; }

.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1050;
  display: none;
  overflow: hidden;
  outline: 0; }

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none; }
  .modal.fade .modal-dialog {
    transition: transform 0.3s ease-out;
    transform: translate(0, -25%); }
    @media screen and (prefers-reduced-motion: reduce) {
      .modal.fade .modal-dialog {
        transition: none; } }
  .modal.show .modal-dialog {
    transform: translate(0, 0); }

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - (0.5rem * 2)); }
  .modal-dialog-centered::before {
    display: block;
    height: calc(100vh - (0.5rem * 2));
    content: ""; }

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #FFFFFF;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0; }

.modal-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1040;
  background-color: #000; }
  .modal-backdrop.fade {
    opacity: 0; }
  .modal-backdrop.show {
    opacity: 0.5; }

.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem;
  border-bottom: 1px solid #e9ecef;
  border-top-left-radius: 0.3rem;
  border-top-right-radius: 0.3rem; }
  .modal-header .close {
    padding: 1rem;
    margin: -1rem -1rem -1rem auto; }

.modal-title {
  margin-bottom: 0;
  line-height: 1.5; }

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem; }

.modal-footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 1rem;
  border-top: 1px solid #e9ecef; }
  .modal-footer > :not(:first-child) {
    margin-left: .25rem; }
  .modal-footer > :not(:last-child) {
    margin-right: .25rem; }

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll; }

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto; }
  .modal-dialog-centered {
    min-height: calc(100% - (1.75rem * 2)); }
    .modal-dialog-centered::before {
      height: calc(100vh - (1.75rem * 2)); }
  .modal-sm {
    max-width: 300px; } }

@media (min-width: 992px) {
  .modal-lg {
    max-width: 800px; } }

.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.765625rem;
  word-wrap: break-word;
  opacity: 0; }
  .tooltip.show {
    opacity: 0.9; }
  .tooltip .arrow, .tooltip body.home .about .wysiwyg a, body.home .about .wysiwyg .tooltip a, .tooltip body.home .about .wysiwyg-blue-box a, body.home .about .wysiwyg-blue-box .tooltip a {
    position: absolute;
    display: block;
    width: 0.8rem;
    height: 0.4rem; }
    .tooltip .arrow::before, .tooltip body.home .about .wysiwyg a::before, body.home .about .wysiwyg .tooltip a::before, .tooltip body.home .about .wysiwyg-blue-box a::before, body.home .about .wysiwyg-blue-box .tooltip a::before {
      position: absolute;
      content: "";
      border-color: transparent;
      border-style: solid; }

.bs-tooltip-top, .bs-tooltip-auto[x-placement^="top"] {
  padding: 0.4rem 0; }
  .bs-tooltip-top .arrow, .bs-tooltip-auto[x-placement^="top"] .arrow, .bs-tooltip-top body.home .about .wysiwyg a, body.home .about .wysiwyg .bs-tooltip-top a, .bs-tooltip-auto[x-placement^="top"] body.home .about .wysiwyg a, body.home .about .wysiwyg .bs-tooltip-auto[x-placement^="top"] a, .bs-tooltip-top body.home .about .wysiwyg-blue-box a, body.home .about .wysiwyg-blue-box .bs-tooltip-top a, .bs-tooltip-auto[x-placement^="top"] body.home .about .wysiwyg-blue-box a, body.home .about .wysiwyg-blue-box .bs-tooltip-auto[x-placement^="top"] a {
    bottom: 0; }
    .bs-tooltip-top .arrow::before, .bs-tooltip-auto[x-placement^="top"] .arrow::before, .bs-tooltip-top body.home .about .wysiwyg a::before, body.home .about .wysiwyg .bs-tooltip-top a::before, .bs-tooltip-auto[x-placement^="top"] body.home .about .wysiwyg a::before, body.home .about .wysiwyg .bs-tooltip-auto[x-placement^="top"] a::before, .bs-tooltip-top body.home .about .wysiwyg-blue-box a::before, body.home .about .wysiwyg-blue-box .bs-tooltip-top a::before, .bs-tooltip-auto[x-placement^="top"] body.home .about .wysiwyg-blue-box a::before, body.home .about .wysiwyg-blue-box .bs-tooltip-auto[x-placement^="top"] a::before {
      top: 0;
      border-width: 0.4rem 0.4rem 0;
      border-top-color: #000; }

.bs-tooltip-right, .bs-tooltip-auto[x-placement^="right"] {
  padding: 0 0.4rem; }
  .bs-tooltip-right .arrow, .bs-tooltip-auto[x-placement^="right"] .arrow, .bs-tooltip-right body.home .about .wysiwyg a, body.home .about .wysiwyg .bs-tooltip-right a, .bs-tooltip-auto[x-placement^="right"] body.home .about .wysiwyg a, body.home .about .wysiwyg .bs-tooltip-auto[x-placement^="right"] a, .bs-tooltip-right body.home .about .wysiwyg-blue-box a, body.home .about .wysiwyg-blue-box .bs-tooltip-right a, .bs-tooltip-auto[x-placement^="right"] body.home .about .wysiwyg-blue-box a, body.home .about .wysiwyg-blue-box .bs-tooltip-auto[x-placement^="right"] a {
    left: 0;
    width: 0.4rem;
    height: 0.8rem; }
    .bs-tooltip-right .arrow::before, .bs-tooltip-auto[x-placement^="right"] .arrow::before, .bs-tooltip-right body.home .about .wysiwyg a::before, body.home .about .wysiwyg .bs-tooltip-right a::before, .bs-tooltip-auto[x-placement^="right"] body.home .about .wysiwyg a::before, body.home .about .wysiwyg .bs-tooltip-auto[x-placement^="right"] a::before, .bs-tooltip-right body.home .about .wysiwyg-blue-box a::before, body.home .about .wysiwyg-blue-box .bs-tooltip-right a::before, .bs-tooltip-auto[x-placement^="right"] body.home .about .wysiwyg-blue-box a::before, body.home .about .wysiwyg-blue-box .bs-tooltip-auto[x-placement^="right"] a::before {
      right: 0;
      border-width: 0.4rem 0.4rem 0.4rem 0;
      border-right-color: #000; }

.bs-tooltip-bottom, .bs-tooltip-auto[x-placement^="bottom"] {
  padding: 0.4rem 0; }
  .bs-tooltip-bottom .arrow, .bs-tooltip-auto[x-placement^="bottom"] .arrow, .bs-tooltip-bottom body.home .about .wysiwyg a, body.home .about .wysiwyg .bs-tooltip-bottom a, .bs-tooltip-auto[x-placement^="bottom"] body.home .about .wysiwyg a, body.home .about .wysiwyg .bs-tooltip-auto[x-placement^="bottom"] a, .bs-tooltip-bottom body.home .about .wysiwyg-blue-box a, body.home .about .wysiwyg-blue-box .bs-tooltip-bottom a, .bs-tooltip-auto[x-placement^="bottom"] body.home .about .wysiwyg-blue-box a, body.home .about .wysiwyg-blue-box .bs-tooltip-auto[x-placement^="bottom"] a {
    top: 0; }
    .bs-tooltip-bottom .arrow::before, .bs-tooltip-auto[x-placement^="bottom"] .arrow::before, .bs-tooltip-bottom body.home .about .wysiwyg a::before, body.home .about .wysiwyg .bs-tooltip-bottom a::before, .bs-tooltip-auto[x-placement^="bottom"] body.home .about .wysiwyg a::before, body.home .about .wysiwyg .bs-tooltip-auto[x-placement^="bottom"] a::before, .bs-tooltip-bottom body.home .about .wysiwyg-blue-box a::before, body.home .about .wysiwyg-blue-box .bs-tooltip-bottom a::before, .bs-tooltip-auto[x-placement^="bottom"] body.home .about .wysiwyg-blue-box a::before, body.home .about .wysiwyg-blue-box .bs-tooltip-auto[x-placement^="bottom"] a::before {
      bottom: 0;
      border-width: 0 0.4rem 0.4rem;
      border-bottom-color: #000; }

.bs-tooltip-left, .bs-tooltip-auto[x-placement^="left"] {
  padding: 0 0.4rem; }
  .bs-tooltip-left .arrow, .bs-tooltip-auto[x-placement^="left"] .arrow, .bs-tooltip-left body.home .about .wysiwyg a, body.home .about .wysiwyg .bs-tooltip-left a, .bs-tooltip-auto[x-placement^="left"] body.home .about .wysiwyg a, body.home .about .wysiwyg .bs-tooltip-auto[x-placement^="left"] a, .bs-tooltip-left body.home .about .wysiwyg-blue-box a, body.home .about .wysiwyg-blue-box .bs-tooltip-left a, .bs-tooltip-auto[x-placement^="left"] body.home .about .wysiwyg-blue-box a, body.home .about .wysiwyg-blue-box .bs-tooltip-auto[x-placement^="left"] a {
    right: 0;
    width: 0.4rem;
    height: 0.8rem; }
    .bs-tooltip-left .arrow::before, .bs-tooltip-auto[x-placement^="left"] .arrow::before, .bs-tooltip-left body.home .about .wysiwyg a::before, body.home .about .wysiwyg .bs-tooltip-left a::before, .bs-tooltip-auto[x-placement^="left"] body.home .about .wysiwyg a::before, body.home .about .wysiwyg .bs-tooltip-auto[x-placement^="left"] a::before, .bs-tooltip-left body.home .about .wysiwyg-blue-box a::before, body.home .about .wysiwyg-blue-box .bs-tooltip-left a::before, .bs-tooltip-auto[x-placement^="left"] body.home .about .wysiwyg-blue-box a::before, body.home .about .wysiwyg-blue-box .bs-tooltip-auto[x-placement^="left"] a::before {
      left: 0;
      border-width: 0.4rem 0 0.4rem 0.4rem;
      border-left-color: #000; }

.tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #FFFFFF;
  text-align: center;
  background-color: #000;
  border-radius: 0.25rem; }

.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: block;
  max-width: 276px;
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.765625rem;
  word-wrap: break-word;
  background-color: #FFFFFF;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem; }
  .popover .arrow, .popover body.home .about .wysiwyg a, body.home .about .wysiwyg .popover a, .popover body.home .about .wysiwyg-blue-box a, body.home .about .wysiwyg-blue-box .popover a {
    position: absolute;
    display: block;
    width: 1rem;
    height: 0.5rem;
    margin: 0 0.3rem; }
    .popover .arrow::before, .popover body.home .about .wysiwyg a::before, body.home .about .wysiwyg .popover a::before, .popover body.home .about .wysiwyg-blue-box a::before, body.home .about .wysiwyg-blue-box .popover a::before, .popover .arrow::after, .popover body.home .about .wysiwyg a::after, body.home .about .wysiwyg .popover a::after, .popover body.home .about .wysiwyg-blue-box a::after, body.home .about .wysiwyg-blue-box .popover a::after {
      position: absolute;
      display: block;
      content: "";
      border-color: transparent;
      border-style: solid; }

.bs-popover-top, .bs-popover-auto[x-placement^="top"] {
  margin-bottom: 0.5rem; }
  .bs-popover-top .arrow, .bs-popover-auto[x-placement^="top"] .arrow, .bs-popover-top body.home .about .wysiwyg a, body.home .about .wysiwyg .bs-popover-top a, .bs-popover-auto[x-placement^="top"] body.home .about .wysiwyg a, body.home .about .wysiwyg .bs-popover-auto[x-placement^="top"] a, .bs-popover-top body.home .about .wysiwyg-blue-box a, body.home .about .wysiwyg-blue-box .bs-popover-top a, .bs-popover-auto[x-placement^="top"] body.home .about .wysiwyg-blue-box a, body.home .about .wysiwyg-blue-box .bs-popover-auto[x-placement^="top"] a {
    bottom: calc((0.5rem + 1px) * -1); }
  .bs-popover-top .arrow::before, .bs-popover-auto[x-placement^="top"] .arrow::before, .bs-popover-top body.home .about .wysiwyg a::before, body.home .about .wysiwyg .bs-popover-top a::before, .bs-popover-auto[x-placement^="top"] body.home .about .wysiwyg a::before, body.home .about .wysiwyg .bs-popover-auto[x-placement^="top"] a::before, .bs-popover-top body.home .about .wysiwyg-blue-box a::before, body.home .about .wysiwyg-blue-box .bs-popover-top a::before, .bs-popover-auto[x-placement^="top"] body.home .about .wysiwyg-blue-box a::before, body.home .about .wysiwyg-blue-box .bs-popover-auto[x-placement^="top"] a::before,
  .bs-popover-top .arrow::after, .bs-popover-auto[x-placement^="top"] .arrow::after,
  .bs-popover-top body.home .about .wysiwyg a::after, body.home .about .wysiwyg
  .bs-popover-top a::after, .bs-popover-auto[x-placement^="top"] body.home .about .wysiwyg a::after, body.home .about .wysiwyg .bs-popover-auto[x-placement^="top"] a::after,
  .bs-popover-top body.home .about .wysiwyg-blue-box a::after, body.home .about .wysiwyg-blue-box
  .bs-popover-top a::after, .bs-popover-auto[x-placement^="top"] body.home .about .wysiwyg-blue-box a::after, body.home .about .wysiwyg-blue-box .bs-popover-auto[x-placement^="top"] a::after {
    border-width: 0.5rem 0.5rem 0; }
  .bs-popover-top .arrow::before, .bs-popover-auto[x-placement^="top"] .arrow::before, .bs-popover-top body.home .about .wysiwyg a::before, body.home .about .wysiwyg .bs-popover-top a::before, .bs-popover-auto[x-placement^="top"] body.home .about .wysiwyg a::before, body.home .about .wysiwyg .bs-popover-auto[x-placement^="top"] a::before, .bs-popover-top body.home .about .wysiwyg-blue-box a::before, body.home .about .wysiwyg-blue-box .bs-popover-top a::before, .bs-popover-auto[x-placement^="top"] body.home .about .wysiwyg-blue-box a::before, body.home .about .wysiwyg-blue-box .bs-popover-auto[x-placement^="top"] a::before {
    bottom: 0;
    border-top-color: rgba(0, 0, 0, 0.25); }
  .bs-popover-top .arrow::after, .bs-popover-auto[x-placement^="top"] .arrow::after, .bs-popover-top body.home .about .wysiwyg a::after, body.home .about .wysiwyg .bs-popover-top a::after, .bs-popover-auto[x-placement^="top"] body.home .about .wysiwyg a::after, body.home .about .wysiwyg .bs-popover-auto[x-placement^="top"] a::after, .bs-popover-top body.home .about .wysiwyg-blue-box a::after, body.home .about .wysiwyg-blue-box .bs-popover-top a::after, .bs-popover-auto[x-placement^="top"] body.home .about .wysiwyg-blue-box a::after, body.home .about .wysiwyg-blue-box .bs-popover-auto[x-placement^="top"] a::after {
    bottom: 1px;
    border-top-color: #FFFFFF; }

.bs-popover-right, .bs-popover-auto[x-placement^="right"] {
  margin-left: 0.5rem; }
  .bs-popover-right .arrow, .bs-popover-auto[x-placement^="right"] .arrow, .bs-popover-right body.home .about .wysiwyg a, body.home .about .wysiwyg .bs-popover-right a, .bs-popover-auto[x-placement^="right"] body.home .about .wysiwyg a, body.home .about .wysiwyg .bs-popover-auto[x-placement^="right"] a, .bs-popover-right body.home .about .wysiwyg-blue-box a, body.home .about .wysiwyg-blue-box .bs-popover-right a, .bs-popover-auto[x-placement^="right"] body.home .about .wysiwyg-blue-box a, body.home .about .wysiwyg-blue-box .bs-popover-auto[x-placement^="right"] a {
    left: calc((0.5rem + 1px) * -1);
    width: 0.5rem;
    height: 1rem;
    margin: 0.3rem 0; }
  .bs-popover-right .arrow::before, .bs-popover-auto[x-placement^="right"] .arrow::before, .bs-popover-right body.home .about .wysiwyg a::before, body.home .about .wysiwyg .bs-popover-right a::before, .bs-popover-auto[x-placement^="right"] body.home .about .wysiwyg a::before, body.home .about .wysiwyg .bs-popover-auto[x-placement^="right"] a::before, .bs-popover-right body.home .about .wysiwyg-blue-box a::before, body.home .about .wysiwyg-blue-box .bs-popover-right a::before, .bs-popover-auto[x-placement^="right"] body.home .about .wysiwyg-blue-box a::before, body.home .about .wysiwyg-blue-box .bs-popover-auto[x-placement^="right"] a::before,
  .bs-popover-right .arrow::after, .bs-popover-auto[x-placement^="right"] .arrow::after,
  .bs-popover-right body.home .about .wysiwyg a::after, body.home .about .wysiwyg
  .bs-popover-right a::after, .bs-popover-auto[x-placement^="right"] body.home .about .wysiwyg a::after, body.home .about .wysiwyg .bs-popover-auto[x-placement^="right"] a::after,
  .bs-popover-right body.home .about .wysiwyg-blue-box a::after, body.home .about .wysiwyg-blue-box
  .bs-popover-right a::after, .bs-popover-auto[x-placement^="right"] body.home .about .wysiwyg-blue-box a::after, body.home .about .wysiwyg-blue-box .bs-popover-auto[x-placement^="right"] a::after {
    border-width: 0.5rem 0.5rem 0.5rem 0; }
  .bs-popover-right .arrow::before, .bs-popover-auto[x-placement^="right"] .arrow::before, .bs-popover-right body.home .about .wysiwyg a::before, body.home .about .wysiwyg .bs-popover-right a::before, .bs-popover-auto[x-placement^="right"] body.home .about .wysiwyg a::before, body.home .about .wysiwyg .bs-popover-auto[x-placement^="right"] a::before, .bs-popover-right body.home .about .wysiwyg-blue-box a::before, body.home .about .wysiwyg-blue-box .bs-popover-right a::before, .bs-popover-auto[x-placement^="right"] body.home .about .wysiwyg-blue-box a::before, body.home .about .wysiwyg-blue-box .bs-popover-auto[x-placement^="right"] a::before {
    left: 0;
    border-right-color: rgba(0, 0, 0, 0.25); }
  .bs-popover-right .arrow::after, .bs-popover-auto[x-placement^="right"] .arrow::after, .bs-popover-right body.home .about .wysiwyg a::after, body.home .about .wysiwyg .bs-popover-right a::after, .bs-popover-auto[x-placement^="right"] body.home .about .wysiwyg a::after, body.home .about .wysiwyg .bs-popover-auto[x-placement^="right"] a::after, .bs-popover-right body.home .about .wysiwyg-blue-box a::after, body.home .about .wysiwyg-blue-box .bs-popover-right a::after, .bs-popover-auto[x-placement^="right"] body.home .about .wysiwyg-blue-box a::after, body.home .about .wysiwyg-blue-box .bs-popover-auto[x-placement^="right"] a::after {
    left: 1px;
    border-right-color: #FFFFFF; }

.bs-popover-bottom, .bs-popover-auto[x-placement^="bottom"] {
  margin-top: 0.5rem; }
  .bs-popover-bottom .arrow, .bs-popover-auto[x-placement^="bottom"] .arrow, .bs-popover-bottom body.home .about .wysiwyg a, body.home .about .wysiwyg .bs-popover-bottom a, .bs-popover-auto[x-placement^="bottom"] body.home .about .wysiwyg a, body.home .about .wysiwyg .bs-popover-auto[x-placement^="bottom"] a, .bs-popover-bottom body.home .about .wysiwyg-blue-box a, body.home .about .wysiwyg-blue-box .bs-popover-bottom a, .bs-popover-auto[x-placement^="bottom"] body.home .about .wysiwyg-blue-box a, body.home .about .wysiwyg-blue-box .bs-popover-auto[x-placement^="bottom"] a {
    top: calc((0.5rem + 1px) * -1); }
  .bs-popover-bottom .arrow::before, .bs-popover-auto[x-placement^="bottom"] .arrow::before, .bs-popover-bottom body.home .about .wysiwyg a::before, body.home .about .wysiwyg .bs-popover-bottom a::before, .bs-popover-auto[x-placement^="bottom"] body.home .about .wysiwyg a::before, body.home .about .wysiwyg .bs-popover-auto[x-placement^="bottom"] a::before, .bs-popover-bottom body.home .about .wysiwyg-blue-box a::before, body.home .about .wysiwyg-blue-box .bs-popover-bottom a::before, .bs-popover-auto[x-placement^="bottom"] body.home .about .wysiwyg-blue-box a::before, body.home .about .wysiwyg-blue-box .bs-popover-auto[x-placement^="bottom"] a::before,
  .bs-popover-bottom .arrow::after, .bs-popover-auto[x-placement^="bottom"] .arrow::after,
  .bs-popover-bottom body.home .about .wysiwyg a::after, body.home .about .wysiwyg
  .bs-popover-bottom a::after, .bs-popover-auto[x-placement^="bottom"] body.home .about .wysiwyg a::after, body.home .about .wysiwyg .bs-popover-auto[x-placement^="bottom"] a::after,
  .bs-popover-bottom body.home .about .wysiwyg-blue-box a::after, body.home .about .wysiwyg-blue-box
  .bs-popover-bottom a::after, .bs-popover-auto[x-placement^="bottom"] body.home .about .wysiwyg-blue-box a::after, body.home .about .wysiwyg-blue-box .bs-popover-auto[x-placement^="bottom"] a::after {
    border-width: 0 0.5rem 0.5rem 0.5rem; }
  .bs-popover-bottom .arrow::before, .bs-popover-auto[x-placement^="bottom"] .arrow::before, .bs-popover-bottom body.home .about .wysiwyg a::before, body.home .about .wysiwyg .bs-popover-bottom a::before, .bs-popover-auto[x-placement^="bottom"] body.home .about .wysiwyg a::before, body.home .about .wysiwyg .bs-popover-auto[x-placement^="bottom"] a::before, .bs-popover-bottom body.home .about .wysiwyg-blue-box a::before, body.home .about .wysiwyg-blue-box .bs-popover-bottom a::before, .bs-popover-auto[x-placement^="bottom"] body.home .about .wysiwyg-blue-box a::before, body.home .about .wysiwyg-blue-box .bs-popover-auto[x-placement^="bottom"] a::before {
    top: 0;
    border-bottom-color: rgba(0, 0, 0, 0.25); }
  .bs-popover-bottom .arrow::after, .bs-popover-auto[x-placement^="bottom"] .arrow::after, .bs-popover-bottom body.home .about .wysiwyg a::after, body.home .about .wysiwyg .bs-popover-bottom a::after, .bs-popover-auto[x-placement^="bottom"] body.home .about .wysiwyg a::after, body.home .about .wysiwyg .bs-popover-auto[x-placement^="bottom"] a::after, .bs-popover-bottom body.home .about .wysiwyg-blue-box a::after, body.home .about .wysiwyg-blue-box .bs-popover-bottom a::after, .bs-popover-auto[x-placement^="bottom"] body.home .about .wysiwyg-blue-box a::after, body.home .about .wysiwyg-blue-box .bs-popover-auto[x-placement^="bottom"] a::after {
    top: 1px;
    border-bottom-color: #FFFFFF; }
  .bs-popover-bottom .popover-header::before, .bs-popover-auto[x-placement^="bottom"] .popover-header::before {
    position: absolute;
    top: 0;
    left: 50%;
    display: block;
    width: 1rem;
    margin-left: -0.5rem;
    content: "";
    border-bottom: 1px solid #f7f7f7; }

.bs-popover-left, .bs-popover-auto[x-placement^="left"] {
  margin-right: 0.5rem; }
  .bs-popover-left .arrow, .bs-popover-auto[x-placement^="left"] .arrow, .bs-popover-left body.home .about .wysiwyg a, body.home .about .wysiwyg .bs-popover-left a, .bs-popover-auto[x-placement^="left"] body.home .about .wysiwyg a, body.home .about .wysiwyg .bs-popover-auto[x-placement^="left"] a, .bs-popover-left body.home .about .wysiwyg-blue-box a, body.home .about .wysiwyg-blue-box .bs-popover-left a, .bs-popover-auto[x-placement^="left"] body.home .about .wysiwyg-blue-box a, body.home .about .wysiwyg-blue-box .bs-popover-auto[x-placement^="left"] a {
    right: calc((0.5rem + 1px) * -1);
    width: 0.5rem;
    height: 1rem;
    margin: 0.3rem 0; }
  .bs-popover-left .arrow::before, .bs-popover-auto[x-placement^="left"] .arrow::before, .bs-popover-left body.home .about .wysiwyg a::before, body.home .about .wysiwyg .bs-popover-left a::before, .bs-popover-auto[x-placement^="left"] body.home .about .wysiwyg a::before, body.home .about .wysiwyg .bs-popover-auto[x-placement^="left"] a::before, .bs-popover-left body.home .about .wysiwyg-blue-box a::before, body.home .about .wysiwyg-blue-box .bs-popover-left a::before, .bs-popover-auto[x-placement^="left"] body.home .about .wysiwyg-blue-box a::before, body.home .about .wysiwyg-blue-box .bs-popover-auto[x-placement^="left"] a::before,
  .bs-popover-left .arrow::after, .bs-popover-auto[x-placement^="left"] .arrow::after,
  .bs-popover-left body.home .about .wysiwyg a::after, body.home .about .wysiwyg
  .bs-popover-left a::after, .bs-popover-auto[x-placement^="left"] body.home .about .wysiwyg a::after, body.home .about .wysiwyg .bs-popover-auto[x-placement^="left"] a::after,
  .bs-popover-left body.home .about .wysiwyg-blue-box a::after, body.home .about .wysiwyg-blue-box
  .bs-popover-left a::after, .bs-popover-auto[x-placement^="left"] body.home .about .wysiwyg-blue-box a::after, body.home .about .wysiwyg-blue-box .bs-popover-auto[x-placement^="left"] a::after {
    border-width: 0.5rem 0 0.5rem 0.5rem; }
  .bs-popover-left .arrow::before, .bs-popover-auto[x-placement^="left"] .arrow::before, .bs-popover-left body.home .about .wysiwyg a::before, body.home .about .wysiwyg .bs-popover-left a::before, .bs-popover-auto[x-placement^="left"] body.home .about .wysiwyg a::before, body.home .about .wysiwyg .bs-popover-auto[x-placement^="left"] a::before, .bs-popover-left body.home .about .wysiwyg-blue-box a::before, body.home .about .wysiwyg-blue-box .bs-popover-left a::before, .bs-popover-auto[x-placement^="left"] body.home .about .wysiwyg-blue-box a::before, body.home .about .wysiwyg-blue-box .bs-popover-auto[x-placement^="left"] a::before {
    right: 0;
    border-left-color: rgba(0, 0, 0, 0.25); }
  .bs-popover-left .arrow::after, .bs-popover-auto[x-placement^="left"] .arrow::after, .bs-popover-left body.home .about .wysiwyg a::after, body.home .about .wysiwyg .bs-popover-left a::after, .bs-popover-auto[x-placement^="left"] body.home .about .wysiwyg a::after, body.home .about .wysiwyg .bs-popover-auto[x-placement^="left"] a::after, .bs-popover-left body.home .about .wysiwyg-blue-box a::after, body.home .about .wysiwyg-blue-box .bs-popover-left a::after, .bs-popover-auto[x-placement^="left"] body.home .about .wysiwyg-blue-box a::after, body.home .about .wysiwyg-blue-box .bs-popover-auto[x-placement^="left"] a::after {
    right: 1px;
    border-left-color: #FFFFFF; }

.popover-header {
  padding: 0.5rem 0.75rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: inherit;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px); }
  .popover-header:empty {
    display: none; }

.popover-body {
  padding: 0.5rem 0.75rem;
  color: #212529; }

.carousel {
  position: relative; }

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden; }

.carousel-item {
  position: relative;
  display: none;
  align-items: center;
  width: 100%;
  backface-visibility: hidden;
  perspective: 1000px; }

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block;
  transition: transform 0.6s ease; }
  @media screen and (prefers-reduced-motion: reduce) {
    .carousel-item.active,
    .carousel-item-next,
    .carousel-item-prev {
      transition: none; } }

.carousel-item-next,
.carousel-item-prev {
  position: absolute;
  top: 0; }

.carousel-item-next.carousel-item-left,
.carousel-item-prev.carousel-item-right {
  transform: translateX(0); }
  @supports (transform-style: preserve-3d) {
    .carousel-item-next.carousel-item-left,
    .carousel-item-prev.carousel-item-right {
      transform: translate3d(0, 0, 0); } }

.carousel-item-next,
.active.carousel-item-right {
  transform: translateX(100%); }
  @supports (transform-style: preserve-3d) {
    .carousel-item-next,
    .active.carousel-item-right {
      transform: translate3d(100%, 0, 0); } }

.carousel-item-prev,
.active.carousel-item-left {
  transform: translateX(-100%); }
  @supports (transform-style: preserve-3d) {
    .carousel-item-prev,
    .active.carousel-item-left {
      transform: translate3d(-100%, 0, 0); } }

.carousel-fade .carousel-item {
  opacity: 0;
  transition-duration: .6s;
  transition-property: opacity; }

.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-left,
.carousel-fade .carousel-item-prev.carousel-item-right {
  opacity: 1; }

.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
  opacity: 0; }

.carousel-fade .carousel-item-next,
.carousel-fade .carousel-item-prev,
.carousel-fade .carousel-item.active,
.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-prev {
  transform: translateX(0); }
  @supports (transform-style: preserve-3d) {
    .carousel-fade .carousel-item-next,
    .carousel-fade .carousel-item-prev,
    .carousel-fade .carousel-item.active,
    .carousel-fade .active.carousel-item-left,
    .carousel-fade .active.carousel-item-prev {
      transform: translate3d(0, 0, 0); } }

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  color: #FFFFFF;
  text-align: center;
  opacity: 0.5; }
  .carousel-control-prev:hover, .carousel-control-prev:focus,
  .carousel-control-next:hover,
  .carousel-control-next:focus {
    color: #FFFFFF;
    text-decoration: none;
    outline: 0;
    opacity: .9; }

.carousel-control-prev {
  left: 0; }

.carousel-control-next {
  right: 0; }

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: transparent no-repeat center center;
  background-size: 100% 100%; }

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23FFFFFF' viewBox='0 0 8 8'%3E%3Cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3E%3C/svg%3E"); }

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23FFFFFF' viewBox='0 0 8 8'%3E%3Cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3E%3C/svg%3E"); }

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 10px;
  left: 0;
  z-index: 15;
  display: flex;
  justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none; }
  .carousel-indicators li {
    position: relative;
    flex: 0 1 auto;
    width: 30px;
    height: 3px;
    margin-right: 3px;
    margin-left: 3px;
    text-indent: -999px;
    cursor: pointer;
    background-color: rgba(255, 255, 255, 0.5); }
    .carousel-indicators li::before {
      position: absolute;
      top: -10px;
      left: 0;
      display: inline-block;
      width: 100%;
      height: 10px;
      content: ""; }
    .carousel-indicators li::after {
      position: absolute;
      bottom: -10px;
      left: 0;
      display: inline-block;
      width: 100%;
      height: 10px;
      content: ""; }
  .carousel-indicators .active {
    background-color: #FFFFFF; }

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 20px;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #FFFFFF;
  text-align: center; }

.align-baseline {
  vertical-align: baseline !important; }

.align-top {
  vertical-align: top !important; }

.align-middle {
  vertical-align: middle !important; }

.align-bottom {
  vertical-align: bottom !important; }

.align-text-bottom {
  vertical-align: text-bottom !important; }

.align-text-top {
  vertical-align: text-top !important; }

.bg-primary {
  background-color: #0787e6 !important; }

a.bg-primary:hover, a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #056ab5 !important; }

.bg-secondary {
  background-color: #6c757d !important; }

a.bg-secondary:hover, a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #545b62 !important; }

.bg-success {
  background-color: #28a745 !important; }

a.bg-success:hover, a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #1e7e34 !important; }

.bg-info {
  background-color: #17a2b8 !important; }

a.bg-info:hover, a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #117a8b !important; }

.bg-warning {
  background-color: #ffc107 !important; }

a.bg-warning:hover, a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #d39e00 !important; }

.bg-danger {
  background-color: #dc3545 !important; }

a.bg-danger:hover, a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #bd2130 !important; }

.bg-light {
  background-color: #f8f9fa !important; }

a.bg-light:hover, a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #dae0e5 !important; }

.bg-dark {
  background-color: #343a40 !important; }

a.bg-dark:hover, a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: #1d2124 !important; }

.bg-white {
  background-color: #FFFFFF !important; }

.bg-transparent {
  background-color: transparent !important; }

.border {
  border: 1px solid #dee2e6 !important; }

.border-top {
  border-top: 1px solid #dee2e6 !important; }

.border-right {
  border-right: 1px solid #dee2e6 !important; }

.border-bottom {
  border-bottom: 1px solid #dee2e6 !important; }

.border-left {
  border-left: 1px solid #dee2e6 !important; }

.border-0 {
  border: 0 !important; }

.border-top-0 {
  border-top: 0 !important; }

.border-right-0 {
  border-right: 0 !important; }

.border-bottom-0 {
  border-bottom: 0 !important; }

.border-left-0 {
  border-left: 0 !important; }

.border-primary {
  border-color: #0787e6 !important; }

.border-secondary {
  border-color: #6c757d !important; }

.border-success {
  border-color: #28a745 !important; }

.border-info {
  border-color: #17a2b8 !important; }

.border-warning {
  border-color: #ffc107 !important; }

.border-danger {
  border-color: #dc3545 !important; }

.border-light {
  border-color: #f8f9fa !important; }

.border-dark {
  border-color: #343a40 !important; }

.border-white {
  border-color: #FFFFFF !important; }

.rounded {
  border-radius: 0.25rem !important; }

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important; }

.rounded-right {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important; }

.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

.rounded-left {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

.rounded-circle {
  border-radius: 50% !important; }

.rounded-0 {
  border-radius: 0 !important; }

.clearfix::after {
  display: block;
  clear: both;
  content: ""; }

.d-none {
  display: none !important; }

.d-inline {
  display: inline !important; }

.d-inline-block {
  display: inline-block !important; }

.d-block {
  display: block !important; }

.d-table {
  display: table !important; }

.d-table-row {
  display: table-row !important; }

.d-table-cell {
  display: table-cell !important; }

.d-flex {
  display: flex !important; }

.d-inline-flex {
  display: inline-flex !important; }

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important; }
  .d-sm-inline {
    display: inline !important; }
  .d-sm-inline-block {
    display: inline-block !important; }
  .d-sm-block {
    display: block !important; }
  .d-sm-table {
    display: table !important; }
  .d-sm-table-row {
    display: table-row !important; }
  .d-sm-table-cell {
    display: table-cell !important; }
  .d-sm-flex {
    display: flex !important; }
  .d-sm-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 768px) {
  .d-md-none {
    display: none !important; }
  .d-md-inline {
    display: inline !important; }
  .d-md-inline-block {
    display: inline-block !important; }
  .d-md-block {
    display: block !important; }
  .d-md-table {
    display: table !important; }
  .d-md-table-row {
    display: table-row !important; }
  .d-md-table-cell {
    display: table-cell !important; }
  .d-md-flex {
    display: flex !important; }
  .d-md-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 992px) {
  .d-lg-none {
    display: none !important; }
  .d-lg-inline {
    display: inline !important; }
  .d-lg-inline-block {
    display: inline-block !important; }
  .d-lg-block {
    display: block !important; }
  .d-lg-table {
    display: table !important; }
  .d-lg-table-row {
    display: table-row !important; }
  .d-lg-table-cell {
    display: table-cell !important; }
  .d-lg-flex {
    display: flex !important; }
  .d-lg-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important; }
  .d-xl-inline {
    display: inline !important; }
  .d-xl-inline-block {
    display: inline-block !important; }
  .d-xl-block {
    display: block !important; }
  .d-xl-table {
    display: table !important; }
  .d-xl-table-row {
    display: table-row !important; }
  .d-xl-table-cell {
    display: table-cell !important; }
  .d-xl-flex {
    display: flex !important; }
  .d-xl-inline-flex {
    display: inline-flex !important; } }

@media print {
  .d-print-none {
    display: none !important; }
  .d-print-inline {
    display: inline !important; }
  .d-print-inline-block {
    display: inline-block !important; }
  .d-print-block {
    display: block !important; }
  .d-print-table {
    display: table !important; }
  .d-print-table-row {
    display: table-row !important; }
  .d-print-table-cell {
    display: table-cell !important; }
  .d-print-flex {
    display: flex !important; }
  .d-print-inline-flex {
    display: inline-flex !important; } }

.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden; }
  .embed-responsive::before {
    display: block;
    content: ""; }
  .embed-responsive .embed-responsive-item,
  .embed-responsive iframe,
  .embed-responsive embed,
  .embed-responsive object,
  .embed-responsive video {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0; }

.embed-responsive-21by9::before {
  padding-top: 42.8571428571%; }

.embed-responsive-16by9::before {
  padding-top: 56.25%; }

.embed-responsive-4by3::before {
  padding-top: 75%; }

.embed-responsive-1by1::before {
  padding-top: 100%; }

.flex-row {
  flex-direction: row !important; }

.flex-column {
  flex-direction: column !important; }

.flex-row-reverse {
  flex-direction: row-reverse !important; }

.flex-column-reverse {
  flex-direction: column-reverse !important; }

.flex-wrap {
  flex-wrap: wrap !important; }

.flex-nowrap {
  flex-wrap: nowrap !important; }

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important; }

.flex-fill {
  flex: 1 1 auto !important; }

.flex-grow-0 {
  flex-grow: 0 !important; }

.flex-grow-1 {
  flex-grow: 1 !important; }

.flex-shrink-0 {
  flex-shrink: 0 !important; }

.flex-shrink-1 {
  flex-shrink: 1 !important; }

.justify-content-start {
  justify-content: flex-start !important; }

.justify-content-end {
  justify-content: flex-end !important; }

.justify-content-center {
  justify-content: center !important; }

.justify-content-between {
  justify-content: space-between !important; }

.justify-content-around {
  justify-content: space-around !important; }

.align-items-start {
  align-items: flex-start !important; }

.align-items-end {
  align-items: flex-end !important; }

.align-items-center {
  align-items: center !important; }

.align-items-baseline {
  align-items: baseline !important; }

.align-items-stretch {
  align-items: stretch !important; }

.align-content-start {
  align-content: flex-start !important; }

.align-content-end {
  align-content: flex-end !important; }

.align-content-center {
  align-content: center !important; }

.align-content-between {
  align-content: space-between !important; }

.align-content-around {
  align-content: space-around !important; }

.align-content-stretch {
  align-content: stretch !important; }

.align-self-auto {
  align-self: auto !important; }

.align-self-start {
  align-self: flex-start !important; }

.align-self-end {
  align-self: flex-end !important; }

.align-self-center {
  align-self: center !important; }

.align-self-baseline {
  align-self: baseline !important; }

.align-self-stretch {
  align-self: stretch !important; }

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important; }
  .flex-sm-column {
    flex-direction: column !important; }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-sm-wrap {
    flex-wrap: wrap !important; }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important; }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-sm-fill {
    flex: 1 1 auto !important; }
  .flex-sm-grow-0 {
    flex-grow: 0 !important; }
  .flex-sm-grow-1 {
    flex-grow: 1 !important; }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-sm-start {
    justify-content: flex-start !important; }
  .justify-content-sm-end {
    justify-content: flex-end !important; }
  .justify-content-sm-center {
    justify-content: center !important; }
  .justify-content-sm-between {
    justify-content: space-between !important; }
  .justify-content-sm-around {
    justify-content: space-around !important; }
  .align-items-sm-start {
    align-items: flex-start !important; }
  .align-items-sm-end {
    align-items: flex-end !important; }
  .align-items-sm-center {
    align-items: center !important; }
  .align-items-sm-baseline {
    align-items: baseline !important; }
  .align-items-sm-stretch {
    align-items: stretch !important; }
  .align-content-sm-start {
    align-content: flex-start !important; }
  .align-content-sm-end {
    align-content: flex-end !important; }
  .align-content-sm-center {
    align-content: center !important; }
  .align-content-sm-between {
    align-content: space-between !important; }
  .align-content-sm-around {
    align-content: space-around !important; }
  .align-content-sm-stretch {
    align-content: stretch !important; }
  .align-self-sm-auto {
    align-self: auto !important; }
  .align-self-sm-start {
    align-self: flex-start !important; }
  .align-self-sm-end {
    align-self: flex-end !important; }
  .align-self-sm-center {
    align-self: center !important; }
  .align-self-sm-baseline {
    align-self: baseline !important; }
  .align-self-sm-stretch {
    align-self: stretch !important; } }

@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important; }
  .flex-md-column {
    flex-direction: column !important; }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-md-wrap {
    flex-wrap: wrap !important; }
  .flex-md-nowrap {
    flex-wrap: nowrap !important; }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-md-fill {
    flex: 1 1 auto !important; }
  .flex-md-grow-0 {
    flex-grow: 0 !important; }
  .flex-md-grow-1 {
    flex-grow: 1 !important; }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-md-start {
    justify-content: flex-start !important; }
  .justify-content-md-end {
    justify-content: flex-end !important; }
  .justify-content-md-center {
    justify-content: center !important; }
  .justify-content-md-between {
    justify-content: space-between !important; }
  .justify-content-md-around {
    justify-content: space-around !important; }
  .align-items-md-start {
    align-items: flex-start !important; }
  .align-items-md-end {
    align-items: flex-end !important; }
  .align-items-md-center {
    align-items: center !important; }
  .align-items-md-baseline {
    align-items: baseline !important; }
  .align-items-md-stretch {
    align-items: stretch !important; }
  .align-content-md-start {
    align-content: flex-start !important; }
  .align-content-md-end {
    align-content: flex-end !important; }
  .align-content-md-center {
    align-content: center !important; }
  .align-content-md-between {
    align-content: space-between !important; }
  .align-content-md-around {
    align-content: space-around !important; }
  .align-content-md-stretch {
    align-content: stretch !important; }
  .align-self-md-auto {
    align-self: auto !important; }
  .align-self-md-start {
    align-self: flex-start !important; }
  .align-self-md-end {
    align-self: flex-end !important; }
  .align-self-md-center {
    align-self: center !important; }
  .align-self-md-baseline {
    align-self: baseline !important; }
  .align-self-md-stretch {
    align-self: stretch !important; } }

@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important; }
  .flex-lg-column {
    flex-direction: column !important; }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-lg-wrap {
    flex-wrap: wrap !important; }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important; }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-lg-fill {
    flex: 1 1 auto !important; }
  .flex-lg-grow-0 {
    flex-grow: 0 !important; }
  .flex-lg-grow-1 {
    flex-grow: 1 !important; }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-lg-start {
    justify-content: flex-start !important; }
  .justify-content-lg-end {
    justify-content: flex-end !important; }
  .justify-content-lg-center {
    justify-content: center !important; }
  .justify-content-lg-between {
    justify-content: space-between !important; }
  .justify-content-lg-around {
    justify-content: space-around !important; }
  .align-items-lg-start {
    align-items: flex-start !important; }
  .align-items-lg-end {
    align-items: flex-end !important; }
  .align-items-lg-center {
    align-items: center !important; }
  .align-items-lg-baseline {
    align-items: baseline !important; }
  .align-items-lg-stretch {
    align-items: stretch !important; }
  .align-content-lg-start {
    align-content: flex-start !important; }
  .align-content-lg-end {
    align-content: flex-end !important; }
  .align-content-lg-center {
    align-content: center !important; }
  .align-content-lg-between {
    align-content: space-between !important; }
  .align-content-lg-around {
    align-content: space-around !important; }
  .align-content-lg-stretch {
    align-content: stretch !important; }
  .align-self-lg-auto {
    align-self: auto !important; }
  .align-self-lg-start {
    align-self: flex-start !important; }
  .align-self-lg-end {
    align-self: flex-end !important; }
  .align-self-lg-center {
    align-self: center !important; }
  .align-self-lg-baseline {
    align-self: baseline !important; }
  .align-self-lg-stretch {
    align-self: stretch !important; } }

@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important; }
  .flex-xl-column {
    flex-direction: column !important; }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xl-wrap {
    flex-wrap: wrap !important; }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-xl-fill {
    flex: 1 1 auto !important; }
  .flex-xl-grow-0 {
    flex-grow: 0 !important; }
  .flex-xl-grow-1 {
    flex-grow: 1 !important; }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-xl-start {
    justify-content: flex-start !important; }
  .justify-content-xl-end {
    justify-content: flex-end !important; }
  .justify-content-xl-center {
    justify-content: center !important; }
  .justify-content-xl-between {
    justify-content: space-between !important; }
  .justify-content-xl-around {
    justify-content: space-around !important; }
  .align-items-xl-start {
    align-items: flex-start !important; }
  .align-items-xl-end {
    align-items: flex-end !important; }
  .align-items-xl-center {
    align-items: center !important; }
  .align-items-xl-baseline {
    align-items: baseline !important; }
  .align-items-xl-stretch {
    align-items: stretch !important; }
  .align-content-xl-start {
    align-content: flex-start !important; }
  .align-content-xl-end {
    align-content: flex-end !important; }
  .align-content-xl-center {
    align-content: center !important; }
  .align-content-xl-between {
    align-content: space-between !important; }
  .align-content-xl-around {
    align-content: space-around !important; }
  .align-content-xl-stretch {
    align-content: stretch !important; }
  .align-self-xl-auto {
    align-self: auto !important; }
  .align-self-xl-start {
    align-self: flex-start !important; }
  .align-self-xl-end {
    align-self: flex-end !important; }
  .align-self-xl-center {
    align-self: center !important; }
  .align-self-xl-baseline {
    align-self: baseline !important; }
  .align-self-xl-stretch {
    align-self: stretch !important; } }

.float-left {
  float: left !important; }

.float-right {
  float: right !important; }

.float-none {
  float: none !important; }

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important; }
  .float-sm-right {
    float: right !important; }
  .float-sm-none {
    float: none !important; } }

@media (min-width: 768px) {
  .float-md-left {
    float: left !important; }
  .float-md-right {
    float: right !important; }
  .float-md-none {
    float: none !important; } }

@media (min-width: 992px) {
  .float-lg-left {
    float: left !important; }
  .float-lg-right {
    float: right !important; }
  .float-lg-none {
    float: none !important; } }

@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important; }
  .float-xl-right {
    float: right !important; }
  .float-xl-none {
    float: none !important; } }

.position-static {
  position: static !important; }

.position-relative {
  position: relative !important; }

.position-absolute {
  position: absolute !important; }

.position-fixed {
  position: fixed !important; }

.position-sticky {
  position: sticky !important; }

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030; }

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030; }

@supports (position: sticky) {
  .sticky-top {
    position: sticky;
    top: 0;
    z-index: 1020; } }

.sr-only, .screen-reader-text {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0; }

.sr-only-focusable:active, .screen-reader-text:active, .sr-only-focusable:focus, .screen-reader-text:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal; }

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important; }

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important; }

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important; }

.shadow-none {
  box-shadow: none !important; }

.w-25 {
  width: 25% !important; }

.w-50 {
  width: 50% !important; }

.w-75 {
  width: 75% !important; }

.w-100 {
  width: 100% !important; }

.w-auto {
  width: auto !important; }

.h-25 {
  height: 25% !important; }

.h-50 {
  height: 50% !important; }

.h-75 {
  height: 75% !important; }

.h-100 {
  height: 100% !important; }

.h-auto {
  height: auto !important; }

.mw-100 {
  max-width: 100% !important; }

.mh-100 {
  max-height: 100% !important; }

.m-0 {
  margin: 0 !important; }

.mt-0,
.my-0 {
  margin-top: 0 !important; }

.mr-0,
.mx-0 {
  margin-right: 0 !important; }

.mb-0,
.my-0 {
  margin-bottom: 0 !important; }

.ml-0,
.mx-0 {
  margin-left: 0 !important; }

.m-1 {
  margin: 0.25rem !important; }

.mt-1,
.my-1 {
  margin-top: 0.25rem !important; }

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important; }

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important; }

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important; }

.m-2 {
  margin: 0.5rem !important; }

.mt-2,
.my-2 {
  margin-top: 0.5rem !important; }

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important; }

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important; }

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important; }

.m-3 {
  margin: 1rem !important; }

.mt-3,
.my-3 {
  margin-top: 1rem !important; }

.mr-3,
.mx-3 {
  margin-right: 1rem !important; }

.mb-3,
.my-3 {
  margin-bottom: 1rem !important; }

.ml-3,
.mx-3 {
  margin-left: 1rem !important; }

.m-4 {
  margin: 1.5rem !important; }

.mt-4,
.my-4 {
  margin-top: 1.5rem !important; }

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important; }

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important; }

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important; }

.m-5 {
  margin: 3rem !important; }

.mt-5,
.my-5 {
  margin-top: 3rem !important; }

.mr-5,
.mx-5 {
  margin-right: 3rem !important; }

.mb-5,
.my-5 {
  margin-bottom: 3rem !important; }

.ml-5,
.mx-5 {
  margin-left: 3rem !important; }

.p-0 {
  padding: 0 !important; }

.pt-0,
.py-0 {
  padding-top: 0 !important; }

.pr-0,
.px-0 {
  padding-right: 0 !important; }

.pb-0,
.py-0 {
  padding-bottom: 0 !important; }

.pl-0,
.px-0 {
  padding-left: 0 !important; }

.p-1 {
  padding: 0.25rem !important; }

.pt-1,
.py-1 {
  padding-top: 0.25rem !important; }

.pr-1,
.px-1 {
  padding-right: 0.25rem !important; }

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important; }

.pl-1,
.px-1 {
  padding-left: 0.25rem !important; }

.p-2 {
  padding: 0.5rem !important; }

.pt-2,
.py-2 {
  padding-top: 0.5rem !important; }

.pr-2,
.px-2 {
  padding-right: 0.5rem !important; }

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important; }

.pl-2,
.px-2 {
  padding-left: 0.5rem !important; }

.p-3 {
  padding: 1rem !important; }

.pt-3,
.py-3 {
  padding-top: 1rem !important; }

.pr-3,
.px-3 {
  padding-right: 1rem !important; }

.pb-3,
.py-3 {
  padding-bottom: 1rem !important; }

.pl-3,
.px-3 {
  padding-left: 1rem !important; }

.p-4 {
  padding: 1.5rem !important; }

.pt-4,
.py-4 {
  padding-top: 1.5rem !important; }

.pr-4,
.px-4 {
  padding-right: 1.5rem !important; }

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important; }

.pl-4,
.px-4 {
  padding-left: 1.5rem !important; }

.p-5 {
  padding: 3rem !important; }

.pt-5,
.py-5 {
  padding-top: 3rem !important; }

.pr-5,
.px-5 {
  padding-right: 3rem !important; }

.pb-5,
.py-5 {
  padding-bottom: 3rem !important; }

.pl-5,
.px-5 {
  padding-left: 3rem !important; }

.m-auto {
  margin: auto !important; }

.mt-auto,
.my-auto {
  margin-top: auto !important; }

.mr-auto,
.mx-auto {
  margin-right: auto !important; }

.mb-auto,
.my-auto {
  margin-bottom: auto !important; }

.ml-auto,
.mx-auto {
  margin-left: auto !important; }

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important; }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important; }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important; }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important; }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important; }
  .m-sm-1 {
    margin: 0.25rem !important; }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.25rem !important; }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.25rem !important; }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.25rem !important; }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.25rem !important; }
  .m-sm-2 {
    margin: 0.5rem !important; }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.5rem !important; }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.5rem !important; }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.5rem !important; }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.5rem !important; }
  .m-sm-3 {
    margin: 1rem !important; }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1rem !important; }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1rem !important; }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1rem !important; }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1rem !important; }
  .m-sm-4 {
    margin: 1.5rem !important; }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.5rem !important; }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.5rem !important; }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.5rem !important; }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.5rem !important; }
  .m-sm-5 {
    margin: 3rem !important; }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 3rem !important; }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 3rem !important; }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 3rem !important; }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 3rem !important; }
  .p-sm-0 {
    padding: 0 !important; }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important; }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important; }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important; }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important; }
  .p-sm-1 {
    padding: 0.25rem !important; }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.25rem !important; }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.25rem !important; }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.25rem !important; }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.25rem !important; }
  .p-sm-2 {
    padding: 0.5rem !important; }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.5rem !important; }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.5rem !important; }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.5rem !important; }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.5rem !important; }
  .p-sm-3 {
    padding: 1rem !important; }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1rem !important; }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1rem !important; }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1rem !important; }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1rem !important; }
  .p-sm-4 {
    padding: 1.5rem !important; }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.5rem !important; }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.5rem !important; }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.5rem !important; }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.5rem !important; }
  .p-sm-5 {
    padding: 3rem !important; }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 3rem !important; }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 3rem !important; }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 3rem !important; }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 3rem !important; }
  .m-sm-auto {
    margin: auto !important; }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important; }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important; }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important; }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important; } }

@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important; }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important; }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important; }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important; }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important; }
  .m-md-1 {
    margin: 0.25rem !important; }
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.25rem !important; }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.25rem !important; }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.25rem !important; }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.25rem !important; }
  .m-md-2 {
    margin: 0.5rem !important; }
  .mt-md-2,
  .my-md-2 {
    margin-top: 0.5rem !important; }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.5rem !important; }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.5rem !important; }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.5rem !important; }
  .m-md-3 {
    margin: 1rem !important; }
  .mt-md-3,
  .my-md-3 {
    margin-top: 1rem !important; }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 1rem !important; }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem !important; }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 1rem !important; }
  .m-md-4 {
    margin: 1.5rem !important; }
  .mt-md-4,
  .my-md-4 {
    margin-top: 1.5rem !important; }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.5rem !important; }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.5rem !important; }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.5rem !important; }
  .m-md-5 {
    margin: 3rem !important; }
  .mt-md-5,
  .my-md-5 {
    margin-top: 3rem !important; }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 3rem !important; }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 3rem !important; }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 3rem !important; }
  .p-md-0 {
    padding: 0 !important; }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important; }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important; }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important; }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important; }
  .p-md-1 {
    padding: 0.25rem !important; }
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.25rem !important; }
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.25rem !important; }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.25rem !important; }
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.25rem !important; }
  .p-md-2 {
    padding: 0.5rem !important; }
  .pt-md-2,
  .py-md-2 {
    padding-top: 0.5rem !important; }
  .pr-md-2,
  .px-md-2 {
    padding-right: 0.5rem !important; }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.5rem !important; }
  .pl-md-2,
  .px-md-2 {
    padding-left: 0.5rem !important; }
  .p-md-3 {
    padding: 1rem !important; }
  .pt-md-3,
  .py-md-3 {
    padding-top: 1rem !important; }
  .pr-md-3,
  .px-md-3 {
    padding-right: 1rem !important; }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1rem !important; }
  .pl-md-3,
  .px-md-3 {
    padding-left: 1rem !important; }
  .p-md-4 {
    padding: 1.5rem !important; }
  .pt-md-4,
  .py-md-4 {
    padding-top: 1.5rem !important; }
  .pr-md-4,
  .px-md-4 {
    padding-right: 1.5rem !important; }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.5rem !important; }
  .pl-md-4,
  .px-md-4 {
    padding-left: 1.5rem !important; }
  .p-md-5 {
    padding: 3rem !important; }
  .pt-md-5,
  .py-md-5 {
    padding-top: 3rem !important; }
  .pr-md-5,
  .px-md-5 {
    padding-right: 3rem !important; }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 3rem !important; }
  .pl-md-5,
  .px-md-5 {
    padding-left: 3rem !important; }
  .m-md-auto {
    margin: auto !important; }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important; }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important; }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important; }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important; } }

@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important; }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important; }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important; }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important; }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important; }
  .m-lg-1 {
    margin: 0.25rem !important; }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.25rem !important; }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.25rem !important; }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.25rem !important; }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.25rem !important; }
  .m-lg-2 {
    margin: 0.5rem !important; }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.5rem !important; }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.5rem !important; }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.5rem !important; }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.5rem !important; }
  .m-lg-3 {
    margin: 1rem !important; }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem !important; }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem !important; }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem !important; }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem !important; }
  .m-lg-4 {
    margin: 1.5rem !important; }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.5rem !important; }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.5rem !important; }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.5rem !important; }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.5rem !important; }
  .m-lg-5 {
    margin: 3rem !important; }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 3rem !important; }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 3rem !important; }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 3rem !important; }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 3rem !important; }
  .p-lg-0 {
    padding: 0 !important; }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important; }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important; }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important; }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important; }
  .p-lg-1 {
    padding: 0.25rem !important; }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.25rem !important; }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.25rem !important; }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.25rem !important; }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.25rem !important; }
  .p-lg-2 {
    padding: 0.5rem !important; }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.5rem !important; }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.5rem !important; }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.5rem !important; }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.5rem !important; }
  .p-lg-3 {
    padding: 1rem !important; }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem !important; }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1rem !important; }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem !important; }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1rem !important; }
  .p-lg-4 {
    padding: 1.5rem !important; }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.5rem !important; }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.5rem !important; }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.5rem !important; }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.5rem !important; }
  .p-lg-5 {
    padding: 3rem !important; }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 3rem !important; }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 3rem !important; }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 3rem !important; }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 3rem !important; }
  .m-lg-auto {
    margin: auto !important; }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important; }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important; }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important; }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important; } }

@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important; }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important; }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important; }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important; }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important; }
  .m-xl-1 {
    margin: 0.25rem !important; }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.25rem !important; }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.25rem !important; }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.25rem !important; }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.25rem !important; }
  .m-xl-2 {
    margin: 0.5rem !important; }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.5rem !important; }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.5rem !important; }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.5rem !important; }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.5rem !important; }
  .m-xl-3 {
    margin: 1rem !important; }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1rem !important; }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1rem !important; }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1rem !important; }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1rem !important; }
  .m-xl-4 {
    margin: 1.5rem !important; }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.5rem !important; }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.5rem !important; }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.5rem !important; }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.5rem !important; }
  .m-xl-5 {
    margin: 3rem !important; }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 3rem !important; }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 3rem !important; }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 3rem !important; }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 3rem !important; }
  .p-xl-0 {
    padding: 0 !important; }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important; }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important; }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important; }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important; }
  .p-xl-1 {
    padding: 0.25rem !important; }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.25rem !important; }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.25rem !important; }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.25rem !important; }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.25rem !important; }
  .p-xl-2 {
    padding: 0.5rem !important; }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.5rem !important; }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.5rem !important; }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.5rem !important; }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.5rem !important; }
  .p-xl-3 {
    padding: 1rem !important; }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1rem !important; }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1rem !important; }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1rem !important; }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1rem !important; }
  .p-xl-4 {
    padding: 1.5rem !important; }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.5rem !important; }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.5rem !important; }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.5rem !important; }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.5rem !important; }
  .p-xl-5 {
    padding: 3rem !important; }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 3rem !important; }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 3rem !important; }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 3rem !important; }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 3rem !important; }
  .m-xl-auto {
    margin: auto !important; }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important; }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important; }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important; }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important; } }

.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace; }

.text-justify {
  text-align: justify !important; }

.text-nowrap {
  white-space: nowrap !important; }

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.text-left {
  text-align: left !important; }

.text-right {
  text-align: right !important; }

.text-center {
  text-align: center !important; }

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important; }
  .text-sm-right {
    text-align: right !important; }
  .text-sm-center {
    text-align: center !important; } }

@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important; }
  .text-md-right {
    text-align: right !important; }
  .text-md-center {
    text-align: center !important; } }

@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important; }
  .text-lg-right {
    text-align: right !important; }
  .text-lg-center {
    text-align: center !important; } }

@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important; }
  .text-xl-right {
    text-align: right !important; }
  .text-xl-center {
    text-align: center !important; } }

.text-lowercase {
  text-transform: lowercase !important; }

.text-uppercase {
  text-transform: uppercase !important; }

.text-capitalize {
  text-transform: capitalize !important; }

.font-weight-light {
  font-weight: 300 !important; }

.font-weight-normal {
  font-weight: 400 !important; }

.font-weight-bold {
  font-weight: 700 !important; }

.font-italic {
  font-style: italic !important; }

.text-white {
  color: #FFFFFF !important; }

.text-primary {
  color: #0787e6 !important; }

a.text-primary:hover, a.text-primary:focus {
  color: #056ab5 !important; }

.text-secondary {
  color: #6c757d !important; }

a.text-secondary:hover, a.text-secondary:focus {
  color: #545b62 !important; }

.text-success {
  color: #28a745 !important; }

a.text-success:hover, a.text-success:focus {
  color: #1e7e34 !important; }

.text-info {
  color: #17a2b8 !important; }

a.text-info:hover, a.text-info:focus {
  color: #117a8b !important; }

.text-warning {
  color: #ffc107 !important; }

a.text-warning:hover, a.text-warning:focus {
  color: #d39e00 !important; }

.text-danger {
  color: #dc3545 !important; }

a.text-danger:hover, a.text-danger:focus {
  color: #bd2130 !important; }

.text-light {
  color: #f8f9fa !important; }

a.text-light:hover, a.text-light:focus {
  color: #dae0e5 !important; }

.text-dark {
  color: #343a40 !important; }

a.text-dark:hover, a.text-dark:focus {
  color: #1d2124 !important; }

.text-body {
  color: #212529 !important; }

.text-muted {
  color: #6c757d !important; }

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important; }

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important; }

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0; }

.visible {
  visibility: visible !important; }

.invisible {
  visibility: hidden !important; }

@media print {
  *,
  *::before,
  *::after {
    text-shadow: none !important;
    box-shadow: none !important; }
  a:not(.btn):not(.comment-form input[type="submit"]):not(.search-form .search-submit) {
    text-decoration: underline; }
  abbr[title]::after {
    content: " (" attr(title) ")"; }
  pre {
    white-space: pre-wrap !important; }
  pre,
  blockquote {
    border: 1px solid #adb5bd;
    page-break-inside: avoid; }
  thead {
    display: table-header-group; }
  tr,
  img {
    page-break-inside: avoid; }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3; }
  h2,
  h3 {
    page-break-after: avoid; }
  @page {
    size: a3; }
  body {
    min-width: 992px !important; }
  .container {
    min-width: 992px !important; }
  .navbar {
    display: none; }
  .badge {
    border: 1px solid #000; }
  .table {
    border-collapse: collapse !important; }
    .table td,
    .table th {
      background-color: #FFFFFF !important; }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #dee2e6 !important; }
  .table-dark {
    color: inherit; }
    .table-dark th,
    .table-dark td,
    .table-dark thead th,
    .table-dark tbody + tbody {
      border-color: #dee2e6; }
  .table .thead-dark th {
    color: inherit;
    border-color: #dee2e6; } }

/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent; }

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0; }
  .slick-list:focus {
    outline: none; }
  .slick-list.dragging {
    cursor: pointer;
    cursor: hand; }

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto; }
  .slick-track:before, .slick-track:after {
    content: "";
    display: table; }
  .slick-track:after {
    clear: both; }
  .slick-loading .slick-track {
    visibility: hidden; }

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none; }
  [dir="rtl"] .slick-slide {
    float: right; }
  .slick-slide img {
    display: block; }
  .slick-slide.slick-loading img {
    display: none; }
  .slick-slide.dragging img {
    pointer-events: none; }
  .slick-initialized .slick-slide {
    display: block; }
  .slick-loading .slick-slide {
    visibility: hidden; }
  .slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent; }

.slick-arrow.slick-hidden {
  display: none; }

[class^="icon-"], [class*=" icon-"] {
  font-family: "teccart" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.icon-equalizer:before {
  content: "\e90e"; }

.icon-menu:before {
  content: "\e90d"; }

.icon-close:before {
  content: "\e90c"; }

.icon-bus:before {
  content: "\e90b"; }

.icon-check:before {
  content: ""; }

.icon-facebook:before {
  content: ""; }

.icon-mail:before {
  content: ""; }

.icon-moins:before {
  content: ""; }

.icon-plus:before {
  content: ""; }

.icon-share:before {
  content: ""; }

.icon-pdf:before {
  content: ""; }

.icon-down-arrow:before {
  content: ""; }

.icon-right-arrow:before {
  content: ""; }

.icon-facebook_logo:before {
  content: ""; }

.icon-instagram_logo:before {
  content: ""; }

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }
  body.admin-bar header.banner {
    top: 30px; }

.brand {
  display: inline-block;
  max-width: 220px;
  width: 100%; }
  @media (min-width: 992px) {
    .brand {
      max-width: 250px; } }
  @media (min-width: 1200px) {
    .brand {
      max-width: 290px; } }

.primary-title, .wysiwyg h1, .wysiwyg-blue-box h1, .commentsyrendre__single--title, body.home .programs-type .wysiwyg h2, body.home .programs-type .wysiwyg-blue-box h2 {
  font-family: "Montserrat", sans-serif;
  font-size: 25px;
  font-weight: 700;
  line-height: 1.2;
  color: #06204c; }
  @media (min-width: 992px) {
    .primary-title, .wysiwyg h1, .wysiwyg-blue-box h1, .commentsyrendre__single--title, body.home .programs-type .wysiwyg h2, body.home .programs-type .wysiwyg-blue-box h2 {
      font-size: 30px; } }

.secondary-title, .wysiwyg h2, .wysiwyg-blue-box h2 {
  font-family: "Montserrat", sans-serif;
  font-size: 19px;
  font-weight: 700;
  line-height: 1.2;
  color: #06204c;
  text-transform: uppercase; }
  @media (min-width: 992px) {
    .secondary-title, .wysiwyg h2, .wysiwyg-blue-box h2 {
      font-size: 21px; } }
  @media (min-width: 1200px) {
    .secondary-title, .wysiwyg h2, .wysiwyg-blue-box h2 {
      font-size: 24px; } }

.primary-subtitle, .wysiwyg h3, .wysiwyg-blue-box h3, .aide_demarche__content h3, body.page-template-template-activites .activity__single--title, body.page-template-template-equipements .campus-equipement__single--title, body.page-template-template-mission .mission__quote h4 {
  font-family: "Montserrat", sans-serif;
  font-size: 15px;
  font-weight: 700;
  line-height: 1.2;
  color: #06204c; }

.secondary-subtitle, body.home .programs-type .wysiwyg h4, body.home .programs-type .wysiwyg-blue-box h4 {
  font-family: "Montserrat", sans-serif;
  font-size: 15px;
  font-weight: 300;
  line-height: 1.2;
  color: #06204c; }

.wysiwyg, .wysiwyg-blue-box {
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  color: #06204c; }
  .wysiwyg h1, .wysiwyg-blue-box h1 {
    margin-bottom: 20px; }
  .wysiwyg h2, .wysiwyg-blue-box h2 {
    margin-bottom: 20px; }
  .wysiwyg h3, .wysiwyg-blue-box h3 {
    margin-bottom: 15px; }
  .wysiwyg h4, .wysiwyg-blue-box h4 {
    font-family: "Open Sans", sans-serif;
    font-size: 14px;
    font-weight: 700;
    line-height: 1.2;
    color: #06204c;
    margin-bottom: 15px; }
  .wysiwyg a, .wysiwyg-blue-box a {
    text-decoration: underline;
    font-weight: bolder; }
  .wysiwyg p + h1, .wysiwyg-blue-box p + h1, .wysiwyg p + h2, .wysiwyg-blue-box p + h2, .wysiwyg p + h3, .wysiwyg-blue-box p + h3, .wysiwyg p + h4, .wysiwyg-blue-box p + h4 {
    margin-top: 30px; }
  .wysiwyg b, .wysiwyg-blue-box b, .wysiwyg strong, .wysiwyg-blue-box strong {
    font-weight: 700; }
  .wysiwyg ul, .wysiwyg-blue-box ul {
    list-style-type: none;
    padding: 0 0 1rem 0;
    margin: 0; }
    .wysiwyg ul li, .wysiwyg-blue-box ul li {
      padding-left: 1.5em;
      position: relative;
      padding-bottom: 6px; }
    .wysiwyg ul > li:before, .wysiwyg-blue-box ul > li:before {
      content: '—';
      padding-right: 5px;
      color: #0787e6;
      position: absolute;
      left: 0; }
    .wysiwyg ul ul, .wysiwyg-blue-box ul ul, .wysiwyg ul ol, .wysiwyg-blue-box ul ol {
      padding-left: 0;
      padding-top: 15px; }
      @media (min-width: 992px) {
        .wysiwyg ul ul, .wysiwyg-blue-box ul ul, .wysiwyg ul ol, .wysiwyg-blue-box ul ol {
          padding-left: 20px; } }
    .wysiwyg ul + h1, .wysiwyg-blue-box ul + h1, .wysiwyg ul + h2, .wysiwyg-blue-box ul + h2, .wysiwyg ul + h3, .wysiwyg-blue-box ul + h3, .wysiwyg ul + h4, .wysiwyg-blue-box ul + h4 {
      margin-top: 25px; }
  .wysiwyg ol > li, .wysiwyg-blue-box ol > li {
    padding-left: 0 !important; }
  .wysiwyg blockquote, .wysiwyg-blue-box blockquote {
    border: 2px solid #0787e6;
    padding: 30px 30px 20px;
    font-family: "Montserrat", sans-serif;
    font-size: 15px;
    font-weight: 700;
    line-height: 1.3;
    color: #0787e6;
    margin-top: 30px;
    margin-bottom: 30px; }
  .wysiwyg hr, .wysiwyg-blue-box hr {
    margin: 20px 0; }
    @media (min-width: 992px) {
      .wysiwyg hr, .wysiwyg-blue-box hr {
        margin: 35px 0; } }
  .wysiwyg.blue-check ul li, .blue-check.wysiwyg-blue-box ul li {
    padding-left: 2em;
    padding-bottom: 13px; }
    @media (min-width: 992px) {
      .wysiwyg.blue-check ul li, .blue-check.wysiwyg-blue-box ul li {
        padding-left: 2.2em;
        padding-bottom: 20px; } }
    .wysiwyg.blue-check ul li:before, .blue-check.wysiwyg-blue-box ul li:before {
      content: "";
      font-family: "teccart" !important;
      font-size: 12px;
      padding-right: 5px;
      color: #0787e6;
      position: absolute;
      left: 0; }
      @media (min-width: 992px) {
        .wysiwyg.blue-check ul li:before, .blue-check.wysiwyg-blue-box ul li:before {
          font-size: 14px; } }
  .wysiwyg .gallery.slick-slider, .wysiwyg-blue-box .gallery.slick-slider {
    margin-bottom: 30px; }
    @media (min-width: 992px) {
      .wysiwyg .gallery.slick-slider, .wysiwyg-blue-box .gallery.slick-slider {
        margin-bottom: 50px; } }
    .wysiwyg .gallery.slick-slider .gallery-item, .wysiwyg-blue-box .gallery.slick-slider .gallery-item {
      position: relative;
      padding-bottom: 60%; }
      .wysiwyg .gallery.slick-slider .gallery-item img, .wysiwyg-blue-box .gallery.slick-slider .gallery-item img {
        position: absolute;
        top: 50%;
        transform: translateY(-50%); }
    .wysiwyg .gallery.slick-slider .slick-prev, .wysiwyg-blue-box .gallery.slick-slider .slick-prev,
    .wysiwyg .gallery.slick-slider .slick-next, .wysiwyg-blue-box .gallery.slick-slider .slick-next {
      position: absolute;
      z-index: 999;
      display: block;
      height: 30px;
      width: 30px;
      cursor: pointer;
      background: #0787e6;
      color: #FFF;
      top: 100%;
      right: 0;
      padding: 0;
      border: none;
      outline: none;
      transform: translateY(-100%); }
      .wysiwyg .gallery.slick-slider .slick-prev:hover, .wysiwyg-blue-box .gallery.slick-slider .slick-prev:hover, .wysiwyg .gallery.slick-slider .slick-prev:focus, .wysiwyg-blue-box .gallery.slick-slider .slick-prev:focus,
      .wysiwyg .gallery.slick-slider .slick-next:hover, .wysiwyg-blue-box .gallery.slick-slider .slick-next:hover,
      .wysiwyg .gallery.slick-slider .slick-next:focus, .wysiwyg-blue-box .gallery.slick-slider .slick-next:focus {
        outline: none;
        background: #0787e6; }
      .wysiwyg .gallery.slick-slider .slick-prev.slick-disabled:before, .wysiwyg-blue-box .gallery.slick-slider .slick-prev.slick-disabled:before,
      .wysiwyg .gallery.slick-slider .slick-next.slick-disabled:before, .wysiwyg-blue-box .gallery.slick-slider .slick-next.slick-disabled:before {
        opacity: 0.5; }
      .wysiwyg .gallery.slick-slider .slick-prev i, .wysiwyg-blue-box .gallery.slick-slider .slick-prev i,
      .wysiwyg .gallery.slick-slider .slick-next i, .wysiwyg-blue-box .gallery.slick-slider .slick-next i {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%); }
    .wysiwyg .gallery.slick-slider .slick-prev, .wysiwyg-blue-box .gallery.slick-slider .slick-prev {
      transform: rotate(180deg) translateY(100%);
      right: 30px; }
    .wysiwyg .gallery.slick-slider .slick-next, .wysiwyg-blue-box .gallery.slick-slider .slick-next {
      right: 0; }

.wysiwyg-blue-box {
  border: 4px solid #0787e6;
  padding: 40px 30px;
  margin-top: 50px; }
  .wysiwyg-blue-box ul {
    padding: 10px 0 0 0;
    margin: 0;
    list-style-type: none; }
    .wysiwyg-blue-box ul li {
      padding: 6px 0; }
      .wysiwyg-blue-box ul li::before {
        display: none; }
      .wysiwyg-blue-box ul li a {
        font-family: "Open Sans", sans-serif;
        font-size: 14px;
        font-weight: 700;
        line-height: 1.2;
        color: #0787e6;
        text-decoration: underline; }

button:focus, a:focus {
  outline: 0; }

.primary-button, .aide_admission__wrapper a, .aide_demarche__wrapper a, .aide_financiere__wrapper a, .page-banner-home__content a, .post-navigation__menu-wrapper li a, body.page-template-template-Admission .boite_sidebar__wysiwyg a, body.home .about .wysiwyg a, body.home .about .wysiwyg-blue-box a {
  outline: 0;
  background: transparent;
  border: 0;
  cursor: pointer;
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  font-size: 14px;
  padding: 14px 19px 14px;
  background-color: #0787e6;
  color: #fff;
  border-radius: 0;
  display: inline-block;
  transition: all ease .2s;
  text-decoration: none;
  position: relative; }
  .primary-button:hover, .aide_admission__wrapper a:hover, .aide_demarche__wrapper a:hover, .aide_financiere__wrapper a:hover, .page-banner-home__content a:hover, .post-navigation__menu-wrapper li a:hover, body.page-template-template-Admission .boite_sidebar__wysiwyg a:hover, body.home .about .wysiwyg a:hover, body.home .about .wysiwyg-blue-box a:hover {
    background-color: #06204c;
    color: #fff;
    text-decoration: none; }
  .primary-button.white, .aide_admission__wrapper a, .aide_demarche__wrapper a.white, .aide_financiere__wrapper a.white, .page-banner-home__content a.white, .post-navigation__menu-wrapper li a.white, body.page-template-template-Admission .boite_sidebar__wysiwyg a, body.home .about .wysiwyg a.white, body.home .about .wysiwyg-blue-box a.white {
    background-color: #fff;
    color: #0787e6; }
    .primary-button.white:hover, .aide_admission__wrapper a:hover, .aide_demarche__wrapper a.white:hover, .aide_financiere__wrapper a.white:hover, .page-banner-home__content a.white:hover, .post-navigation__menu-wrapper li a.white:hover, body.page-template-template-Admission .boite_sidebar__wysiwyg a:hover, body.home .about .wysiwyg a.white:hover, body.home .about .wysiwyg-blue-box a.white:hover {
      background-color: #06204c;
      color: #fff; }
  .primary-button.wide, .aide_admission__wrapper a.wide, .aide_demarche__wrapper a.wide, .aide_financiere__wrapper a.wide, .page-banner-home__content a.wide, .post-navigation__menu-wrapper li a.wide, body.page-template-template-Admission .boite_sidebar__wysiwyg a.wide, body.home .about .wysiwyg a.wide, body.home .about .wysiwyg-blue-box a.wide {
    width: 100%;
    display: block;
    text-align: center; }
  .primary-button.arrow, .aide_admission__wrapper a.arrow, .aide_demarche__wrapper a.arrow, .aide_financiere__wrapper a.arrow, .page-banner-home__content a, .post-navigation__menu-wrapper li a.arrow, footer.content-info .more-infos .footer_actions a, body.page-template-template-Admission .boite_sidebar__wysiwyg a.arrow, body.home .about .wysiwyg a, body.home .about .wysiwyg-blue-box a {
    padding: 18px 50px 18px 19px; }
    .primary-button.arrow:before, .aide_admission__wrapper a.arrow:before, .aide_demarche__wrapper a.arrow:before, .aide_financiere__wrapper a.arrow:before, .page-banner-home__content a:before, .post-navigation__menu-wrapper li a.arrow:before, footer.content-info .more-infos .footer_actions a:before, body.page-template-template-Admission .boite_sidebar__wysiwyg a.arrow:before, body.home .about .wysiwyg a:before, body.home .about .wysiwyg-blue-box a:before {
      content: "";
      font-family: "teccart" !important;
      position: absolute;
      right: 19px;
      top: 50%;
      transform: translateY(-50%);
      transition: all ease .2s; }
    .primary-button.arrow:hover:before, .aide_admission__wrapper a.arrow:hover:before, .aide_demarche__wrapper a.arrow:hover:before, .aide_financiere__wrapper a.arrow:hover:before, .page-banner-home__content a:hover:before, .post-navigation__menu-wrapper li a.arrow:hover:before, footer.content-info .more-infos .footer_actions a:hover:before, body.page-template-template-Admission .boite_sidebar__wysiwyg a.arrow:hover:before, body.home .about .wysiwyg a:hover:before, body.home .about .wysiwyg-blue-box a:hover:before {
      transform: translateX(5px) translateY(-50%); }

.link-arrow, body.home .news-home .tab-content .tab-pane a.all-event-link span {
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  font-size: 14px;
  line-height: 1.2;
  display: inline-block;
  position: relative;
  padding-right: 14px; }
  .link-arrow:before, body.home .news-home .tab-content .tab-pane a.all-event-link span:before {
    content: "";
    font-family: "teccart" !important;
    position: absolute;
    left: 100%;
    top: 50%;
    transform: translateY(-50%);
    transition: all ease .2s; }
  .link-arrow:hover:before, body.home .news-home .tab-content .tab-pane a.all-event-link span:hover:before {
    transform: translateX(5px) translateY(-50%); }
  .link-arrow.blue, body.home .news-home .tab-content .tab-pane a.all-event-link span.blue {
    color: #0787e6; }

.newsletterBackdrop .modal-backdrop.show {
  opacity: 0.7; }

.modal-backdrop {
  background-color: #06204c; }
  .modal-backdrop.show {
    opacity: 0.7; }

.aide_admission__wrapper {
  background-color: #0787e6;
  width: 100%;
  border: solid 3px #0787e6;
  padding: 25px;
  margin-bottom: 33px;
  color: #fff; }
  @media (min-width: 768px) and (max-width: 991.98px) {
    .aide_admission__wrapper {
      height: 100%; } }
  @media (min-width: 992px) {
    .aide_admission__wrapper {
      padding: 31px 30px; } }
  .aide_admission__wrapper a {
    margin-top: 25px; }

.aide_admission__title {
  font-family: "Montserrat", sans-serif;
  font-size: 19px;
  font-weight: 700;
  line-height: 1.2;
  color: #fff;
  text-transform: uppercase;
  position: relative;
  margin-bottom: 35px;
  padding-bottom: 15px; }
  @media (min-width: 992px) {
    .aide_admission__title {
      margin-bottom: 46px;
      padding-bottom: 26px; } }
  .aide_admission__title:before {
    content: '';
    position: relative;
    width: 60px;
    height: 3px;
    background-color: #007bff;
    position: absolute;
    top: 100%;
    left: 0; }
    @media (min-width: 992px) {
      .aide_admission__title:before {
        width: 83px; } }
  @media (min-width: 992px) {
    .aide_admission__title {
      font-size: 24px; } }

.aide_demarche__wrapper {
  background-color: #fff;
  width: 100%;
  border: solid 3px #0787e6;
  padding: 25px;
  margin-bottom: 33px; }
  @media (min-width: 768px) and (max-width: 991.98px) {
    .aide_demarche__wrapper {
      height: 100%; } }
  @media (min-width: 992px) {
    .aide_demarche__wrapper {
      padding: 31px 30px; } }
  .aide_demarche__wrapper a {
    margin-top: 25px; }

.aide_demarche__title {
  font-family: "Montserrat", sans-serif;
  font-size: 19px;
  font-weight: 700;
  line-height: 1.2;
  color: #06204c;
  text-transform: uppercase;
  position: relative;
  margin-bottom: 35px;
  padding-bottom: 15px; }
  @media (min-width: 992px) {
    .aide_demarche__title {
      margin-bottom: 46px;
      padding-bottom: 26px; } }
  .aide_demarche__title:before {
    content: '';
    position: relative;
    width: 60px;
    height: 3px;
    background-color: #007bff;
    position: absolute;
    top: 100%;
    left: 0; }
    @media (min-width: 992px) {
      .aide_demarche__title:before {
        width: 83px; } }
  @media (min-width: 992px) {
    .aide_demarche__title {
      font-size: 24px; } }

.aide_demarche__content h3 {
  color: #06204c;
  margin-bottom: 30px; }

.aide_financiere__wrapper {
  background-color: #fff;
  width: 100%;
  border: solid 3px #0787e6;
  padding: 25px;
  margin-bottom: 33px; }
  @media (min-width: 768px) and (max-width: 991.98px) {
    .aide_financiere__wrapper {
      height: 100%; } }
  @media (min-width: 992px) {
    .aide_financiere__wrapper {
      padding: 31px 30px; } }
  .aide_financiere__wrapper a {
    margin-top: 25px; }

.aide_financiere__title {
  font-family: "Montserrat", sans-serif;
  font-size: 19px;
  font-weight: 700;
  line-height: 1.2;
  color: #06204c;
  text-transform: uppercase;
  position: relative;
  margin-bottom: 35px;
  padding-bottom: 15px; }
  @media (min-width: 992px) {
    .aide_financiere__title {
      margin-bottom: 46px;
      padding-bottom: 26px; } }
  .aide_financiere__title:before {
    content: '';
    position: relative;
    width: 60px;
    height: 3px;
    background-color: #007bff;
    position: absolute;
    top: 100%;
    left: 0; }
    @media (min-width: 992px) {
      .aide_financiere__title:before {
        width: 83px; } }
  @media (min-width: 992px) {
    .aide_financiere__title {
      font-size: 24px; } }

.article-share__wrapper {
  width: 95px; }

.article-share__button {
  outline: 0;
  background: transparent;
  border: 0;
  cursor: pointer;
  background-color: #0787e6;
  font-family: "Open Sans", sans-serif;
  font-size: 10px;
  font-weight: 700;
  line-height: 1;
  color: #fff;
  padding: 9px 15px 8px 35px;
  border-radius: 1.5px;
  width: 100%;
  text-align: left;
  position: relative;
  z-index: 1001; }
  .article-share__button:after {
    display: none; }
  .article-share__button:hover {
    background-color: #0670be; }
  .article-share__button[aria-expanded=true] {
    background-color: #0670be;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0; }
  .article-share__button i {
    position: absolute;
    left: 10px;
    top: 50%;
    transform: translateY(-50%);
    font-size: 12px;
    width: 14px;
    text-align: center; }

.article-share__dropdown {
  min-width: 0;
  width: 100%;
  padding: 0;
  margin: 0;
  border: 0;
  border-radius: 0;
  background-color: #0787e6; }
  .article-share__dropdown:before {
    content: '';
    background-color: #0787e6;
    width: 100%;
    height: 5px;
    position: absolute;
    bottom: calc(100% - 2px);
    left: 0;
    z-index: -1; }
  .article-share__dropdown .dropdown-item {
    padding: 7px 15px 7px 35px;
    display: block;
    text-align: left;
    font-family: "Open Sans", sans-serif;
    font-size: 10px;
    font-weight: 700;
    line-height: 1;
    color: #fff;
    position: relative; }
    .article-share__dropdown .dropdown-item:hover {
      background-color: #0670be; }
    .article-share__dropdown .dropdown-item i {
      position: absolute;
      left: 10px;
      top: 50%;
      transform: translateY(-50%);
      font-size: 10px;
      width: 14px;
      text-align: center; }

.page-banner-big {
  background: url("../images/home-banner-tile.jpg") repeat-x;
  background-size: auto 100%; }
  .page-banner-big__wrapper {
    background-repeat: no-repeat;
    background-size: auto 100%;
    background-position: center; }
  .page-banner-big__container {
    position: relative;
    padding-bottom: 58%; }
  .page-banner-big__content {
    width: 50%;
    position: absolute;
    top: 50%;
    transform: translateY(-53%);
    color: #FFFFFF;
    font-size: 0.875rem;
    line-height: 1.125rem; }
    .page-banner-big__content p {
      margin-bottom: 8px; }
    .page-banner-big__content strong {
      font-family: "Montserrat", sans-serif;
      font-weight: 300;
      font-size: 1.125rem; }
    .page-banner-big__content h1 {
      text-transform: uppercase;
      font-family: "Montserrat", sans-serif;
      font-weight: 700;
      font-size: 2.25rem;
      margin-bottom: 20px; }
    .page-banner-big__content a {
      color: #fff;
      text-decoration: underline; }
    .page-banner-big__content .teccart-breadcrumb {
      margin-bottom: 20px; }

.page-banner-flat {
  background: url("../images/fond_bleu_degrade.jpg") repeat-x;
  background-size: auto 100%; }
  .page-banner-flat__wrapper {
    background-repeat: no-repeat;
    background-size: auto 100%;
    background-position: center;
    overflow: hidden; }
    .page-banner-flat__wrapper > .container {
      position: relative; }
      .page-banner-flat__wrapper > .container:before {
        content: '';
        width: 100vw;
        height: 100%;
        position: absolute;
        left: 50%;
        top: 0;
        transform: translateX(-50%);
        background-color: rgba(6, 32, 76, 0.7); }
        @media (min-width: 992px) {
          .page-banner-flat__wrapper > .container:before {
            background: none;
            display: none; } }
  .page-banner-flat__container {
    margin-top: 100px; }
    @media (min-width: 992px) {
      .page-banner-flat__container {
        background: none;
        padding-top: 0;
        position: relative;
        padding-bottom: 33%; } }
  .page-banner-flat__content {
    color: #FFFFFF;
    font-size: 12px;
    line-height: 1.125rem;
    padding-bottom: 25px; }
    @media (min-width: 992px) {
      .page-banner-flat__content {
        position: absolute;
        width: 50%;
        top: 50%;
        transform: translateY(-30%);
        padding-bottom: 0;
        font-size: 14px; } }
    .page-banner-flat__content h1, .page-banner-flat__content .primary-title, .page-banner-flat__content .wysiwyg h1, .wysiwyg .page-banner-flat__content h1, .page-banner-flat__content .wysiwyg-blue-box h1, .wysiwyg-blue-box .page-banner-flat__content h1, .page-banner-flat__content .commentsyrendre__single--title, .page-banner-flat__content body.home .programs-type .wysiwyg h2, body.home .programs-type .wysiwyg .page-banner-flat__content h2, .page-banner-flat__content body.home .programs-type .wysiwyg-blue-box h2, body.home .programs-type .wysiwyg-blue-box .page-banner-flat__content h2 {
      font-size: 23px;
      color: #FFFFFF;
      text-transform: uppercase; }
      @media (min-width: 992px) {
        .page-banner-flat__content h1, .page-banner-flat__content .primary-title, .page-banner-flat__content .wysiwyg h1, .wysiwyg .page-banner-flat__content h1, .page-banner-flat__content .wysiwyg-blue-box h1, .wysiwyg-blue-box .page-banner-flat__content h1, .page-banner-flat__content .commentsyrendre__single--title, .page-banner-flat__content body.home .programs-type .wysiwyg h2, body.home .programs-type .wysiwyg .page-banner-flat__content h2, .page-banner-flat__content body.home .programs-type .wysiwyg-blue-box h2, body.home .programs-type .wysiwyg-blue-box .page-banner-flat__content h2 {
          font-size: 36px; } }
    .page-banner-flat__content a {
      color: #fff;
      text-decoration: underline; }
    .page-banner-flat__content .teccart-breadcrumb {
      margin-bottom: 13px; }
      @media (min-width: 992px) {
        .page-banner-flat__content .teccart-breadcrumb {
          margin-bottom: 20px; } }

.page-banner-home {
  background: url("../images/home-banner-tile.jpg") repeat-x;
  background-size: auto 100%;
  padding-top: 70px; }
  @media (min-width: 768px) {
    .page-banner-home {
      padding-top: 0; } }
  .page-banner-home__wrapper {
    background-repeat: no-repeat;
    background-size: auto 100%;
    background-position: center; }
  .page-banner-home__container {
    position: relative;
    padding-bottom: 50px; }
    @media (min-width: 992px) {
      .page-banner-home__container {
        padding-bottom: 58%; } }
  .page-banner-home__content {
    color: #FFFFFF;
    font-size: 0.875rem;
    line-height: 1.125rem;
    padding-top: 50px; }
    @media (min-width: 768px) {
      .page-banner-home__content {
        padding-top: 100px; } }
    @media (min-width: 992px) {
      .page-banner-home__content {
        width: 41%;
        position: absolute;
        top: 50%;
        transform: translateY(-53%);
        padding-top: 0px; } }
    .page-banner-home__content p {
      margin-bottom: 8px; }
    .page-banner-home__content strong {
      font-family: "Montserrat", sans-serif;
      font-weight: 300;
      font-size: 1.125rem; }
    .page-banner-home__content h1 {
      text-transform: uppercase;
      font-family: "Montserrat", sans-serif;
      font-weight: 700;
      margin-bottom: 20px;
      font-size: 25px;
      line-height: 1.2; }
      @media (min-width: 992px) {
        .page-banner-home__content h1 {
          font-size: 31px; } }
      @media (min-width: 1200px) {
        .page-banner-home__content h1 {
          font-size: 35px; } }
    .page-banner-home__content a {
      margin-top: 15px;
      position: relative; }
      @media (max-width: 767.98px) {
        .page-banner-home__content a {
          width: 100%;
          max-width: 240px; } }
      .page-banner-home__content a:first-child {
        margin-top: 40px; }

.page-banner-none {
  background: #06204c;
  background-size: auto 100%;
  height: 100px; }

.comment-list ol {
  list-style: none; }

.courses-accordian__wrapper {
  border-radius: 0;
  border: 0; }
  .courses-accordian__wrapper.mtl .card-header {
    background-color: #F78505; }
  .courses-accordian__wrapper.south-shore .card-header {
    background-color: #C61C78; }

.courses-accordian__single {
  border-radius: 0;
  border: 0;
  margin-bottom: 30px; }
  .courses-accordian__single .card-header {
    background: #0787e6;
    padding: 16px 65px 16px 20px;
    border: 0;
    border-radius: 0;
    position: relative; }
    .courses-accordian__single .card-header .card-title {
      font-family: "Open Sans", sans-serif;
      font-size: 18px;
      font-weight: 700;
      line-height: 1.2;
      color: #fff; }
    .courses-accordian__single .card-header .card-button {
      position: absolute;
      width: 53px;
      height: 100%;
      top: 0;
      right: 0;
      background-color: rgba(255, 255, 255, 0.25);
      display: block;
      vertical-align: middle; }
      .courses-accordian__single .card-header .card-button[aria-expanded="true"]:before {
        content: ""; }
      .courses-accordian__single .card-header .card-button:before {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        color: #f6f7f9;
        font-family: "teccart" !important;
        font-size: 14px;
        line-height: 0.75; }
  .courses-accordian__single .card-body {
    padding: 15px 20px 20px; }

.courses-accordian__content {
  border-right: solid 1px #ebeef1;
  border-left: solid 1px #ebeef1;
  border-bottom: solid 1px #ebeef1; }

.courses__header {
  font-family: "Montserrat", sans-serif;
  font-size: 15px;
  font-weight: 700;
  line-height: 1.2;
  color: #06204c;
  margin-bottom: 12px; }

.courses__row {
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  line-height: 1.2;
  color: #06204c;
  margin-bottom: 10px; }

.informations__wrapper {
  padding: 0;
  margin: 10px 0 20px;
  list-style-type: none; }
  @media (min-width: 992px) {
    .informations__wrapper {
      border-top: 1px solid #0787e6;
      margin: 45px 0 25px 0; } }
  .informations__wrapper li {
    position: relative;
    padding-right: 30px;
    padding-top: 15px;
    padding-bottom: 15px;
    border-bottom: 1px solid #0787e6; }
    @media (min-width: 1200px) {
      .informations__wrapper li {
        padding-top: 25px;
        padding-bottom: 25px; } }
    .informations__wrapper li a {
      font-family: "Montserrat", sans-serif;
      font-size: 15px;
      font-weight: 700;
      line-height: 1.2;
      color: #06204c;
      display: block; }
      @media (min-width: 1200px) {
        .informations__wrapper li a {
          font-size: 18px; } }
      .informations__wrapper li a:before {
        content: "";
        font-family: "teccart" !important;
        font-size: 24px;
        font-weight: 400;
        position: absolute;
        top: 50%;
        right: 0;
        transform: translateY(-50%);
        color: #0787e6; }

.faq-accordian__wrapper {
  border-radius: 0;
  border: 0; }
  .faq-accordian__wrapper.mtl .faq-accordian__single .card-button {
    color: #F78505; }
  .faq-accordian__wrapper.south-shore .faq-accordian__single .card-button {
    color: #C61C78; }

.faq-accordian__single {
  border-radius: 0;
  border: 0;
  border-bottom: 2px solid #ebeef1;
  margin-bottom: 30px; }
  .faq-accordian__single .card-header {
    background-color: #fff;
    padding: 16px 20px;
    border: 0;
    border-radius: 0;
    position: relative; }
    .faq-accordian__single .card-header .card-title {
      font-family: "Open Sans", sans-serif;
      font-size: 18px;
      font-weight: 700;
      line-height: 1.2;
      color: #06204c; }
    .faq-accordian__single .card-header .card-button {
      position: absolute;
      width: 53px;
      height: 53px;
      top: 0;
      right: 0;
      display: block;
      vertical-align: middle; }
      .faq-accordian__single .card-header .card-button[aria-expanded="true"]:before {
        content: ""; }
      .faq-accordian__single .card-header .card-button:before {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        color: #0787e6;
        font-family: "teccart" !important;
        font-size: 14px;
        line-height: 0.75; }
  .faq-accordian__single .card-body {
    padding: 15px 20px 20px; }

.faq__header {
  font-family: "Montserrat", sans-serif;
  font-size: 15px;
  font-weight: 700;
  line-height: 1.2;
  color: #06204c;
  margin-bottom: 12px; }

.faq__row {
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  line-height: 1.2;
  color: #06204c;
  margin-bottom: 10px; }

.search-form label {
  font-weight: normal; }

form.wpcf7-form input, form.wpcf7-form textarea {
  outline: 0; }

form.wpcf7-form .wpcf7-form-control-wrap {
  display: block;
  width: 100%; }

form.wpcf7-form .wpcf7-text, form.wpcf7-form .wpcf7-tel, form.wpcf7-form .wpcf7-email, form.wpcf7-form .wpcf7-date, form.wpcf7-form .wpcf7-file {
  background: transparent;
  padding: 7px 10px;
  border: 1px solid #0787e6;
  width: 100%;
  border-radius: 0;
  margin-bottom: 30px;
  appearance: none;
  font-family: "Open Sans", sans-serif;
  font-size: 13px;
  line-height: 1.2;
  color: #06204c; }
  form.wpcf7-form .wpcf7-text::-webkit-input-placeholder, form.wpcf7-form .wpcf7-tel::-webkit-input-placeholder, form.wpcf7-form .wpcf7-email::-webkit-input-placeholder, form.wpcf7-form .wpcf7-date::-webkit-input-placeholder, form.wpcf7-form .wpcf7-file::-webkit-input-placeholder {
    color: inherit; }
  form.wpcf7-form .wpcf7-text::-moz-placeholder, form.wpcf7-form .wpcf7-tel::-moz-placeholder, form.wpcf7-form .wpcf7-email::-moz-placeholder, form.wpcf7-form .wpcf7-date::-moz-placeholder, form.wpcf7-form .wpcf7-file::-moz-placeholder {
    color: inherit; }
  form.wpcf7-form .wpcf7-text:-ms-input-placeholder, form.wpcf7-form .wpcf7-tel:-ms-input-placeholder, form.wpcf7-form .wpcf7-email:-ms-input-placeholder, form.wpcf7-form .wpcf7-date:-ms-input-placeholder, form.wpcf7-form .wpcf7-file:-ms-input-placeholder {
    color: inherit; }
  form.wpcf7-form .wpcf7-text:-moz-placeholder, form.wpcf7-form .wpcf7-tel:-moz-placeholder, form.wpcf7-form .wpcf7-email:-moz-placeholder, form.wpcf7-form .wpcf7-date:-moz-placeholder, form.wpcf7-form .wpcf7-file:-moz-placeholder {
    color: inherit; }
  form.wpcf7-form .wpcf7-text.wpcf7-not-valid, form.wpcf7-form .wpcf7-tel.wpcf7-not-valid, form.wpcf7-form .wpcf7-email.wpcf7-not-valid, form.wpcf7-form .wpcf7-date.wpcf7-not-valid, form.wpcf7-form .wpcf7-file.wpcf7-not-valid {
    border-color: red;
    color: red; }

form.wpcf7-form .wpcf7-text, form.wpcf7-form .wpcf7-tel, form.wpcf7-form .wpcf7-email, form.wpcf7-form .wpcf7-date, form.wpcf7-form .wpcf7-select, form.wpcf7-form .wpcf7-file {
  position: relative; }
  form.wpcf7-form .wpcf7-text + .wpcf7-not-valid-tip, form.wpcf7-form .wpcf7-tel + .wpcf7-not-valid-tip, form.wpcf7-form .wpcf7-email + .wpcf7-not-valid-tip, form.wpcf7-form .wpcf7-date + .wpcf7-not-valid-tip, form.wpcf7-form .wpcf7-select + .wpcf7-not-valid-tip, form.wpcf7-form .wpcf7-file + .wpcf7-not-valid-tip {
    font-size: 10px;
    color: red;
    margin-top: -28px;
    position: absolute;
    width: 100%;
    left: 0;
    top: 100%; }

form.wpcf7-form label {
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  font-weight: 700;
  line-height: 1.2;
  color: #06204c;
  cursor: pointer; }

form.wpcf7-form .wpcf7-file {
  border-style: dashed; }

form.wpcf7-form .wpcf7-list-item.first {
  margin: 0; }

form.wpcf7-form .wpcf7-textarea {
  background: transparent;
  font-family: "Open Sans", sans-serif;
  font-size: 13px;
  line-height: 1.2;
  color: #06204c;
  border: 1px solid #0787e6;
  width: 100%;
  resize: none;
  border-radius: 0;
  margin-bottom: 40px;
  padding: 15px 20px;
  appearance: none; }

form.wpcf7-form .wpcf7-select {
  width: 100%;
  padding: 7px 10px;
  border-radius: 0;
  background-color: #fff;
  box-shadow: none;
  border: 1px solid #0787e6;
  appearance: none;
  font-family: "Open Sans", sans-serif;
  font-size: 13px;
  line-height: 1.2;
  color: #06204c;
  margin-bottom: 30px;
  position: relative;
  background: url("../images/carret.svg") no-repeat;
  background-size: 11px auto;
  background-position: center right 20px; }

form.wpcf7-form .wpcf7-recaptcha {
  transform: scale(0.81);
  transform-origin: 0 0;
  margin-bottom: 40px; }
  @media (min-width: 768px) {
    form.wpcf7-form .wpcf7-recaptcha {
      transform: scale(1); } }

form.wpcf7-form .wpcf7-validation-errors, form.wpcf7-form .wpcf7-acceptance-missing {
  border: 0;
  color: red;
  padding: 0;
  margin-bottom: 50px; }

form.wpcf7-form .wpcf7-submit {
  min-width: 150px; }

.frais__header {
  font-family: "Montserrat", sans-serif;
  font-size: 15px;
  font-weight: 700;
  line-height: 1.2;
  color: #fff;
  background: #0787e6;
  padding: 10px 0;
  margin: 0; }

.frais__row {
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  line-height: 1.2;
  color: #06204c;
  padding-top: 12px;
  padding-bottom: 12px;
  margin: 0;
  border-left: 1px solid #ebeef1;
  border-right: 1px solid #ebeef1; }
  .frais__row:last-child {
    border-bottom: 1px solid #ebeef1; }

.main {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px; }
  @media (min-width: 576px) {
    .main {
      flex: 0 0 100%;
      max-width: 100%; }
      .sidebar-primary .main {
        flex: 0 0 66.6666666667%;
        max-width: 66.6666666667%; } }

.sidebar {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px; }
  @media (min-width: 576px) {
    .sidebar {
      flex: 0 0 33.3333333333%;
      max-width: 33.3333333333%; } }

.dl-menuwrapper {
  width: 100%;
  float: left;
  position: relative;
  perspective: 1000px;
  perspective-origin: 50% 200%; }
  .dl-menuwrapper ul {
    background: #fff;
    padding: 0;
    list-style: none;
    transform-style: preserve-3d; }
    .dl-menuwrapper ul li {
      position: relative; }
      .dl-menuwrapper ul li.menu-item.blue > a {
        color: #0787e6;
        font-weight: 700; }
      .dl-menuwrapper ul li.menu-item.orange > a {
        color: #F78505;
        font-weight: 700; }
      .dl-menuwrapper ul li.menu-item.red > a {
        color: #C61C78;
        font-weight: 700; }
      .dl-menuwrapper ul li a {
        display: block;
        position: relative;
        padding: 11px 20px;
        font-family: "Montserrat", sans-serif;
        font-size: 14px;
        line-height: 20px;
        font-weight: 700;
        color: #06204c;
        outline: none;
        text-decoration: none; }
        .dl-menuwrapper ul li a:hover {
          text-decoration: none; }
      .dl-menuwrapper ul li.dl-back > a {
        padding-left: 30px;
        background: #0787e6;
        color: #fff; }
      .dl-menuwrapper ul li.dl-back:after {
        content: "";
        position: absolute;
        top: 50%;
        font-family: "teccart" !important;
        speak: none;
        -webkit-font-smoothing: antialiased;
        left: 10px;
        color: #fff;
        transform: rotate(180deg) translateY(-50%);
        transform-origin: top center; }
      .dl-menuwrapper ul li > a:not(:only-child):after {
        content: "";
        font-family: "teccart" !important;
        position: absolute;
        top: 50%;
        speak: none;
        -webkit-font-smoothing: antialiased;
        transform: translateY(-50%); }
      .dl-menuwrapper ul li > a:after {
        right: 10px;
        color: #06204c; }
  .dl-menuwrapper .dl-menu {
    margin: 0;
    position: absolute;
    width: 100%;
    opacity: 0;
    pointer-events: none;
    transform: translateY(10px);
    backface-visibility: hidden; }
    .dl-menuwrapper .dl-menu.dl-menu-toggle {
      transition: all 0.3s ease; }
    .dl-menuwrapper .dl-menu.dl-menuopen {
      opacity: 1;
      pointer-events: auto;
      transform: translateY(0px); }
    .dl-menuwrapper .dl-menu.dl-subview li {
      display: none; }
      .dl-menuwrapper .dl-menu.dl-subview li.dl-subviewopen > a {
        display: none; }
      .dl-menuwrapper .dl-menu.dl-subview li.dl-subview {
        display: block; }
        .dl-menuwrapper .dl-menu.dl-subview li.dl-subview > a {
          display: none; }
        .dl-menuwrapper .dl-menu.dl-subview li.dl-subview .dl-submenu {
          display: block; }
      .dl-menuwrapper .dl-menu.dl-subview li.dl-subviewopen {
        display: block; }
        .dl-menuwrapper .dl-menu.dl-subview li.dl-subviewopen > .dl-submenu {
          display: block; }
          .dl-menuwrapper .dl-menu.dl-subview li.dl-subviewopen > .dl-submenu.programmes_navigation > .col-title.blue a, .dl-menuwrapper .dl-menu.dl-subview li.dl-subviewopen > .dl-submenu.programmes_navigation > .col-title.orange a, .dl-menuwrapper .dl-menu.dl-subview li.dl-subviewopen > .dl-submenu.programmes_navigation > .col-title.red a {
            color: #06204c; }
          .dl-menuwrapper .dl-menu.dl-subview li.dl-subviewopen > .dl-submenu > li {
            display: block; }
    .dl-menuwrapper .dl-menu.dl-animate-out-1 {
      animation: MenuAnimOut1 0.4s; }
    .dl-menuwrapper .dl-menu.dl-animate-out-2 {
      animation: MenuAnimOut2 0.3s ease-in-out; }
    .dl-menuwrapper .dl-menu.dl-animate-out-3 {
      animation: MenuAnimOut3 0.4s ease; }
    .dl-menuwrapper .dl-menu.dl-animate-out-4 {
      animation: MenuAnimOut4 0.4s ease; }
    .dl-menuwrapper .dl-menu.dl-animate-out-5 {
      animation: MenuAnimOut5 0.4s ease; }
    .dl-menuwrapper .dl-menu.dl-animate-in-1 {
      animation: MenuAnimIn1 0.3s; }
    .dl-menuwrapper .dl-menu.dl-animate-in-2 {
      animation: MenuAnimIn2 0.3s ease-in-out; }
    .dl-menuwrapper .dl-menu.dl-animate-in-3 {
      animation: MenuAnimIn3 0.4s ease; }
    .dl-menuwrapper .dl-menu.dl-animate-in-4 {
      animation: MenuAnimIn4 0.4s ease; }
    .dl-menuwrapper .dl-menu.dl-animate-in-5 {
      animation: MenuAnimIn5 0.4s ease; }
  .dl-menuwrapper li .dl-submenu {
    display: none; }
  .dl-menuwrapper > .dl-submenu {
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    margin: 0; }
    .dl-menuwrapper > .dl-submenu.dl-animate-in-1 {
      animation: SubMenuAnimIn1 0.4s ease; }
    .dl-menuwrapper > .dl-submenu.dl-animate-in-2 {
      animation: SubMenuAnimIn2 0.3s ease-in-out; }
    .dl-menuwrapper > .dl-submenu.dl-animate-in-3 {
      animation: SubMenuAnimIn3 0.4s ease; }
    .dl-menuwrapper > .dl-submenu.dl-animate-in-4 {
      animation: SubMenuAnimIn4 0.4s ease; }
    .dl-menuwrapper > .dl-submenu.dl-animate-in-5 {
      animation: SubMenuAnimIn5 0.4s ease; }
    .dl-menuwrapper > .dl-submenu.dl-animate-out-1 {
      animation: SubMenuAnimOut1 0.4s ease; }
    .dl-menuwrapper > .dl-submenu.dl-animate-out-2 {
      animation: SubMenuAnimOut2 0.3s ease-in-out; }
    .dl-menuwrapper > .dl-submenu.dl-animate-out-3 {
      animation: SubMenuAnimOut3 0.4s ease; }
    .dl-menuwrapper > .dl-submenu.dl-animate-out-4 {
      animation: SubMenuAnimOut4 0.4s ease; }
    .dl-menuwrapper > .dl-submenu.dl-animate-out-5 {
      animation: SubMenuAnimOut5 0.4s ease; }
  .dl-menuwrapper .secondary_navigation {
    padding-top: 20px; }
    .dl-menuwrapper .secondary_navigation .menu-item a {
      font-weight: 400; }

/* No JS Fallback */
.no-js .dl-menuwrapper .dl-menu {
  position: relative;
  opacity: 1;
  transform: none; }

.no-js .dl-menuwrapper li .dl-submenu {
  display: block; }

.no-js .dl-menuwrapper li.dl-back {
  display: none; }

.no-js .dl-menuwrapper li > a:not(:only-child) {
  background: rgba(0, 0, 0, 0.1); }
  .no-js .dl-menuwrapper li > a:not(:only-child):after {
    content: ''; }

@keyframes MenuAnimOut1 {
  50% {
    transform: translateZ(-250px) rotateY(30deg); }
  75% {
    transform: translateZ(-372.5px) rotateY(15deg);
    opacity: .5; }
  100% {
    transform: translateZ(-500px) rotateY(0);
    opacity: 0; } }

@keyframes MenuAnimOut2 {
  100% {
    transform: translateX(-100%);
    opacity: 0; } }

@keyframes MenuAnimOut3 {
  100% {
    transform: translateZ(300px);
    opacity: 0; } }

@keyframes MenuAnimOut4 {
  100% {
    transform: translateZ(-300px);
    opacity: 0; } }

@keyframes MenuAnimOut5 {
  100% {
    transform: translateY(40%);
    opacity: 0; } }

@keyframes MenuAnimIn1 {
  0% {
    transform: translateZ(-500px) rotateY(0);
    opacity: 0; }
  20% {
    transform: translateZ(-250px) rotateY(30deg);
    opacity: .5; }
  100% {
    transform: translateZ(0) rotateY(0);
    opacity: 1; } }

@keyframes MenuAnimIn2 {
  0% {
    transform: translateX(-100%);
    opacity: 0; }
  100% {
    transform: translateX(0);
    opacity: 1; } }

@keyframes MenuAnimIn3 {
  0% {
    transform: translateZ(300px);
    opacity: 0; }
  100% {
    transform: translateZ(0);
    opacity: 1; } }

@keyframes MenuAnimIn4 {
  0% {
    transform: translateZ(-300px);
    opacity: 0; }
  100% {
    transform: translateZ(0);
    opacity: 1; } }

@keyframes MenuAnimIn5 {
  0% {
    transform: translateY(40%);
    opacity: 0; }
  100% {
    transform: translateY(0);
    opacity: 1; } }

@keyframes SubMenuAnimIn1 {
  0% {
    transform: translateX(50%);
    opacity: 0; }
  100% {
    transform: translateX(0);
    opacity: 1; } }

@keyframes SubMenuAnimIn2 {
  0% {
    transform: translateX(100%);
    opacity: 0; }
  100% {
    transform: translateX(0);
    opacity: 1; } }

@keyframes SubMenuAnimIn3 {
  0% {
    transform: translateZ(-300px);
    opacity: 0; }
  100% {
    transform: translateZ(0);
    opacity: 1; } }

@keyframes SubMenuAnimIn4 {
  0% {
    transform: translateZ(300px);
    opacity: 0; }
  100% {
    transform: translateZ(0);
    opacity: 1; } }

@keyframes SubMenuAnimIn5 {
  0% {
    transform: translateZ(-200px);
    opacity: 0; }
  100% {
    transform: translateZ(0);
    opacity: 1; } }

@keyframes SubMenuAnimOut1 {
  0% {
    transform: translateX(0);
    opacity: 1; }
  100% {
    transform: translateX(50%);
    opacity: 0; } }

@keyframes SubMenuAnimOut2 {
  0% {
    transform: translateX(0);
    opacity: 1; }
  100% {
    transform: translateX(100%);
    opacity: 0; } }

@keyframes SubMenuAnimOut3 {
  0% {
    transform: translateZ(0);
    opacity: 1; }
  100% {
    transform: translateZ(-300px);
    opacity: 0; } }

@keyframes SubMenuAnimOut4 {
  0% {
    transform: translateZ(0);
    opacity: 1; }
  100% {
    transform: translateZ(300px);
    opacity: 0; } }

@keyframes SubMenuAnimOut5 {
  0% {
    transform: translateZ(0);
    opacity: 1; }
  100% {
    transform: translateZ(-200px);
    opacity: 0; } }

.nav-primary__wrapper .menu-teaser__wrapper {
  position: absolute;
  width: 180px;
  height: 100%;
  top: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  background-color: #0787e6; }
  .nav-primary__wrapper .menu-teaser__wrapper .menu-item {
    height: 33.33333%;
    border-bottom: 1px solid #fff;
    display: flex;
    align-items: center;
    padding: 0 !important; }
    .nav-primary__wrapper .menu-teaser__wrapper .menu-item:last-child {
      border: 0; }
    .nav-primary__wrapper .menu-teaser__wrapper .menu-item a {
      font-family: "Montserrat", sans-serif;
      font-weight: 700;
      font-size: 13px;
      color: #FFFFFF !important;
      padding-left: 26px;
      padding-right: 20px; }

.alertModal .modal-content {
  border-radius: 0;
  border: 0;
  border-bottom: 10px solid #fff;
  background: #ef4130; }

.alertModal .modal-header {
  border-bottom: 0;
  padding: 0; }
  .alertModal .modal-header .close {
    font-style: italic;
    font-size: 15px;
    text-shadow: none;
    opacity: 1;
    margin: 10px 15px 10px auto;
    padding: 0;
    color: #fff; }
    .alertModal .modal-header .close:hover {
      color: #fff; }

.alertModal .modal-body {
  color: #fff;
  font-family: "Montserrat", sans-serif;
  font-weight: "Montserrat", sans-serif;
  padding: 0 25px 10px; }
  .alertModal .modal-body h5 {
    font-family: "Montserrat", sans-serif;
    font-weight: 700;
    font-size: 22px;
    text-transform: uppercase;
    margin-bottom: 15px;
    line-height: 1.2; }
    @media (min-width: 992px) {
      .alertModal .modal-body h5 {
        font-size: 30px; } }

.newsletter-form--wrapper {
  position: relative; }
  .newsletter-form--wrapper form {
    margin-top: 20px; }
  .newsletter-form--wrapper .form-newsletter {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    background: #fff; }
    .newsletter-form--wrapper .form-newsletter .wpcf7-email {
      background: transparent;
      font-family: "Montserrat", sans-serif;
      font-size: 12px;
      font-weight: 600;
      color: #06204c;
      border: 0;
      border-radius: 0;
      padding: 12px 12px 12px 17px;
      margin: 0;
      width: 100%; }
      @media (min-width: 768px) {
        .newsletter-form--wrapper .form-newsletter .wpcf7-email {
          font-size: 14px;
          padding: 16px 16px 16px 24px; } }
      @media (min-width: 992px) and (max-width: 1199.98px) {
        .newsletter-form--wrapper .form-newsletter .wpcf7-email {
          padding: 12px 12px 12px 17px;
          font-size: 12px; } }
      .newsletter-form--wrapper .form-newsletter .wpcf7-email::-webkit-input-placeholder {
        color: inherit; }
      .newsletter-form--wrapper .form-newsletter .wpcf7-email::-moz-placeholder {
        color: inherit; }
      .newsletter-form--wrapper .form-newsletter .wpcf7-email:-ms-input-placeholder {
        color: inherit; }
      .newsletter-form--wrapper .form-newsletter .wpcf7-email:-moz-placeholder {
        color: inherit; }
      .newsletter-form--wrapper .form-newsletter .wpcf7-email:focus {
        outline: 0; }
      .newsletter-form--wrapper .form-newsletter .wpcf7-email.wpcf7-not-valid {
        color: red; }
      .newsletter-form--wrapper .form-newsletter .wpcf7-email + .wpcf7-not-valid-tip {
        display: none; }
    .newsletter-form--wrapper .form-newsletter .wpcf7-submit {
      background-size: 40px auto;
      background-position: center center;
      padding: 12px 10px;
      font-size: 11px;
      border-radius: 0;
      margin-left: auto;
      min-width: 95px; }
      @media (min-width: 768px) {
        .newsletter-form--wrapper .form-newsletter .wpcf7-submit {
          font-size: 15px;
          padding: 15px; } }
      @media (min-width: 992px) and (max-width: 1199.98px) {
        .newsletter-form--wrapper .form-newsletter .wpcf7-submit {
          font-size: 11px; } }
      .newsletter-form--wrapper .form-newsletter .wpcf7-submit.loading {
        background: #0787e6 url(../images/loader.gif) no-repeat;
        background-size: 40px auto;
        background-position: center center;
        pointer-events: none;
        text-indent: -9999px; }
    .newsletter-form--wrapper .form-newsletter .ajax-loader {
      display: none !important; }
    .newsletter-form--wrapper .form-newsletter + .wpcf7-response-output {
      display: none !important; }

.modalNewsletter .modal-dialog {
  max-width: 550px; }

.modalNewsletter .modal-content {
  border-radius: 0;
  border: 0;
  padding: 0 15px; }
  @media (min-width: 992px) {
    .modalNewsletter .modal-content {
      padding: 0 68px; } }

.modalNewsletter .modal-header {
  text-align: center;
  display: block;
  padding-top: 40px;
  padding-bottom: 0;
  border: 0; }
  .modalNewsletter .modal-header h3 {
    display: inline-block; }
  .modalNewsletter .modal-header p {
    color: #06204c; }

.modalNewsletter .modal-body {
  padding-top: 0; }

.post-navigation__menu-wrapper {
  background-color: #ebeef1;
  display: flex;
  justify-content: center;
  flex-direction: row;
  list-style-type: none;
  margin-bottom: 0; }
  .post-navigation__menu-wrapper li {
    margin: 0 3px; }
    .post-navigation__menu-wrapper li.active a {
      color: #fff;
      background-color: #06204c; }
    .post-navigation__menu-wrapper li a {
      padding: 9px 16px; }

@media (max-width: 1199.98px) {
  .program-sidebar-sticky {
    position: sticky;
    top: -20px;
    background-color: #fff;
    z-index: 1;
    padding-bottom: 5px; } }

@media (min-width: 992px) {
  .program-sidebar-sticky:before {
    content: '';
    position: absolute;
    width: 50vw;
    height: 100%;
    top: 0;
    right: -30px;
    z-index: -1;
    background-color: #ebeef1; } }

.programmeDetails__sidebar {
  position: relative;
  padding-top: 30px; }
  @media (min-width: 992px) {
    .programmeDetails__sidebar {
      padding-top: 65px; } }
  .programmeDetails__sidebar--title {
    font-family: "Montserrat", sans-serif;
    font-size: 15px;
    font-weight: 700;
    line-height: 1.2;
    color: #06204c;
    margin-bottom: 15px;
    text-transform: uppercase; }
  .programmeDetails__sidebar--button {
    font-size: 12px;
    padding: 12px 15px 12px; }
  .programmeDetails__sidebar--nav {
    padding: 0;
    margin: 0 0 80px 0;
    list-style: none;
    width: 100%; }
    @media (min-width: 992px) {
      .programmeDetails__sidebar--nav {
        display: block;
        position: relative !important;
        float: none;
        background: transparent;
        border: 0;
        width: auto;
        transform: none !important; } }
    @media (min-width: 992px) {
      .programmeDetails__sidebar--nav li {
        margin-bottom: 15px; } }
    .programmeDetails__sidebar--nav li a {
      font-family: "Montserrat", sans-serif;
      font-size: 15px;
      font-weight: 400;
      line-height: 1.2;
      color: #06204c;
      padding: 8px 15px; }
      @media (min-width: 992px) {
        .programmeDetails__sidebar--nav li a {
          white-space: initial;
          padding: 0;
          display: inline-block;
          width: auto; } }
      .programmeDetails__sidebar--nav li a:before {
        content: '—';
        padding-right: 5px;
        display: none; }
      .programmeDetails__sidebar--nav li a:hover, .programmeDetails__sidebar--nav li a:focus {
        background: #0787e6;
        color: #fff; }
        @media (min-width: 992px) {
          .programmeDetails__sidebar--nav li a:hover, .programmeDetails__sidebar--nav li a:focus {
            background: transparent;
            color: #06204c; } }
      .programmeDetails__sidebar--nav li a.active {
        font-weight: 700;
        color: #fff; }
        @media (min-width: 992px) {
          .programmeDetails__sidebar--nav li a.active {
            color: #0787e6;
            background: transparent; }
            .programmeDetails__sidebar--nav li a.active:before {
              display: inline-block; } }
  .programmeDetails__sidebar--actions h3 {
    font-family: "Montserrat", sans-serif;
    font-size: 15px;
    font-weight: 500;
    line-height: 1.2;
    color: #06204c;
    margin-bottom: 25px; }
  .programmeDetails__sidebar--actions .primary-button, .programmeDetails__sidebar--actions .aide_admission__wrapper a, .aide_admission__wrapper .programmeDetails__sidebar--actions a, .programmeDetails__sidebar--actions .aide_demarche__wrapper a, .aide_demarche__wrapper .programmeDetails__sidebar--actions a, .programmeDetails__sidebar--actions .aide_financiere__wrapper a, .aide_financiere__wrapper .programmeDetails__sidebar--actions a, .programmeDetails__sidebar--actions .page-banner-home__content a, .page-banner-home__content .programmeDetails__sidebar--actions a, .programmeDetails__sidebar--actions .post-navigation__menu-wrapper li a, .post-navigation__menu-wrapper li .programmeDetails__sidebar--actions a, .programmeDetails__sidebar--actions body.page-template-template-Admission .boite_sidebar__wysiwyg a, body.page-template-template-Admission .boite_sidebar__wysiwyg .programmeDetails__sidebar--actions a, .programmeDetails__sidebar--actions body.home .about .wysiwyg a, body.home .about .wysiwyg .programmeDetails__sidebar--actions a, .programmeDetails__sidebar--actions body.home .about .wysiwyg-blue-box a, body.home .about .wysiwyg-blue-box .programmeDetails__sidebar--actions a {
    margin-bottom: 20px; }
  .programmeDetails__sidebar .article-share__wrapper {
    margin-bottom: 5px; }
    @media (min-width: 768px) {
      .programmeDetails__sidebar .article-share__wrapper {
        margin-bottom: 0; } }
    @media (min-width: 992px) {
      .programmeDetails__sidebar .article-share__wrapper {
        margin-bottom: 50px; } }

.filterAccordion .card {
  background: transparent;
  border: 0; }

.filterAccordion .card-header, .filterAccordion .card-body {
  bottom: 0;
  padding: 0;
  margin: 0;
  background: transparent;
  border: 0; }

.filterAccordion .card-header .primary-button:focus, .filterAccordion .card-header .aide_admission__wrapper a:focus, .aide_admission__wrapper .filterAccordion .card-header a:focus, .filterAccordion .card-header .aide_demarche__wrapper a:focus, .aide_demarche__wrapper .filterAccordion .card-header a:focus, .filterAccordion .card-header .aide_financiere__wrapper a:focus, .aide_financiere__wrapper .filterAccordion .card-header a:focus, .filterAccordion .card-header .page-banner-home__content a:focus, .page-banner-home__content .filterAccordion .card-header a:focus, .filterAccordion .card-header .post-navigation__menu-wrapper li a:focus, .post-navigation__menu-wrapper li .filterAccordion .card-header a:focus, .filterAccordion .card-header body.page-template-template-Admission .boite_sidebar__wysiwyg a:focus, body.page-template-template-Admission .boite_sidebar__wysiwyg .filterAccordion .card-header a:focus, .filterAccordion .card-header body.home .about .wysiwyg a:focus, body.home .about .wysiwyg .filterAccordion .card-header a:focus, .filterAccordion .card-header body.home .about .wysiwyg-blue-box a:focus, body.home .about .wysiwyg-blue-box .filterAccordion .card-header a:focus {
  background-color: #0787e6; }

.filterAccordion .card-header .primary-button i, .filterAccordion .card-header .aide_admission__wrapper a i, .aide_admission__wrapper .filterAccordion .card-header a i, .filterAccordion .card-header .aide_demarche__wrapper a i, .aide_demarche__wrapper .filterAccordion .card-header a i, .filterAccordion .card-header .aide_financiere__wrapper a i, .aide_financiere__wrapper .filterAccordion .card-header a i, .filterAccordion .card-header .page-banner-home__content a i, .page-banner-home__content .filterAccordion .card-header a i, .filterAccordion .card-header .post-navigation__menu-wrapper li a i, .post-navigation__menu-wrapper li .filterAccordion .card-header a i, .filterAccordion .card-header body.page-template-template-Admission .boite_sidebar__wysiwyg a i, body.page-template-template-Admission .boite_sidebar__wysiwyg .filterAccordion .card-header a i, .filterAccordion .card-header body.home .about .wysiwyg a i, body.home .about .wysiwyg .filterAccordion .card-header a i, .filterAccordion .card-header body.home .about .wysiwyg-blue-box a i, body.home .about .wysiwyg-blue-box .filterAccordion .card-header a i {
  font-size: 18px;
  padding-right: 10px; }

@media (min-width: 768px) {
  .filterAccordion .collapse {
    display: block !important; } }

.programmeFilters__wrapper {
  display: flex;
  padding: 20px;
  background-color: #0787e6;
  margin-bottom: 30px;
  flex-wrap: wrap; }
  @media (min-width: 768px) {
    .programmeFilters__wrapper {
      flex-wrap: nowrap; } }

.programmeFilters__title {
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
  font-weight: 700;
  line-height: 1.2;
  color: #06204c;
  text-transform: uppercase;
  margin-bottom: 10px; }

.programmeFilters__single {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 0 10px 10px; }
  @media (min-width: 768px) {
    .programmeFilters__single {
      width: calc(33% - 20px);
      margin-bottom: 0; } }
  .programmeFilters__single--title {
    font-family: "Open Sans", sans-serif;
    font-size: 14px;
    font-weight: 700;
    line-height: 1.2;
    color: #fff;
    margin-bottom: 10px; }

.programmeFilters__button {
  outline: 0;
  background: transparent;
  border: 0;
  cursor: pointer;
  text-align: left;
  background: #fff;
  font-family: "Open Sans", sans-serif;
  font-size: 12px;
  line-height: 1.2;
  color: #06204c;
  padding: 10px 35px 10px 10px;
  position: relative;
  overflow: hidden;
  white-space: nowrap;
  width: 100%;
  height: 35px; }
  .programmeFilters__button::after {
    content: "";
    font-family: "teccart" !important;
    font-size: 15px;
    color: #0787e6;
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    border: 0;
    width: auto;
    height: auto;
    margin: 0;
    z-index: 2; }
  .programmeFilters__button::before {
    content: '';
    height: 100%;
    width: 35px;
    background: -moz-linear-gradient(left, transparent 0%, rgba(255, 255, 255, 0.3) 7%, white 23%, white 100%);
    /* FF3.6-15 */
    background: -webkit-linear-gradient(left, transparent 0%, rgba(255, 255, 255, 0.3) 7%, white 23%, white 100%);
    /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to right, transparent 0%, rgba(255, 255, 255, 0.3) 7%, white 23%, white 100%);
    /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#ffffff',GradientType=1 );
    /* IE6-9 */
    position: absolute;
    right: 0;
    top: 0;
    z-index: 1; }

.programmeFilters__dropdown {
  margin: 0;
  border: 0;
  border-radius: 0;
  padding: 0;
  width: 100%;
  box-shadow: 0 13px 26px -9px rgba(0, 0, 0, 0.4); }
  .programmeFilters__dropdown ul {
    list-style-type: none;
    padding: 0;
    margin: 0; }
    .programmeFilters__dropdown ul li a {
      display: block;
      font-family: "Open Sans", sans-serif;
      font-size: 12px;
      line-height: 1.2;
      color: #06204c;
      padding: 7px 10px; }
      .programmeFilters__dropdown ul li a:hover {
        background-color: #ebeef1;
        text-decoration: none; }

.programmeListing__sidebar > * {
  margin-bottom: 33px;
  display: block; }

.responsive-menu__toggle {
  outline: 0;
  background: transparent;
  border: 0;
  cursor: pointer;
  color: #fff;
  font-size: 23px; }
  @media (min-width: 576px) {
    .responsive-menu__toggle {
      font-size: 26px; } }

.responsive-menu__wrapper .modal-dialog {
  margin: 0 auto;
  height: 100vh;
  background-color: #06204c;
  max-width: none; }

.responsive-menu__wrapper .modal-content {
  background-color: #06204c;
  border: 0; }

.responsive-menu__wrapper .modal-header {
  border: 0;
  padding: 20px 15px 0; }
  .responsive-menu__wrapper .modal-header .logo {
    max-width: 180px;
    width: 100%; }
    @media (min-width: 992px) {
      .responsive-menu__wrapper .modal-header .logo {
        max-width: 265px; } }

.responsive-menu__wrapper .modal-body {
  border: 0;
  padding: 30px 0 0 0;
  margin: 0; }

.responsive-menu__wrapper .close {
  color: #fff;
  text-shadow: none;
  font-style: normal;
  opacity: 1 !important;
  padding: 0;
  margin: 0 0 0 auto; }
  .responsive-menu__wrapper .close:hover {
    color: #fff !important; }

.services__sidebar {
  margin-top: 70px;
  margin-bottom: 115px;
  display: block; }
  .services__sidebar > * {
    margin-bottom: 33px;
    display: block; }

.programmeTeaser__wrapper {
  background-color: #fff;
  position: relative;
  overflow: hidden;
  padding: 20px 30px 20px 15px;
  margin-bottom: 30px;
  display: flex;
  flex-direction: column; }
  @media (min-width: 992px) {
    .programmeTeaser__wrapper {
      padding: 32px 180px 50px 29px; } }
  .programmeTeaser__wrapper:before {
    content: '';
    width: 165px;
    height: 100%;
    position: absolute;
    top: 0;
    right: 10px;
    transform: translateX(165px);
    transition: transform ease .3s;
    background-color: #0787e6; }
  @media (min-width: 1200px) {
    .programmeTeaser__wrapper:hover::before {
      transform: translateX(10px); }
    .programmeTeaser__wrapper:hover .programmeTeaser__infos {
      color: #fff; }
      .programmeTeaser__wrapper:hover .programmeTeaser__infos .details-link {
        color: #fff; } }
  .programmeTeaser__wrapper.mtl:before {
    background-color: #F78505; }
  .programmeTeaser__wrapper.mtl .programmeTeaser__type {
    color: #F78505; }
  .programmeTeaser__wrapper.south-shore:before {
    background-color: #C61C78; }
  .programmeTeaser__wrapper.south-shore .programmeTeaser__type {
    color: #C61C78; }

.programmeTeaser__type {
  font-family: "Montserrat", sans-serif;
  font-size: 12px;
  font-weight: 700;
  line-height: 1.2;
  color: #0787e6;
  margin-bottom: 5px; }
  @media (min-width: 992px) {
    .programmeTeaser__type {
      font-size: 15px; } }

.programmeTeaser__title {
  font-family: "Montserrat", sans-serif;
  font-size: 15px;
  font-weight: 700;
  line-height: 1.2;
  color: #06204c;
  margin-bottom: 15px;
  display: inline-block; }
  @media (min-width: 992px) {
    .programmeTeaser__title {
      font-size: 24px; } }
  .programmeTeaser__title:hover {
    color: #06204c; }

.programmeTeaser__infos {
  transition: color ease .2s;
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  line-height: 1.2;
  color: #06204c;
  order: 2;
  margin-top: 15px; }
  @media (min-width: 992px) {
    .programmeTeaser__infos {
      width: 150px;
      position: absolute;
      top: 50%;
      right: 33px;
      transform: translateY(-50%);
      text-align: right;
      margin-top: 0; } }
  .programmeTeaser__infos .duration {
    padding-bottom: 5px; }
  .programmeTeaser__infos .campus {
    margin-bottom: 20px; }
    @media (min-width: 992px) {
      .programmeTeaser__infos .campus {
        margin-bottom: 35px; } }
  .programmeTeaser__infos .details-link {
    color: transparent;
    transition: color ease .2s;
    position: relative;
    padding-right: 10px;
    font-family: "Montserrat", sans-serif;
    font-size: 14px;
    font-weight: 700;
    line-height: 1.2; }
    .programmeTeaser__infos .details-link.mtl {
      color: #F78505; }
    .programmeTeaser__infos .details-link.south-shore {
      color: #C61C78; }
    .programmeTeaser__infos .details-link:before {
      content: "";
      font-family: "teccart" !important;
      position: absolute;
      top: 50%;
      left: 100%;
      transform: translateY(-50%); }

.programmeTeaser__description {
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  color: #06204c; }

.listing-cards {
  background: #ebeef1;
  padding: 50px 0 70px; }
  .listing-cards .card-columns {
    column-gap: 30px; }

.tile-news__wrapper {
  background-color: #fff;
  border: 0;
  position: relative;
  display: block;
  text-decoration: none;
  padding-bottom: 0 !important;
  margin-bottom: 30px !important;
  overflow: hidden;
  border-radius: 0;
  width: 100%; }
  @media (min-width: 576px) {
    .tile-news__wrapper {
      padding-bottom: 31px !important; } }
  @media (min-width: 1200px) {
    .tile-news__wrapper {
      padding-bottom: 21px !important; } }
  .tile-news__wrapper:hover {
    text-decoration: none; }
    @media (min-width: 1200px) {
      .tile-news__wrapper:hover .primary-button, .tile-news__wrapper:hover .aide_admission__wrapper a, .aide_admission__wrapper .tile-news__wrapper:hover a, .tile-news__wrapper:hover .aide_demarche__wrapper a, .aide_demarche__wrapper .tile-news__wrapper:hover a, .tile-news__wrapper:hover .aide_financiere__wrapper a, .aide_financiere__wrapper .tile-news__wrapper:hover a, .tile-news__wrapper:hover .page-banner-home__content a, .page-banner-home__content .tile-news__wrapper:hover a, .tile-news__wrapper:hover .post-navigation__menu-wrapper li a, .post-navigation__menu-wrapper li .tile-news__wrapper:hover a, .tile-news__wrapper:hover body.page-template-template-Admission .boite_sidebar__wysiwyg a, body.page-template-template-Admission .boite_sidebar__wysiwyg .tile-news__wrapper:hover a, .tile-news__wrapper:hover body.home .about .wysiwyg a, body.home .about .wysiwyg .tile-news__wrapper:hover a, .tile-news__wrapper:hover body.home .about .wysiwyg-blue-box a, body.home .about .wysiwyg-blue-box .tile-news__wrapper:hover a {
        opacity: 1; } }
  .tile-news__wrapper .card-body {
    padding: 30px; }
    @media (min-width: 768px) {
      .tile-news__wrapper .card-body {
        padding: 20px; } }
    @media (min-width: 1200px) {
      .tile-news__wrapper .card-body {
        padding: 30px; } }
  .tile-news__wrapper .primary-button, .tile-news__wrapper .aide_admission__wrapper a, .aide_admission__wrapper .tile-news__wrapper a, .tile-news__wrapper .aide_demarche__wrapper a, .aide_demarche__wrapper .tile-news__wrapper a, .tile-news__wrapper .aide_financiere__wrapper a, .aide_financiere__wrapper .tile-news__wrapper a, .tile-news__wrapper .page-banner-home__content a, .page-banner-home__content .tile-news__wrapper a, .tile-news__wrapper .post-navigation__menu-wrapper li a, .post-navigation__menu-wrapper li .tile-news__wrapper a, .tile-news__wrapper body.page-template-template-Admission .boite_sidebar__wysiwyg a, body.page-template-template-Admission .boite_sidebar__wysiwyg .tile-news__wrapper a, .tile-news__wrapper body.home .about .wysiwyg a, body.home .about .wysiwyg .tile-news__wrapper a, .tile-news__wrapper body.home .about .wysiwyg-blue-box a, body.home .about .wysiwyg-blue-box .tile-news__wrapper a {
    padding: 10px 30px;
    text-align: left;
    transition: all ease .3s; }
    @media (min-width: 576px) {
      .tile-news__wrapper .primary-button, .tile-news__wrapper .aide_admission__wrapper a, .aide_admission__wrapper .tile-news__wrapper a, .tile-news__wrapper .aide_demarche__wrapper a, .aide_demarche__wrapper .tile-news__wrapper a, .tile-news__wrapper .aide_financiere__wrapper a, .aide_financiere__wrapper .tile-news__wrapper a, .tile-news__wrapper .page-banner-home__content a, .page-banner-home__content .tile-news__wrapper a, .tile-news__wrapper .post-navigation__menu-wrapper li a, .post-navigation__menu-wrapper li .tile-news__wrapper a, .tile-news__wrapper body.page-template-template-Admission .boite_sidebar__wysiwyg a, body.page-template-template-Admission .boite_sidebar__wysiwyg .tile-news__wrapper a, .tile-news__wrapper body.home .about .wysiwyg a, body.home .about .wysiwyg .tile-news__wrapper a, .tile-news__wrapper body.home .about .wysiwyg-blue-box a, body.home .about .wysiwyg-blue-box .tile-news__wrapper a {
        position: absolute;
        bottom: 0; } }
    @media (min-width: 1200px) {
      .tile-news__wrapper .primary-button, .tile-news__wrapper .aide_admission__wrapper a, .aide_admission__wrapper .tile-news__wrapper a, .tile-news__wrapper .aide_demarche__wrapper a, .aide_demarche__wrapper .tile-news__wrapper a, .tile-news__wrapper .aide_financiere__wrapper a, .aide_financiere__wrapper .tile-news__wrapper a, .tile-news__wrapper .page-banner-home__content a, .page-banner-home__content .tile-news__wrapper a, .tile-news__wrapper .post-navigation__menu-wrapper li a, .post-navigation__menu-wrapper li .tile-news__wrapper a, .tile-news__wrapper body.page-template-template-Admission .boite_sidebar__wysiwyg a, body.page-template-template-Admission .boite_sidebar__wysiwyg .tile-news__wrapper a, .tile-news__wrapper body.home .about .wysiwyg a, body.home .about .wysiwyg .tile-news__wrapper a, .tile-news__wrapper body.home .about .wysiwyg-blue-box a, body.home .about .wysiwyg-blue-box .tile-news__wrapper a {
        opacity: 0; } }

.tile-news__image {
  display: block; }

.tile-news__date {
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
  font-weight: 600;
  line-height: 1.2;
  color: #0787e6;
  margin-bottom: 10px; }
  @media (min-width: 768px) {
    .tile-news__date {
      font-size: 13px;
      margin-bottom: 15px; } }
  @media (min-width: 1200px) {
    .tile-news__date {
      font-size: 14px;
      margin-bottom: 10px; } }

.tile-news__title {
  font-family: "Montserrat", sans-serif;
  font-size: 18px;
  font-weight: 700;
  line-height: 1.2;
  color: #06204c;
  margin-bottom: 20px;
  display: inline-block; }
  @media (min-width: 768px) {
    .tile-news__title {
      font-size: 15px;
      margin-bottom: 10px; } }
  @media (min-width: 1200px) {
    .tile-news__title {
      font-size: 18px;
      margin-bottom: 20px; } }
  .tile-news__title:hover {
    color: #06204c; }

.tile-news__text {
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.2;
  color: #06204c; }

.alignnone {
  margin-left: 0;
  margin-right: 0;
  max-width: 100%;
  height: auto; }

.aligncenter {
  display: block;
  margin: 0.5rem auto;
  height: auto; }

.alignleft,
.alignright {
  margin-bottom: 0.5rem;
  height: auto; }

@media (min-width: 576px) {
  .alignleft {
    float: left;
    margin-right: 0.5rem; }
  .alignright {
    float: right;
    margin-left: 0.5rem; } }

body.page-template-template-custom .page-content {
  padding: 60px 0; }
  @media (min-width: 576px) {
    body.page-template-template-custom .page-content {
      padding: 90px 0 150px; } }

body.page-template-template-content_et_menu .content__wrapper {
  padding: 30px 0; }
  @media (min-width: 992px) {
    body.page-template-template-content_et_menu .content__wrapper {
      padding: 65px 0; } }
  @media (min-width: 1200px) {
    body.page-template-template-content_et_menu .content__wrapper {
      padding: 65px 15px 65px 4vw; } }

header.banner {
  position: absolute;
  width: 100%;
  top: 0;
  z-index: 1; }
  header.banner .brand {
    margin-top: 10px;
    margin-bottom: 19px; }
  header.banner .top-bar .nav-top {
    padding: 6px 0 7px;
    justify-content: flex-end; }
    header.banner .top-bar .nav-top a {
      font-family: "Montserrat", sans-serif;
      font-weight: 500;
      font-size: 11px;
      text-transform: uppercase;
      color: #FFFFFF;
      letter-spacing: -0.1; }
      header.banner .top-bar .nav-top a:hover {
        color: #0787e6;
        text-decoration: none; }
  header.banner .top-bar .nav-top-primary li {
    padding-right: 14px; }
    @media (min-width: 1200px) {
      header.banner .top-bar .nav-top-primary li {
        padding-right: 20px; } }
    header.banner .top-bar .nav-top-primary li:last-child {
      padding-right: 0; }

.nav-primary__wrapper > *, .nav-primary__menu > * {
  margin-right: 25px; }
  @media (min-width: 1200px) {
    .nav-primary__wrapper > *, .nav-primary__menu > * {
      margin-right: 45px; } }
  .nav-primary__wrapper > *:last-child, .nav-primary__menu > *:last-child {
    margin-right: 0; }

.nav-primary__wrapper {
  display: flex;
  justify-content: flex-end;
  position: relative;
  margin-top: 14px; }
  @media (min-width: 1200px) {
    .nav-primary__wrapper {
      margin-top: 24px; } }
  .nav-primary__wrapper .dropdown-toggle, .nav-primary__wrapper .nav-link {
    outline: 0;
    background: transparent;
    border: 0;
    cursor: pointer;
    font-family: "Montserrat", sans-serif;
    font-weight: 600;
    font-size: 15px;
    color: #FFFFFF;
    padding: 0 0 10px 0; }
  .nav-primary__wrapper .dropdown-toggle {
    position: relative;
    direction: block; }
    .nav-primary__wrapper .dropdown-toggle:before {
      content: "";
      display: block;
      width: 9px;
      height: 5px;
      background: transparent url("../images/arrow.png") left top no-repeat;
      background-size: contain;
      position: absolute;
      left: 50%;
      bottom: 0;
      transform: translateX(-50%);
      transition: all ease .3s; }
    .nav-primary__wrapper .dropdown-toggle:after {
      content: " ";
      top: 0;
      left: 50%;
      border: solid transparent;
      height: 0;
      width: 0;
      position: absolute;
      pointer-events: none;
      border-color: rgba(136, 183, 213, 0);
      border-bottom-color: #fff;
      border-width: 30px;
      margin-left: -30px;
      display: none; }
    .nav-primary__wrapper .dropdown-toggle:hover:before {
      transform: translateX(-50%) translateY(5px); }
    .nav-primary__wrapper .dropdown-toggle[aria-expanded="true"]:before {
      display: none; }
    .nav-primary__wrapper .dropdown-toggle[aria-expanded="true"]:after {
      display: block; }
  .nav-primary__wrapper .dropdown-menu {
    top: 40px !important;
    right: 0 !important;
    left: auto !important;
    transform: translateX(0) !important;
    border: 0;
    border-radius: 0;
    margin: 0;
    padding: 28px 39px; }
    .nav-primary__wrapper .dropdown-menu a {
      display: inline-block;
      font-family: "Open Sans", sans-serif;
      font-size: 12px;
      line-height: 1.2;
      color: #06204c;
      margin: 3px 0;
      padding: 0; }
      .nav-primary__wrapper .dropdown-menu a:active, .nav-primary__wrapper .dropdown-menu a:hover {
        background-color: transparent; }
      .nav-primary__wrapper .dropdown-menu a.col-title {
        font-family: "Montserrat", sans-serif;
        font-weight: 700;
        text-transform: uppercase;
        text-decoration: none;
        color: #0787e6; }
    .nav-primary__wrapper .dropdown-menu .sub-menu {
      padding: 0;
      list-style-type: none;
      padding-right: 20px;
      padding-top: 5px; }
      .nav-primary__wrapper .dropdown-menu .sub-menu > .menu-item {
        padding: 5px 0; }
    .nav-primary__wrapper .dropdown-menu .menu-item {
      padding: 5px 0;
      line-height: 1.2; }
      .nav-primary__wrapper .dropdown-menu .menu-item.no-title > a {
        display: none; }
      .nav-primary__wrapper .dropdown-menu .menu-item.no-title > .sub-menu {
        padding-top: 0;
        margin-top: -5px; }
      .nav-primary__wrapper .dropdown-menu .menu-item.col-title > a {
        font-family: "Montserrat", sans-serif;
        font-weight: 700;
        text-transform: uppercase;
        text-decoration: none; }
      .nav-primary__wrapper .dropdown-menu .menu-item.col-title.blue > a {
        color: #0787e6; }
      .nav-primary__wrapper .dropdown-menu .menu-item.col-title.orange > a {
        color: #F78505; }
      .nav-primary__wrapper .dropdown-menu .menu-item.col-title.red > a {
        color: #C61C78; }
      .nav-primary__wrapper .dropdown-menu .menu-item.col-title.hide {
        padding-top: 0; }
        .nav-primary__wrapper .dropdown-menu .menu-item.col-title.hide > a {
          display: none; }
        .nav-primary__wrapper .dropdown-menu .menu-item.col-title.hide > .sub-menu {
          padding-top: 0; }
      .nav-primary__wrapper .dropdown-menu .menu-item.bold > a {
        font-weight: 700;
        text-decoration: underline; }
    .nav-primary__wrapper .dropdown-menu .discover_navigation {
      flex-wrap: nowrap;
      margin-right: 180px; }
      .nav-primary__wrapper .dropdown-menu .discover_navigation > li {
        width: 180px; }
    .nav-primary__wrapper .dropdown-menu .programmes_navigation {
      flex-wrap: nowrap;
      margin-right: 180px; }
      .nav-primary__wrapper .dropdown-menu .programmes_navigation > li {
        width: 220px; }
    .nav-primary__wrapper .dropdown-menu .etudiant_navigation__wrapper {
      flex-wrap: nowrap;
      padding: 0;
      list-style-type: none;
      flex-direction: row;
      display: flex; }
      .nav-primary__wrapper .dropdown-menu .etudiant_navigation__wrapper > li {
        width: 220px; }
    .nav-primary__wrapper .dropdown-menu .documents_navigation {
      padding: 0;
      list-style-type: none; }
      .nav-primary__wrapper .dropdown-menu .documents_navigation a {
        font-weight: 700;
        text-decoration: underline; }

footer.content-info .more-infos {
  background-color: #f2f2f2;
  color: #06204c; }
  footer.content-info .more-infos__bg {
    background-position: top center;
    background-size: auto 34vw;
    background-repeat: no-repeat;
    padding-top: 34vw; }
    @media (min-width: 992px) {
      footer.content-info .more-infos__bg {
        background-position: top right;
        background-size: auto 100%;
        padding-top: 0; } }
  footer.content-info .more-infos__content {
    padding: 30px 0; }
    @media (min-width: 992px) {
      footer.content-info .more-infos__content {
        margin-right: auto;
        padding: 40px 0;
        width: 33%; } }
    @media (min-width: 1200px) {
      footer.content-info .more-infos__content {
        padding: 95px 0; } }
  footer.content-info .more-infos .actions-text {
    font-family: "Montserrat", sans-serif;
    font-weight: 300;
    font-size: 15px;
    line-height: 1.2; }
    @media (min-width: 992px) {
      footer.content-info .more-infos .actions-text {
        font-size: 18px; } }
    footer.content-info .more-infos .actions-text p {
      margin-bottom: 10px; }
    footer.content-info .more-infos .actions-text strong {
      display: block;
      font-weight: 700;
      font-size: 17px;
      margin-bottom: 17px; }
      @media (min-width: 992px) {
        footer.content-info .more-infos .actions-text strong {
          font-size: 25px;
          margin-bottom: 12px; } }
    footer.content-info .more-infos .actions-text em {
      font-family: "Open Sans", sans-serif;
      font-style: normal;
      font-size: 0.875rem; }
  footer.content-info .more-infos .footer_actions {
    display: flex;
    flex-direction: column;
    padding-top: 25px; }
    @media (min-width: 768px) {
      footer.content-info .more-infos .footer_actions {
        flex-direction: row; } }
    @media (min-width: 992px) {
      footer.content-info .more-infos .footer_actions {
        display: block;
        padding-top: 10px; } }
    footer.content-info .more-infos .footer_actions a {
      display: block;
      width: 100%;
      margin: 5px 0; }
      @media (min-width: 768px) and (max-width: 991.98px) {
        footer.content-info .more-infos .footer_actions a {
          width: 50%;
          margin: 5px; } }
      @media (min-width: 992px) and (max-width: 1199.98px) {
        footer.content-info .more-infos .footer_actions a {
          width: 100%;
          margin: 5px; } }
      @media (min-width: 1200px) {
        footer.content-info .more-infos .footer_actions a {
          width: auto;
          display: inline-block;
          padding: 14px 19px; }
          footer.content-info .more-infos .footer_actions a:first-child {
            margin-right: 30px; }
          footer.content-info .more-infos .footer_actions a:before {
            display: none; } }

footer.content-info .footer-content {
  padding: 20px 0 35px;
  background-color: #06204c;
  color: #FFFFFF; }
  @media (min-width: 992px) {
    footer.content-info .footer-content {
      padding-top: 50px;
      padding-bottom: 20px; } }
  footer.content-info .footer-content .brand {
    margin-bottom: 20px; }
    @media (min-width: 992px) {
      footer.content-info .footer-content .brand {
        margin-bottom: 38px; } }
  footer.content-info .footer-content .tagline {
    margin: 10px 0 40px; }
    @media (min-width: 992px) {
      footer.content-info .footer-content .tagline {
        margin-bottom: 15px;
        padding-right: 60px; } }
    footer.content-info .footer-content .tagline h5 {
      font-family: "Montserrat", sans-serif;
      font-size: 15px;
      font-weight: 700;
      line-height: 1.2;
      color: #FFFFFF;
      text-transform: uppercase; }
      @media (min-width: 992px) {
        footer.content-info .footer-content .tagline h5 {
          font-size: 18px; } }
  footer.content-info .footer-content a {
    color: #fff; }
  footer.content-info .footer-content .campus {
    font-weight: 600;
    font-size: 12px;
    padding-bottom: 10px;
    text-align: center; }
    @media (min-width: 768px) {
      footer.content-info .footer-content .campus {
        text-align: left;
        font-size: 14px;
        padding-bottom: 20px; } }
    @media (min-width: 992px) {
      footer.content-info .footer-content .campus {
        padding-bottom: 80px;
        line-height: 1.5; } }
    footer.content-info .footer-content .campus h5 {
      font-family: "Montserrat", sans-serif;
      font-weight: 700;
      font-size: 13px;
      margin-bottom: 7px; }
      @media (min-width: 768px) {
        footer.content-info .footer-content .campus h5 {
          font-size: 15px; } }
    footer.content-info .footer-content .campus a {
      display: inline-block; }
      @media (min-width: 992px) {
        footer.content-info .footer-content .campus a {
          margin-top: 25px; } }
    footer.content-info .footer-content .campus.campus-mtl, footer.content-info .footer-content .campus .longueil {
      color: #F78505; }
      footer.content-info .footer-content .campus.campus-mtl a, footer.content-info .footer-content .campus .longueil a {
        color: #F78505; }
    footer.content-info .footer-content .campus.campus-south, footer.content-info .footer-content .campus .longueuil {
      color: #C61C78; }
      footer.content-info .footer-content .campus.campus-south a, footer.content-info .footer-content .campus .longueuil a {
        color: #C61C78; }
    footer.content-info .footer-content .campus.institut, footer.content-info .footer-content .campus .brossard {
      color: #0787e6; }
      footer.content-info .footer-content .campus.institut a, footer.content-info .footer-content .campus .brossard a {
        color: #0787e6; }
  footer.content-info .footer-content .socials p {
    font-family: "Montserrat", sans-serif;
    font-weight: 700;
    font-size: 1.125rem;
    line-height: 1.375rem;
    margin-bottom: 0.75rem; }
  footer.content-info .footer-content .socials ul {
    list-style: none;
    padding: 0;
    margin: 0; }
    footer.content-info .footer-content .socials ul li {
      display: inline-block; }
      footer.content-info .footer-content .socials ul li a {
        font-size: 27px;
        transition: opacity ease .3s;
        opacity: 1;
        margin-right: 17px;
        display: inline-block;
        padding: 2px; }
        footer.content-info .footer-content .socials ul li a:hover {
          text-decoration: none;
          opacity: .6; }
  footer.content-info .footer-content .phone, footer.content-info .footer-content .phone a {
    color: #fff;
    font-size: 13px;
    font-weight: 700;
    text-decoration: none;
    text-align: center; }
    @media (min-width: 768px) {
      footer.content-info .footer-content .phone, footer.content-info .footer-content .phone a {
        text-align: left; } }
    @media (min-width: 1200px) {
      footer.content-info .footer-content .phone, footer.content-info .footer-content .phone a {
        font-size: 15px; } }
    footer.content-info .footer-content .phone p, footer.content-info .footer-content .phone a p {
      margin: 0; }
  footer.content-info .footer-content .footer_navigation {
    flex-direction: column;
    padding: 30px 0 0; }
    @media (min-width: 768px) {
      footer.content-info .footer-content .footer_navigation {
        flex-direction: row;
        padding: 0; } }
    footer.content-info .footer-content .footer_navigation .menu-item {
      text-align: center; }
      @media (min-width: 768px) {
        footer.content-info .footer-content .footer_navigation .menu-item {
          padding-right: 20px;
          text-align: left; } }
      footer.content-info .footer-content .footer_navigation .menu-item a {
        color: #fff;
        font-size: 13px;
        font-weight: 600;
        text-decoration: none;
        text-transform: uppercase; }
  footer.content-info .footer-content .copyrights {
    width: 100%;
    padding-top: 30px;
    font-size: 10px;
    margin-top: 30px; }
    @media (min-width: 992px) {
      footer.content-info .footer-content .copyrights {
        margin-top: 50px;
        padding-top: 20px;
        font-size: 12px; } }
    footer.content-info .footer-content .copyrights .confidential {
      margin: 15px 0;
      display: inline-block; }
      @media (min-width: 768px) {
        footer.content-info .footer-content .copyrights .confidential {
          margin: 0; } }
      @media (min-width: 1200px) {
        footer.content-info .footer-content .copyrights .confidential {
          margin-left: 35px; } }

body#tinymce {
  margin: 12px !important; }

body.error404 .page-content {
  padding: 60px 0; }
  @media (min-width: 576px) {
    body.error404 .page-content {
      padding: 130px 0 200px; } }
  body.error404 .page-content h1 {
    font-size: 30px;
    color: #000;
    text-transform: uppercase;
    margin-top: 40px;
    margin-bottom: 15px; }
    @media (min-width: 768px) {
      body.error404 .page-content h1 {
        font-size: 40px;
        margin-top: 0; } }
  body.error404 .page-content .content-404 {
    text-align: center;
    color: #000;
    font-size: 13px; }
    @media (min-width: 768px) {
      body.error404 .page-content .content-404 {
        text-align: left; } }
    body.error404 .page-content .content-404 .primary-button, body.error404 .page-content .content-404 .aide_admission__wrapper a, .aide_admission__wrapper body.error404 .page-content .content-404 a, body.error404 .page-content .content-404 .aide_demarche__wrapper a, .aide_demarche__wrapper body.error404 .page-content .content-404 a, body.error404 .page-content .content-404 .aide_financiere__wrapper a, .aide_financiere__wrapper body.error404 .page-content .content-404 a, body.error404 .page-content .content-404 .page-banner-home__content a, .page-banner-home__content body.error404 .page-content .content-404 a, body.error404 .page-content .content-404 .post-navigation__menu-wrapper li a, .post-navigation__menu-wrapper li body.error404 .page-content .content-404 a, body.error404 .page-content .content-404 body.page-template-template-Admission .boite_sidebar__wysiwyg a, body.page-template-template-Admission .boite_sidebar__wysiwyg body.error404 .page-content .content-404 a, body.error404 .page-content .content-404 body.home .about .wysiwyg a, body.home .about .wysiwyg body.error404 .page-content .content-404 a, body.error404 .page-content .content-404 body.home .about .wysiwyg-blue-box a, body.home .about .wysiwyg-blue-box body.error404 .page-content .content-404 a {
      margin: 30px 0; }

body.page-template-template-activites .content__wrapper {
  padding: 30px 0; }
  @media (min-width: 992px) {
    body.page-template-template-activites .content__wrapper {
      padding: 65px 0; } }
  @media (min-width: 1200px) {
    body.page-template-template-activites .content__wrapper {
      padding: 65px 15px 65px 4vw; } }

body.page-template-template-activites .activity__single {
  padding: 20px 0; }
  body.page-template-template-activites .activity__single:first-child {
    padding-top: 0; }
  body.page-template-template-activites .activity__single:last-child {
    padding-bottom: 0; }
  body.page-template-template-activites .activity__single > .row {
    padding-bottom: 35px; }
    body.page-template-template-activites .activity__single > .row:first-child {
      padding-bottom: 15px; }
  body.page-template-template-activites .activity__single--title.mtl {
    color: #F78505; }
  body.page-template-template-activites .activity__single--title.south-shore {
    color: #C61C78; }
  body.page-template-template-activites .activity__single .activity-gallery__wrapper {
    position: relative; }
  body.page-template-template-activites .activity__single .activity-gallery__arrow {
    outline: 0;
    background: transparent;
    border: 0;
    cursor: pointer;
    width: 30px;
    height: 30px;
    position: absolute;
    bottom: 0;
    right: 0;
    color: #fff;
    background-color: #0787e6;
    transition: all ease 0.3s; }
    body.page-template-template-activites .activity__single .activity-gallery__arrow:hover {
      background-color: #1e9bf8; }
    body.page-template-template-activites .activity__single .activity-gallery__arrow i {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%); }
    body.page-template-template-activites .activity__single .activity-gallery__arrow.slick-prev {
      right: 30px;
      transform: rotate(180deg); }
  body.page-template-template-activites .activity__single .col-lg-6.wysiwyg, body.page-template-template-activites .activity__single .col-lg-6.wysiwyg-blue-box {
    padding-top: 15px; }
    @media (min-width: 992px) {
      body.page-template-template-activites .activity__single .col-lg-6.wysiwyg, body.page-template-template-activites .activity__single .col-lg-6.wysiwyg-blue-box {
        padding-top: 0; } }

body.page-template-template-Admission .admission__wrapper {
  padding-top: 30px;
  padding-bottom: 20px; }
  @media (min-width: 992px) {
    body.page-template-template-Admission .admission__wrapper {
      padding-top: 75px;
      padding-bottom: 75px; } }

body.page-template-template-Admission .boite_sidebar__wrapper {
  padding: 47px 42px 35px;
  background-color: #0787e6;
  color: #fff;
  margin-top: 75px; }

body.page-template-template-Admission .boite_sidebar__title {
  font-family: "Montserrat", sans-serif;
  font-size: 24px;
  font-weight: 700;
  line-height: 1.2;
  color: #fff;
  text-transform: uppercase;
  margin-bottom: 35px;
  padding-bottom: 15px; }
  @media (min-width: 992px) {
    body.page-template-template-Admission .boite_sidebar__title {
      margin-bottom: 46px;
      padding-bottom: 26px; } }
  body.page-template-template-Admission .boite_sidebar__title:before {
    content: '';
    position: relative;
    width: 60px;
    height: 3px;
    background-color: #007bff;
    position: absolute;
    top: 100%;
    left: 0; }
    @media (min-width: 992px) {
      body.page-template-template-Admission .boite_sidebar__title:before {
        width: 83px; } }

body.page-template-template-Admission .boite_sidebar__wysiwyg {
  color: #fff; }
  body.page-template-template-Admission .boite_sidebar__wysiwyg ul {
    list-style-type: none;
    padding: 0;
    margin: 0; }
    body.page-template-template-Admission .boite_sidebar__wysiwyg ul li {
      padding-left: 2.2em;
      position: relative;
      padding-bottom: 20px; }
      body.page-template-template-Admission .boite_sidebar__wysiwyg ul li:before {
        content: "";
        font-family: "teccart" !important;
        padding-right: 5px;
        color: #06204c;
        position: absolute;
        left: 0; }

body.page-template-template-agentInternationaux .agentInternationaux__wrapper {
  padding: 55px 0 80px; }
  body.page-template-template-agentInternationaux .agentInternationaux__wrapper .wpcf7-form {
    margin-top: 40px; }

.commentsyrendre__wrapper {
  padding: 30px 0; }
  @media (min-width: 992px) {
    .commentsyrendre__wrapper {
      padding: 65px 0; } }
  @media (min-width: 1200px) {
    .commentsyrendre__wrapper {
      padding: 65px 15px 65px 4vw; } }

.commentsyrendre__single {
  padding: 2vw 0 4vw; }
  .commentsyrendre__single--map {
    height: 400px;
    margin-bottom: 75px; }
  .commentsyrendre__single--mapLink {
    font-weight: 700;
    margin-top: 15px;
    margin-bottom: 45px;
    display: inline-block; }
  .commentsyrendre__single--transport {
    font-family: "Montserrat", sans-serif;
    font-size: 16px;
    font-weight: 700;
    line-height: 1.2;
    color: #06204c;
    padding: 25px 0; }
    .commentsyrendre__single--transport img {
      width: 22px;
      margin-right: 10px; }
  .commentsyrendre__single--infos a {
    font-weight: 700; }

body.page-template-template-Contact .contact-form__wrapper {
  padding-top: 25px;
  padding-bottom: 25px; }
  @media (min-width: 992px) {
    body.page-template-template-Contact .contact-form__wrapper {
      padding-top: 55px;
      padding-bottom: 55px; } }

body.page-template-template-Contact .contact-quote__wrapper {
  background-color: #0787e6;
  color: #fff;
  padding: 45px;
  font-size: 18px;
  margin-top: 30px; }
  @media (min-width: 992px) {
    body.page-template-template-Contact .contact-quote__wrapper {
      margin-top: 0; } }
  body.page-template-template-Contact .contact-quote__wrapper em {
    font-size: 14px; }

body.page-template-template-default .baseTemplate__wrapper {
  padding: 55px 0 80px; }

.detailsProgramme__wrapper {
  padding: 30px 15px; }
  @media (min-width: 992px) {
    .detailsProgramme__wrapper {
      padding: 65px 0; } }
  @media (min-width: 1200px) {
    .detailsProgramme__wrapper {
      padding: 65px 15px 65px 4vw; } }
  .detailsProgramme__wrapper.mtl .detailsProgramme__type {
    color: #F78505; }
  .detailsProgramme__wrapper.mtl .detailsProgramme__objectif {
    background-color: #F78505; }
  .detailsProgramme__wrapper.mtl .detailsProgramme__advantages--listing li::before {
    color: #F78505; }
  .detailsProgramme__wrapper.mtl .frais__header {
    background-color: #F78505; }
  .detailsProgramme__wrapper.mtl .faq-accordian__single .card-header .card-button:before {
    color: #F78505; }
  .detailsProgramme__wrapper.mtl .wysiwyg ul > li:before, .detailsProgramme__wrapper.mtl .wysiwyg-blue-box ul > li:before {
    color: #F78505; }
  .detailsProgramme__wrapper.south-shore .detailsProgramme__type {
    color: #C61C78; }
  .detailsProgramme__wrapper.south-shore .detailsProgramme__objectif {
    background-color: #C61C78; }
  .detailsProgramme__wrapper.south-shore .detailsProgramme__advantages--listing li::before {
    color: #C61C78; }
  .detailsProgramme__wrapper.south-shore .frais__header {
    background-color: #C61C78; }
  .detailsProgramme__wrapper.south-shore .faq-accordian__single .card-header .card-button:before {
    color: #C61C78; }
  .detailsProgramme__wrapper.south-shore .wysiwyg ul > li:before, .detailsProgramme__wrapper.south-shore .wysiwyg-blue-box ul > li:before {
    color: #C61C78; }
  .detailsProgramme__wrapper .wysiwyg, .detailsProgramme__wrapper .wysiwyg-blue-box {
    padding: 20px 0 40px; }
    .detailsProgramme__wrapper .wysiwyg h1, .detailsProgramme__wrapper .wysiwyg-blue-box h1 {
      font-size: 24px;
      text-transform: uppercase; }
    .detailsProgramme__wrapper .wysiwyg h2, .detailsProgramme__wrapper .wysiwyg-blue-box h2 {
      margin-bottom: 30px; }

.detailsProgramme__type {
  font-family: "Montserrat", sans-serif;
  font-size: 15px;
  font-weight: 700;
  line-height: 1.2;
  color: #0787e6;
  margin-bottom: 15px; }
  .detailsProgramme__type.mtl {
    color: #F78505; }
  .detailsProgramme__type.south-shore {
    color: #C61C78; }
  .detailsProgramme__type span {
    font-weight: 400; }
    .detailsProgramme__type span:before {
      content: '—';
      display: inline-block;
      padding-left: 20px;
      padding-right: 5px; }

.detailsProgramme__objectif {
  width: 100%;
  padding: 30px 15px;
  background-color: #0787e6; }
  @media (min-width: 992px) {
    .detailsProgramme__objectif {
      padding: 48px 30px; } }
  .detailsProgramme__objectif.mtl {
    background-color: #F78505; }
  .detailsProgramme__objectif.south-shore {
    background-color: #C61C78; }
  .detailsProgramme__objectif--title {
    font-family: "Montserrat", sans-serif;
    font-size: 15px;
    font-weight: 700;
    line-height: 1.2;
    color: #fff;
    margin-bottom: 10px; }
    @media (min-width: 992px) {
      .detailsProgramme__objectif--title {
        margin-bottom: 17px; } }
  .detailsProgramme__objectif--quote {
    font-family: "Montserrat", sans-serif;
    font-size: 18px;
    font-weight: 700;
    line-height: 1.2;
    color: #fff;
    letter-spacing: -0.1px; }
    @media (min-width: 992px) {
      .detailsProgramme__objectif--quote {
        font-size: 24px; } }

.detailsProgramme__advantages--title {
  font-family: "Montserrat", sans-serif;
  font-size: 15px;
  font-weight: 700;
  line-height: 1.2;
  color: #06204c;
  margin-bottom: 20px; }

.detailsProgramme__advantages--listing ul {
  list-style-type: none;
  padding: 0;
  margin: 0; }
  .detailsProgramme__advantages--listing ul li {
    padding-left: 2.2em;
    position: relative;
    padding-bottom: 12px; }
    .detailsProgramme__advantages--listing ul li:before {
      content: "";
      font-family: "teccart" !important;
      padding-right: 5px;
      color: #0787e6;
      position: absolute;
      left: 0; }

body.page-template-template-documents .documents__wrapper {
  padding: 55px 0 80px; }

body.page-template-template-documents .document-quote__wrapper {
  background-color: #0787e6;
  color: #fff;
  padding: 45px;
  font-size: 18px;
  margin-top: 30px; }
  @media (min-width: 992px) {
    body.page-template-template-documents .document-quote__wrapper {
      margin-top: 0; } }
  body.page-template-template-documents .document-quote__wrapper em {
    font-size: 14px; }

.emploi-details__wrapper {
  padding: 30px 0; }
  @media (min-width: 992px) {
    .emploi-details__wrapper {
      padding: 45px 0; } }
  @media (min-width: 1200px) {
    .emploi-details__wrapper {
      padding: 65px 0 85px; } }
  .emploi-details__wrapper .secondary-title, .emploi-details__wrapper .wysiwyg h2, .wysiwyg .emploi-details__wrapper h2, .emploi-details__wrapper .wysiwyg-blue-box h2, .wysiwyg-blue-box .emploi-details__wrapper h2 {
    text-transform: initial; }
  .emploi-details__wrapper .emploi-date {
    font-family: "Montserrat", sans-serif;
    font-size: 14px;
    font-weight: 600;
    line-height: 1.2;
    color: #0787e6;
    margin-bottom: 10px; }
  .emploi-details__wrapper .article-share__wrapper {
    margin: 30px 0 40px; }

.emploi-details__characteristic {
  list-style-type: none;
  padding: 0 0 15px 0; }

.emploi-details__form {
  margin-top: 80px; }

.emploi-details__sidebar {
  position: sticky;
  top: 30px; }
  .emploi-details__sidebar .primary-button, .emploi-details__sidebar .aide_admission__wrapper a, .aide_admission__wrapper .emploi-details__sidebar a, .emploi-details__sidebar .aide_demarche__wrapper a, .aide_demarche__wrapper .emploi-details__sidebar a, .emploi-details__sidebar .aide_financiere__wrapper a, .aide_financiere__wrapper .emploi-details__sidebar a, .emploi-details__sidebar .page-banner-home__content a, .page-banner-home__content .emploi-details__sidebar a, .emploi-details__sidebar .post-navigation__menu-wrapper li a, .post-navigation__menu-wrapper li .emploi-details__sidebar a, .emploi-details__sidebar body.page-template-template-Admission .boite_sidebar__wysiwyg a, body.page-template-template-Admission .boite_sidebar__wysiwyg .emploi-details__sidebar a, .emploi-details__sidebar body.home .about .wysiwyg a, body.home .about .wysiwyg .emploi-details__sidebar a, .emploi-details__sidebar body.home .about .wysiwyg-blue-box a, body.home .about .wysiwyg-blue-box .emploi-details__sidebar a {
    margin-top: 30px; }

body.page-template-template-equipements .campus-equipement__wrapper {
  padding: 65px 15px 65px 45px; }

body.page-template-template-equipements .campus-equipement__single {
  padding: 20px 0; }
  body.page-template-template-equipements .campus-equipement__single:first-child {
    padding-top: 0; }
  body.page-template-template-equipements .campus-equipement__single:last-child {
    padding-bottom: 0; }
  body.page-template-template-equipements .campus-equipement__single > .row {
    padding-bottom: 35px; }
    body.page-template-template-equipements .campus-equipement__single > .row:first-child {
      padding-bottom: 15px; }
  body.page-template-template-equipements .campus-equipement__single--title.mtl {
    color: #F78505; }
  body.page-template-template-equipements .campus-equipement__single--title.south-shore {
    color: #C61C78; }
  body.page-template-template-equipements .campus-equipement__single .equipement-gallery__wrapper {
    position: relative; }
  body.page-template-template-equipements .campus-equipement__single .equipement-gallery__arrow {
    outline: 0;
    background: transparent;
    border: 0;
    cursor: pointer;
    width: 30px;
    height: 30px;
    position: absolute;
    bottom: 0;
    right: 0;
    color: #fff;
    background-color: #0787e6;
    transition: all ease 0.3s; }
    body.page-template-template-equipements .campus-equipement__single .equipement-gallery__arrow:hover {
      background-color: #1e9bf8; }
    body.page-template-template-equipements .campus-equipement__single .equipement-gallery__arrow.mtl {
      background-color: #F78505; }
    body.page-template-template-equipements .campus-equipement__single .equipement-gallery__arrow.south-shore {
      background-color: #C61C78; }
    body.page-template-template-equipements .campus-equipement__single .equipement-gallery__arrow i {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%); }
    body.page-template-template-equipements .campus-equipement__single .equipement-gallery__arrow.slick-prev {
      right: 30px;
      transform: rotate(180deg); }
  body.page-template-template-equipements .campus-equipement__single .col-lg-6.wysiwyg, body.page-template-template-equipements .campus-equipement__single .col-lg-6.wysiwyg-blue-box {
    padding-top: 15px; }
    @media (min-width: 992px) {
      body.page-template-template-equipements .campus-equipement__single .col-lg-6.wysiwyg, body.page-template-template-equipements .campus-equipement__single .col-lg-6.wysiwyg-blue-box {
        padding-top: 30px; } }

.EtudiantUnJour__intro {
  font-family: "Open Sans", sans-serif;
  font-size: 21px;
  line-height: 1.2;
  color: #06204c;
  text-align: center;
  padding-bottom: 20px; }
  @media (min-width: 992px) {
    .EtudiantUnJour__intro {
      padding-bottom: 35px;
      font-size: 27px; } }
  @media (min-width: 1200px) {
    .EtudiantUnJour__intro {
      padding-bottom: 50px;
      font-size: 30px; } }

.EtudiantUnJour__wrapper {
  padding-top: 70px;
  padding-bottom: 90px; }
  .EtudiantUnJour__wrapper .usefullinks__wrapper {
    border: 2px solid #0787e6;
    padding: 30px 100px 20px;
    font-family: "Montserrat", sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.3;
    margin-top: 70px;
    margin-bottom: 30px; }
    .EtudiantUnJour__wrapper .usefullinks__wrapper .primary-button, .EtudiantUnJour__wrapper .usefullinks__wrapper .aide_admission__wrapper a, .aide_admission__wrapper .EtudiantUnJour__wrapper .usefullinks__wrapper a, .EtudiantUnJour__wrapper .usefullinks__wrapper .aide_demarche__wrapper a, .aide_demarche__wrapper .EtudiantUnJour__wrapper .usefullinks__wrapper a, .EtudiantUnJour__wrapper .usefullinks__wrapper .aide_financiere__wrapper a, .aide_financiere__wrapper .EtudiantUnJour__wrapper .usefullinks__wrapper a, .EtudiantUnJour__wrapper .usefullinks__wrapper .page-banner-home__content a, .page-banner-home__content .EtudiantUnJour__wrapper .usefullinks__wrapper a, .EtudiantUnJour__wrapper .usefullinks__wrapper .post-navigation__menu-wrapper li a, .post-navigation__menu-wrapper li .EtudiantUnJour__wrapper .usefullinks__wrapper a, .EtudiantUnJour__wrapper .usefullinks__wrapper body.page-template-template-Admission .boite_sidebar__wysiwyg a, body.page-template-template-Admission .boite_sidebar__wysiwyg .EtudiantUnJour__wrapper .usefullinks__wrapper a, .EtudiantUnJour__wrapper .usefullinks__wrapper body.home .about .wysiwyg a, body.home .about .wysiwyg .EtudiantUnJour__wrapper .usefullinks__wrapper a, .EtudiantUnJour__wrapper .usefullinks__wrapper body.home .about .wysiwyg-blue-box a, body.home .about .wysiwyg-blue-box .EtudiantUnJour__wrapper .usefullinks__wrapper a {
      margin-top: -25px; }

body.page-template-template-etudiantInternationaux .etudiantsInternationaux__wrapper {
  padding: 55px 0 80px; }

body.page-template-template-faq .detailsService__wrapper {
  padding-top: 74px;
  padding-bottom: 115px; }

body.page-template-template-faq .detailsService__type {
  font-family: "Montserrat", sans-serif;
  font-size: 15px;
  font-weight: 700;
  line-height: 1.2;
  color: #0787e6;
  margin-bottom: 15px; }
  body.page-template-template-faq .detailsService__type span {
    font-weight: 400; }
    body.page-template-template-faq .detailsService__type span:before {
      content: '—';
      display: inline-block;
      padding-left: 20px;
      padding-right: 5px; }

body.page-template-template-faq .wysiwyg, body.page-template-template-faq .wysiwyg-blue-box {
  margin-bottom: 40px; }
  body.page-template-template-faq .wysiwyg ul li, body.page-template-template-faq .wysiwyg-blue-box ul li {
    padding-bottom: 15px;
    padding-left: 2.2em; }
  body.page-template-template-faq .wysiwyg ul > li:before, body.page-template-template-faq .wysiwyg-blue-box ul > li:before {
    content: "";
    font-family: "teccart" !important;
    padding-right: 5px;
    color: #0787e6; }

body.page-template-template-groupe-teccart .groupe-teccart__wrapper, body.page-template-template-groupe-teccart-child .groupe-teccart__wrapper {
  padding-top: 35px;
  padding-bottom: 50px; }
  @media (min-width: 992px) {
    body.page-template-template-groupe-teccart .groupe-teccart__wrapper, body.page-template-template-groupe-teccart-child .groupe-teccart__wrapper {
      padding-top: 50px;
      padding-bottom: 100px; } }
  @media (min-width: 1200px) {
    body.page-template-template-groupe-teccart .groupe-teccart__wrapper, body.page-template-template-groupe-teccart-child .groupe-teccart__wrapper {
      padding-top: 66px;
      padding-bottom: 115px; } }

body.page-template-template-groupe-teccart .groupe-teccart__intro, body.page-template-template-groupe-teccart-child .groupe-teccart__intro {
  font-family: "Montserrat", sans-serif;
  font-size: 21px;
  font-weight: 300;
  line-height: 1.2;
  color: #06204c;
  padding-bottom: 20px; }
  @media (min-width: 992px) {
    body.page-template-template-groupe-teccart .groupe-teccart__intro, body.page-template-template-groupe-teccart-child .groupe-teccart__intro {
      padding-bottom: 35px;
      font-size: 27px; } }
  @media (min-width: 1200px) {
    body.page-template-template-groupe-teccart .groupe-teccart__intro, body.page-template-template-groupe-teccart-child .groupe-teccart__intro {
      padding-bottom: 50px;
      font-size: 30px; } }

body.page-template-template-groupe-teccart .groupe-teccart__single, body.page-template-template-groupe-teccart-child .groupe-teccart__single {
  margin-bottom: 50px; }
  @media (min-width: 992px) {
    body.page-template-template-groupe-teccart .groupe-teccart__single, body.page-template-template-groupe-teccart-child .groupe-teccart__single {
      margin-bottom: 0; } }
  body.page-template-template-groupe-teccart .groupe-teccart__single--title, body.page-template-template-groupe-teccart-child .groupe-teccart__single--title {
    font-family: "Montserrat", sans-serif;
    font-size: 18px;
    font-weight: 700;
    line-height: 1.2;
    color: #06204c;
    text-transform: uppercase;
    position: relative;
    margin-top: 35px;
    margin-bottom: 35px;
    padding-bottom: 15px; }
    @media (min-width: 992px) {
      body.page-template-template-groupe-teccart .groupe-teccart__single--title, body.page-template-template-groupe-teccart-child .groupe-teccart__single--title {
        margin-bottom: 46px;
        padding-bottom: 26px; } }
    body.page-template-template-groupe-teccart .groupe-teccart__single--title:before, body.page-template-template-groupe-teccart-child .groupe-teccart__single--title:before {
      content: '';
      position: relative;
      width: 60px;
      height: 3px;
      background-color: #007bff;
      position: absolute;
      top: 100%;
      left: 0; }
      @media (min-width: 992px) {
        body.page-template-template-groupe-teccart .groupe-teccart__single--title:before, body.page-template-template-groupe-teccart-child .groupe-teccart__single--title:before {
          width: 83px; } }
    @media (min-width: 992px) {
      body.page-template-template-groupe-teccart .groupe-teccart__single--title, body.page-template-template-groupe-teccart-child .groupe-teccart__single--title {
        font-size: 22px; } }
  body.page-template-template-groupe-teccart .groupe-teccart__single--etablissement, body.page-template-template-groupe-teccart-child .groupe-teccart__single--etablissement {
    margin-top: 30px; }
    @media (min-width: 992px) {
      body.page-template-template-groupe-teccart .groupe-teccart__single--etablissement, body.page-template-template-groupe-teccart-child .groupe-teccart__single--etablissement {
        margin-top: 40px; } }
    body.page-template-template-groupe-teccart .groupe-teccart__single--etablissement--type, body.page-template-template-groupe-teccart-child .groupe-teccart__single--etablissement--type {
      font-family: "Open Sans", sans-serif;
      font-size: 14px;
      font-weight: 700;
      color: #06204c;
      padding-bottom: 3px; }
    body.page-template-template-groupe-teccart .groupe-teccart__single--etablissement--nom, body.page-template-template-groupe-teccart-child .groupe-teccart__single--etablissement--nom {
      font-family: "Montserrat", sans-serif;
      font-size: 18px;
      font-weight: 700;
      line-height: 1.2;
      color: #06204c;
      padding-bottom: 12px; }
    body.page-template-template-groupe-teccart .groupe-teccart__single--etablissement--adresse, body.page-template-template-groupe-teccart-child .groupe-teccart__single--etablissement--adresse {
      font-family: "Montserrat", sans-serif;
      font-size: 14px;
      font-weight: 400;
      color: #06204c; }
      body.page-template-template-groupe-teccart .groupe-teccart__single--etablissement--adresse p, body.page-template-template-groupe-teccart-child .groupe-teccart__single--etablissement--adresse p {
        margin-bottom: 12px; }
    body.page-template-template-groupe-teccart .groupe-teccart__single--etablissement--website, body.page-template-template-groupe-teccart-child .groupe-teccart__single--etablissement--website {
      font-family: "Open Sans", sans-serif;
      font-size: 14px;
      font-weight: 700;
      color: #0787e6;
      text-decoration: underline;
      display: inline-block; }
    body.page-template-template-groupe-teccart .groupe-teccart__single--etablissement--map, body.page-template-template-groupe-teccart-child .groupe-teccart__single--etablissement--map {
      font-family: "Open Sans", sans-serif;
      font-size: 14px;
      font-weight: 700;
      color: #0787e6;
      text-decoration: underline;
      display: inline-block;
      margin-bottom: 8px; }

body.page-template-template-history .history__wrapper {
  padding-top: 75px;
  padding-bottom: 75px; }

body.page-template-template-history .boite_sidebar__wrapper {
  margin-top: 75px;
  margin-bottom: 75px; }

body.home .page-highlights {
  padding-bottom: 30px;
  padding-top: 28px; }
  @media (min-width: 992px) {
    body.home .page-highlights {
      padding-bottom: 0; } }
  body.home .page-highlights .title {
    font-family: "Montserrat", sans-serif;
    font-weight: 700;
    font-size: 19px;
    color: #06204c; }
    @media (min-width: 992px) {
      body.home .page-highlights .title {
        font-size: 24px; } }
    body.home .page-highlights .title:after {
      content: "";
      background-color: #0787e6;
      display: block;
      width: 80px;
      height: 2px;
      margin: 7px 0 25px; }
      @media (min-width: 992px) {
        body.home .page-highlights .title:after {
          margin: 10px 0 40px;
          height: 3px; } }
  body.home .page-highlights .content {
    margin: 25px 0 30px;
    line-height: 1.25; }
    body.home .page-highlights .content h3 {
      font-size: 18px; }
  body.home .page-highlights a {
    font-family: "Montserrat", sans-serif;
    font-weight: 700;
    display: inline-block;
    font-size: 13px; }
  body.home .page-highlights .campus {
    padding-bottom: 50px; }
    @media (min-width: 992px) {
      body.home .page-highlights .campus {
        padding-bottom: 95px; } }

body.home .news-home {
  width: 100%;
  position: relative;
  padding-top: 40px;
  margin-top: -28px; }
  @media (min-width: 992px) {
    body.home .news-home {
      padding-top: 0; } }
  @media (min-width: 992px) {
    body.home .news-home ul {
      position: absolute;
      width: 100%;
      transform: translateY(-100%);
      align-items: stretch; } }
  body.home .news-home ul > :nth-child(2) {
    margin: 0 3px; }
  body.home .news-home ul li {
    width: 25%; }
    body.home .news-home ul li a {
      color: #FFFFFF;
      height: 100%;
      display: block; }
      body.home .news-home ul li a.all-tab {
        background-color: #0787e6; }
      body.home .news-home ul li a.mtl-tab {
        background-color: #F78505; }
      body.home .news-home ul li a.south-tab {
        background-color: #C61C78; }
  body.home .news-home .tab-content {
    height: calc(100%);
    color: #FFFFFF; }
    body.home .news-home .tab-content .tab-pane {
      padding: 20px 20px 60px; }
      @media (min-width: 1200px) {
        body.home .news-home .tab-content .tab-pane {
          padding: 25px 30px 60px;
          height: 100%; } }
      body.home .news-home .tab-content .tab-pane a {
        color: #FFFFFF; }
        body.home .news-home .tab-content .tab-pane a.event-tile {
          text-decoration: none;
          padding: 2px 0 21px; }
          @media (min-width: 1200px) {
            body.home .news-home .tab-content .tab-pane a.event-tile {
              padding: 12px 0 20px; } }
          body.home .news-home .tab-content .tab-pane a.event-tile .date {
            font-size: 13px;
            font-weight: 500; }
          body.home .news-home .tab-content .tab-pane a.event-tile .news-title {
            font-size: 16px;
            font-weight: 700;
            line-height: 1.2;
            margin-bottom: 7px; }
            @media (min-width: 1200px) {
              body.home .news-home .tab-content .tab-pane a.event-tile .news-title {
                font-size: 21px;
                margin-bottom: 10px; } }
          body.home .news-home .tab-content .tab-pane a.event-tile .link {
            font-size: 0.75rem;
            padding-left: 18px;
            position: relative; }
            body.home .news-home .tab-content .tab-pane a.event-tile .link:before {
              position: absolute;
              top: -2px;
              left: 0;
              content: "\2014";
              margin-right: 10px;
              text-decoration: none; }
            body.home .news-home .tab-content .tab-pane a.event-tile .link:hover {
              text-decoration: underline; }
              body.home .news-home .tab-content .tab-pane a.event-tile .link:hover:before {
                text-decoration: none; }
        body.home .news-home .tab-content .tab-pane a.all-event-link {
          position: absolute;
          width: 100%;
          bottom: 0;
          left: 0;
          display: block;
          border-top: 1px solid rgba(255, 255, 255, 0.4);
          padding: 15px 20px; }
          @media (min-width: 992px) {
            body.home .news-home .tab-content .tab-pane a.all-event-link {
              padding: 15px 30px; } }
      body.home .news-home .tab-content .tab-pane p.event-title {
        font-family: "Montserrat", sans-serif;
        font-weight: 700;
        font-size: 19px;
        text-transform: uppercase;
        margin-bottom: 12px; }
        @media (min-width: 1200px) {
          body.home .news-home .tab-content .tab-pane p.event-title {
            font-size: 24px;
            margin-bottom: 15px; } }
      body.home .news-home .tab-content .tab-pane#all-news {
        background-color: #0787e6; }
      body.home .news-home .tab-content .tab-pane#mtl-news {
        background-color: #F78505; }
      body.home .news-home .tab-content .tab-pane#south-news {
        background-color: #C61C78; }

body.home .programs-type {
  background-color: #ebeef1;
  padding-top: 30px; }
  @media (min-width: 992px) {
    body.home .programs-type {
      padding-top: 70px;
      padding-bottom: 70px; } }
  body.home .programs-type__item {
    background-color: #fff;
    display: block;
    text-decoration: none;
    transition: all ease .3s;
    height: 100%; }
    body.home .programs-type__item:hover {
      background-color: #06204c; }
      body.home .programs-type__item:hover .programs-type__item--title {
        color: #fff; }
    body.home .programs-type__item-wrapper {
      margin-bottom: 30px; }
      @media (min-width: 992px) {
        body.home .programs-type__item-wrapper {
          margin-bottom: 0; } }
    body.home .programs-type__item--image {
      margin-bottom: 15px; }
      @media (min-width: 768px) {
        body.home .programs-type__item--image {
          margin-bottom: 23px; } }
    body.home .programs-type__item--title {
      font-family: "Montserrat", sans-serif;
      font-size: 17px;
      font-weight: 700;
      line-height: 1.2;
      color: #06204c;
      padding: 0 17px;
      display: block;
      transition: all ease .3s; }
      @media (min-width: 768px) {
        body.home .programs-type__item--title {
          padding: 0 24px; } }
      @media (min-width: 992px) and (max-width: 1199.98px) {
        body.home .programs-type__item--title {
          padding: 0 18px 55px;
          font-size: 17px; } }
      @media (min-width: 1200px) {
        body.home .programs-type__item--title {
          padding: 0 24px 60px;
          font-size: 23px; } }
    body.home .programs-type__item--link {
      font-family: "Montserrat", sans-serif;
      font-size: 12px;
      font-weight: 700;
      line-height: 1.2;
      color: #0787e6;
      text-decoration: none;
      display: inline-block;
      padding: 10px 24px 20px;
      transition: all ease .3s; }
      @media (min-width: 992px) {
        body.home .programs-type__item--link {
          position: absolute;
          bottom: 27px;
          left: 40px;
          padding: 0; } }
      body.home .programs-type__item--link:before {
        content: "—";
        padding-right: 5px; }
  body.home .programs-type__links {
    margin-top: 54px;
    font-family: "Montserrat", sans-serif;
    font-size: 14px;
    font-weight: 700;
    line-height: 1.2;
    color: #06204c; }
    body.home .programs-type__links .mtl {
      color: #F78505; }
    body.home .programs-type__links .south-shore {
      color: #C61C78; }
  @media (max-width: 991.98px) {
    body.home .programs-type .wysiwyg h4, body.home .programs-type .wysiwyg-blue-box h4 {
      margin-bottom: 10px; } }
  body.home .programs-type .wysiwyg h2, body.home .programs-type .wysiwyg-blue-box h2 {
    margin-bottom: 30px; }
    @media (min-width: 992px) {
      body.home .programs-type .wysiwyg h2, body.home .programs-type .wysiwyg-blue-box h2 {
        margin-bottom: 56px; } }

body.home .about {
  background-color: #f2f2f2;
  padding: 60px 0; }
  body.home .about .wysiwyg, body.home .about .wysiwyg-blue-box {
    margin-bottom: 20px; }
    @media (min-width: 992px) {
      body.home .about .wysiwyg, body.home .about .wysiwyg-blue-box {
        margin-bottom: 0; } }
    body.home .about .wysiwyg h2, body.home .about .wysiwyg-blue-box h2 {
      margin-bottom: 30px; }
    body.home .about .wysiwyg a, body.home .about .wysiwyg-blue-box a {
      margin-top: 20px; }

body.home .latests-news {
  padding: 50px 0 50px; }
  @media (min-width: 992px) {
    body.home .latests-news {
      padding: 65px 0 55px; } }
  @media (min-width: 992px) {
    body.home .latests-news h2 {
      padding-right: 40px; } }
  body.home .latests-news__single {
    margin-top: 25px; }
    @media (min-width: 992px) {
      body.home .latests-news__single {
        margin-top: 50px; } }
    body.home .latests-news__single--date {
      font-family: "Montserrat", sans-serif;
      font-size: 14px;
      font-weight: 600;
      line-height: 1.2;
      color: #0787e6;
      margin-bottom: 15px; }
    body.home .latests-news__single--title {
      font-family: "Montserrat", sans-serif;
      font-size: 17px;
      font-weight: 700;
      line-height: 1.2;
      color: #06204c;
      margin-bottom: 12px; }
      @media (min-width: 992px) {
        body.home .latests-news__single--title {
          font-size: 21px;
          margin-bottom: 15px; } }
    body.home .latests-news__single--excerpt {
      font-family: "Open Sans", sans-serif;
      font-size: 14px;
      font-weight: 400;
      line-height: 1.25;
      color: #06204c;
      margin-bottom: 10px; }
      @media (min-width: 992px) {
        body.home .latests-news__single--excerpt {
          margin-bottom: 30px; } }
    body.home .latests-news__single--link {
      font-family: "Montserrat", sans-serif;
      font-size: 12px;
      font-weight: 700;
      line-height: 1.2;
      color: #0787e6;
      display: inline-block;
      margin-bottom: 10px; }
      @media (min-width: 992px) {
        body.home .latests-news__single--link {
          font-size: 14px;
          margin-bottom: 30px; } }
  body.home .latests-news .informations-title {
    margin-top: 50px; }
    @media (min-width: 768px) {
      body.home .latests-news .informations-title {
        margin-top: 0; } }

body .archive-program__wrapper {
  background-color: #ebeef1;
  padding-top: 30px; }
  @media (min-width: 992px) {
    body .archive-program__wrapper {
      padding-top: 71px; } }

body.page-template-template-mission .mission__wrapper {
  padding: 30px 0 0;
  overflow: hidden; }
  @media (min-width: 768px) {
    body.page-template-template-mission .mission__wrapper {
      padding: 30px 0; } }
  @media (min-width: 992px) {
    body.page-template-template-mission .mission__wrapper {
      padding: 65px 0 70px; } }

body.page-template-template-mission .mission__intro {
  font-family: "Open Sans", sans-serif;
  font-size: 18px;
  line-height: 1.2;
  color: #06204c;
  padding-bottom: 65px; }

body.page-template-template-mission .mission__quote {
  background: #0787e6;
  padding: 30px 0;
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
  font-weight: 700;
  line-height: 1.25;
  color: #fff;
  margin-top: 10px;
  position: relative; }
  @media (min-width: 768px) {
    body.page-template-template-mission .mission__quote {
      padding: 35px 25px;
      margin-top: 0; } }
  @media (min-width: 1200px) {
    body.page-template-template-mission .mission__quote {
      padding: 45px 27px;
      margin-top: -15px;
      font-size: 24px; } }
  body.page-template-template-mission .mission__quote:before {
    content: '';
    width: 100vw;
    height: 100%;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    background: #0787e6;
    z-index: -1; }
    @media (min-width: 768px) {
      body.page-template-template-mission .mission__quote:before {
        display: none; } }
  body.page-template-template-mission .mission__quote h4 {
    margin-bottom: 18px; }
  body.page-template-template-mission .mission__quote p {
    margin-bottom: 0; }

body.page-template-template-mission .usefullinks__wrapper {
  border: 2px solid #0787e6;
  padding: 50px 60px;
  font-family: "Open Sans", sans-serif;
  font-size: 18px;
  line-height: 1.3;
  margin-top: 70px;
  margin-bottom: 30px; }

.news-details__wrapper {
  padding: 30px 0; }
  @media (min-width: 992px) {
    .news-details__wrapper {
      padding: 50px 0; } }
  @media (min-width: 1200px) {
    .news-details__wrapper {
      padding: 65px 0 85px; } }
  .news-details__wrapper .news-date {
    font-family: "Montserrat", sans-serif;
    font-size: 14px;
    font-weight: 600;
    line-height: 1.2;
    color: #0787e6;
    margin-bottom: 10px; }
  .news-details__wrapper .article-share__wrapper {
    margin: 20px 0 30px; }
    @media (min-width: 1200px) {
      .news-details__wrapper .article-share__wrapper {
        margin: 30px 0 40px; } }

body.page-template-template-NousJoindre .nousjoindre__wrapper {
  padding: 30px 0; }
  @media (min-width: 992px) {
    body.page-template-template-NousJoindre .nousjoindre__wrapper {
      padding: 50px 0; } }
  @media (min-width: 1200px) {
    body.page-template-template-NousJoindre .nousjoindre__wrapper {
      padding: 65px 0 115px; } }

body.page-template-template-NousJoindre .nousjoindre__intro {
  font-family: "Montserrat", sans-serif;
  font-size: 21px;
  font-weight: 300;
  line-height: 1.2;
  color: #06204c;
  padding-bottom: 20px; }
  @media (min-width: 992px) {
    body.page-template-template-NousJoindre .nousjoindre__intro {
      padding-bottom: 35px;
      font-size: 27px; } }
  @media (min-width: 1200px) {
    body.page-template-template-NousJoindre .nousjoindre__intro {
      padding-bottom: 50px;
      font-size: 30px; } }

body.page-template-template-NousJoindre .nousjoindre__single {
  border: solid 2px #ebeef1;
  padding: 25px 20px 10px;
  height: calc(100% - 30px);
  margin-bottom: 30px; }
  @media (min-width: 992px) {
    body.page-template-template-NousJoindre .nousjoindre__single {
      padding: 31px 25px; } }
  body.page-template-template-NousJoindre .nousjoindre__single--title {
    font-family: "Open Sans", sans-serif;
    font-size: 24px;
    font-weight: 700;
    line-height: 1.2;
    color: #06204c;
    position: relative;
    margin-bottom: 35px;
    padding-bottom: 15px; }
    @media (min-width: 992px) {
      body.page-template-template-NousJoindre .nousjoindre__single--title {
        margin-bottom: 46px;
        padding-bottom: 26px; } }
    body.page-template-template-NousJoindre .nousjoindre__single--title:before {
      content: '';
      position: relative;
      width: 60px;
      height: 3px;
      background-color: #007bff;
      position: absolute;
      top: 100%;
      left: 0; }
      @media (min-width: 992px) {
        body.page-template-template-NousJoindre .nousjoindre__single--title:before {
          width: 83px; } }
  body.page-template-template-NousJoindre .nousjoindre__single--map {
    font-family: "Open Sans", sans-serif;
    font-size: 14px;
    font-weight: 700;
    line-height: 1.2;
    color: #0787e6;
    display: inline-block;
    margin-top: 0;
    margin-bottom: 20px; }
    @media (min-width: 992px) {
      body.page-template-template-NousJoindre .nousjoindre__single--map {
        margin-top: 15px;
        margin-bottom: 30px; } }
  body.page-template-template-NousJoindre .nousjoindre__single--phone {
    display: flex;
    align-items: flex-start;
    justify-content: space-between; }
    @media (min-width: 992px) {
      body.page-template-template-NousJoindre .nousjoindre__single--phone > div {
        margin-bottom: 15px; } }
    body.page-template-template-NousJoindre .nousjoindre__single--phone > div:last-child {
      text-align: right; }

body.page-template-template-NousJoindre .nousjoindre__hightlighted {
  border: solid 2px #0787e6;
  padding: 31px 25px;
  height: calc(100% - 30px);
  margin-bottom: 30px;
  background: #0787e6;
  color: #fff; }
  body.page-template-template-NousJoindre .nousjoindre__hightlighted--title {
    font-family: "Open Sans", sans-serif;
    font-size: 24px;
    font-weight: 700;
    line-height: 1.2;
    color: #fff;
    position: relative;
    margin-bottom: 35px;
    padding-bottom: 15px; }
    @media (min-width: 992px) {
      body.page-template-template-NousJoindre .nousjoindre__hightlighted--title {
        margin-bottom: 46px;
        padding-bottom: 26px; } }
    body.page-template-template-NousJoindre .nousjoindre__hightlighted--title:before {
      content: '';
      position: relative;
      width: 60px;
      height: 3px;
      background-color: #007bff;
      position: absolute;
      top: 100%;
      left: 0; }
      @media (min-width: 992px) {
        body.page-template-template-NousJoindre .nousjoindre__hightlighted--title:before {
          width: 83px; } }
    body.page-template-template-NousJoindre .nousjoindre__hightlighted--title:before {
      background-color: #fff; }
  body.page-template-template-NousJoindre .nousjoindre__hightlighted .primary-button, body.page-template-template-NousJoindre .nousjoindre__hightlighted .aide_admission__wrapper a, .aide_admission__wrapper body.page-template-template-NousJoindre .nousjoindre__hightlighted a, body.page-template-template-NousJoindre .nousjoindre__hightlighted .aide_demarche__wrapper a, .aide_demarche__wrapper body.page-template-template-NousJoindre .nousjoindre__hightlighted a, body.page-template-template-NousJoindre .nousjoindre__hightlighted .aide_financiere__wrapper a, .aide_financiere__wrapper body.page-template-template-NousJoindre .nousjoindre__hightlighted a, body.page-template-template-NousJoindre .nousjoindre__hightlighted .page-banner-home__content a, .page-banner-home__content body.page-template-template-NousJoindre .nousjoindre__hightlighted a, body.page-template-template-NousJoindre .nousjoindre__hightlighted .post-navigation__menu-wrapper li a, .post-navigation__menu-wrapper li body.page-template-template-NousJoindre .nousjoindre__hightlighted a, body.page-template-template-NousJoindre .nousjoindre__hightlighted body.page-template-template-Admission .boite_sidebar__wysiwyg a, body.page-template-template-Admission .boite_sidebar__wysiwyg body.page-template-template-NousJoindre .nousjoindre__hightlighted a, body.page-template-template-NousJoindre .nousjoindre__hightlighted body.home .about .wysiwyg a, body.home .about .wysiwyg body.page-template-template-NousJoindre .nousjoindre__hightlighted a, body.page-template-template-NousJoindre .nousjoindre__hightlighted body.home .about .wysiwyg-blue-box a, body.home .about .wysiwyg-blue-box body.page-template-template-NousJoindre .nousjoindre__hightlighted a {
    text-align: center;
    min-width: 150px;
    margin-top: 26px;
    margin-bottom: 46px; }

body.page-template-template-projetEducatif .projetEducatif__wrapper {
  padding: 30px 0; }
  @media (min-width: 992px) {
    body.page-template-template-projetEducatif .projetEducatif__wrapper {
      padding: 45px 0; } }
  @media (min-width: 1200px) {
    body.page-template-template-projetEducatif .projetEducatif__wrapper {
      padding: 65px 0 85px; } }

body.page-template-template-projetEducatif .projetEducatif__intro {
  font-family: "Montserrat", sans-serif;
  font-size: 18px;
  font-weight: 300;
  line-height: 1.2;
  color: #06204c;
  padding-bottom: 14px; }
  @media (min-width: 992px) {
    body.page-template-template-projetEducatif .projetEducatif__intro {
      font-size: 25px;
      padding-bottom: 30px; } }
  @media (min-width: 1200px) {
    body.page-template-template-projetEducatif .projetEducatif__intro {
      font-size: 30px;
      padding-bottom: 50px; } }

body.page-template-template-projetEducatif .projetEducatif__single {
  padding: 30px 20px 10px;
  border: 1px solid #ebeef1;
  position: relative;
  margin-bottom: 30px; }
  @media (min-width: 768px) {
    body.page-template-template-projetEducatif .projetEducatif__single {
      padding: 30px 30px 10px; } }
  @media (min-width: 992px) {
    body.page-template-template-projetEducatif .projetEducatif__single {
      height: 100%;
      padding: 45px 30px 60px;
      margin-bottom: 0; } }
  body.page-template-template-projetEducatif .projetEducatif__single--icon {
    width: 32px;
    position: absolute;
    top: 30px;
    left: 20px; }
    @media (min-width: 768px) {
      body.page-template-template-projetEducatif .projetEducatif__single--icon {
        left: 30px; } }
    @media (min-width: 992px) {
      body.page-template-template-projetEducatif .projetEducatif__single--icon {
        width: 50px;
        top: 45px;
        left: 30px; } }
  body.page-template-template-projetEducatif .projetEducatif__single--title {
    font-family: "Montserrat", sans-serif;
    font-size: 17px;
    font-weight: 700;
    line-height: 1.2;
    color: #06204c;
    margin-bottom: 30px;
    padding-left: 50px; }
    @media (min-width: 992px) {
      body.page-template-template-projetEducatif .projetEducatif__single--title {
        margin-bottom: 66px;
        padding-left: 75px;
        font-size: 20px; } }

body.page-template-template-Services .detailsService__wrapper, body.page-template-template-ServicesPolitique .detailsService__wrapper, body.page-template-template-tempServicesPolitique .detailsService__wrapper, body.page-template-template-tempServicesAidePedagogique .detailsService__wrapper, body.page-template-template-tempServicesAideFinanciere .detailsService__wrapper, body.page-template-template-tempServicesAssociationEtudiante .detailsService__wrapper, body.page-template-template-tempServicesStages .detailsService__wrapper {
  padding-top: 74px;
  padding-bottom: 115px; }

body.page-template-template-Services .detailsService__type, body.page-template-template-ServicesPolitique .detailsService__type, body.page-template-template-tempServicesPolitique .detailsService__type, body.page-template-template-tempServicesAidePedagogique .detailsService__type, body.page-template-template-tempServicesAideFinanciere .detailsService__type, body.page-template-template-tempServicesAssociationEtudiante .detailsService__type, body.page-template-template-tempServicesStages .detailsService__type {
  font-family: "Montserrat", sans-serif;
  font-size: 15px;
  font-weight: 700;
  line-height: 1.2;
  color: #F78505;
  margin-bottom: 15px; }
  body.page-template-template-Services .detailsService__type span, body.page-template-template-ServicesPolitique .detailsService__type span, body.page-template-template-tempServicesPolitique .detailsService__type span, body.page-template-template-tempServicesAidePedagogique .detailsService__type span, body.page-template-template-tempServicesAideFinanciere .detailsService__type span, body.page-template-template-tempServicesAssociationEtudiante .detailsService__type span, body.page-template-template-tempServicesStages .detailsService__type span {
    font-weight: 400; }
    body.page-template-template-Services .detailsService__type span:before, body.page-template-template-ServicesPolitique .detailsService__type span:before, body.page-template-template-tempServicesPolitique .detailsService__type span:before, body.page-template-template-tempServicesAidePedagogique .detailsService__type span:before, body.page-template-template-tempServicesAideFinanciere .detailsService__type span:before, body.page-template-template-tempServicesAssociationEtudiante .detailsService__type span:before, body.page-template-template-tempServicesStages .detailsService__type span:before {
      content: '—';
      display: inline-block;
      padding-left: 20px;
      padding-right: 5px; }

body.page-template-template-Services .wysiwyg ul li, body.page-template-template-Services .wysiwyg-blue-box ul li, body.page-template-template-ServicesPolitique .wysiwyg ul li, body.page-template-template-ServicesPolitique .wysiwyg-blue-box ul li, body.page-template-template-tempServicesPolitique .wysiwyg ul li, body.page-template-template-tempServicesPolitique .wysiwyg-blue-box ul li, body.page-template-template-tempServicesAidePedagogique .wysiwyg ul li, body.page-template-template-tempServicesAidePedagogique .wysiwyg-blue-box ul li, body.page-template-template-tempServicesAideFinanciere .wysiwyg ul li, body.page-template-template-tempServicesAideFinanciere .wysiwyg-blue-box ul li, body.page-template-template-tempServicesAssociationEtudiante .wysiwyg ul li, body.page-template-template-tempServicesAssociationEtudiante .wysiwyg-blue-box ul li, body.page-template-template-tempServicesStages .wysiwyg ul li, body.page-template-template-tempServicesStages .wysiwyg-blue-box ul li {
  padding-bottom: 15px;
  padding-left: 2.2em; }

body.page-template-template-Services .wysiwyg ul > li:before, body.page-template-template-Services .wysiwyg-blue-box ul > li:before, body.page-template-template-ServicesPolitique .wysiwyg ul > li:before, body.page-template-template-ServicesPolitique .wysiwyg-blue-box ul > li:before, body.page-template-template-tempServicesPolitique .wysiwyg ul > li:before, body.page-template-template-tempServicesPolitique .wysiwyg-blue-box ul > li:before, body.page-template-template-tempServicesAidePedagogique .wysiwyg ul > li:before, body.page-template-template-tempServicesAidePedagogique .wysiwyg-blue-box ul > li:before, body.page-template-template-tempServicesAideFinanciere .wysiwyg ul > li:before, body.page-template-template-tempServicesAideFinanciere .wysiwyg-blue-box ul > li:before, body.page-template-template-tempServicesAssociationEtudiante .wysiwyg ul > li:before, body.page-template-template-tempServicesAssociationEtudiante .wysiwyg-blue-box ul > li:before, body.page-template-template-tempServicesStages .wysiwyg ul > li:before, body.page-template-template-tempServicesStages .wysiwyg-blue-box ul > li:before {
  content: "";
  font-family: "teccart" !important;
  padding-right: 5px;
  color: #0787e6; }

body.page-template-template-Services .courses-accordian__wrapper, body.page-template-template-ServicesPolitique .courses-accordian__wrapper, body.page-template-template-tempServicesPolitique .courses-accordian__wrapper, body.page-template-template-tempServicesAidePedagogique .courses-accordian__wrapper, body.page-template-template-tempServicesAideFinanciere .courses-accordian__wrapper, body.page-template-template-tempServicesAssociationEtudiante .courses-accordian__wrapper, body.page-template-template-tempServicesStages .courses-accordian__wrapper {
  margin-top: 50px; }

header.banner {
  position: absolute;
  width: 100%;
  top: 0;
  z-index: 1; }
  header.banner .brand {
    margin-top: 10px;
    margin-bottom: 19px; }
  header.banner .top-bar .nav-top {
    padding: 6px 0 7px;
    justify-content: flex-end; }
    header.banner .top-bar .nav-top a {
      font-family: "Montserrat", sans-serif;
      font-weight: 500;
      font-size: 11px;
      text-transform: uppercase;
      color: #FFFFFF;
      letter-spacing: -0.1; }
      header.banner .top-bar .nav-top a:hover {
        color: #0787e6;
        text-decoration: none; }
  header.banner .top-bar .nav-top-primary li {
    padding-right: 14px; }
    @media (min-width: 1200px) {
      header.banner .top-bar .nav-top-primary li {
        padding-right: 20px; } }
    header.banner .top-bar .nav-top-primary li:last-child {
      padding-right: 0; }

.nav-primary__wrapper > *, .nav-primary__menu > * {
  margin-right: 25px; }
  @media (min-width: 1200px) {
    .nav-primary__wrapper > *, .nav-primary__menu > * {
      margin-right: 45px; } }
  .nav-primary__wrapper > *:last-child, .nav-primary__menu > *:last-child {
    margin-right: 0; }

.nav-primary__wrapper {
  display: flex;
  justify-content: flex-end;
  position: relative;
  margin-top: 14px; }
  @media (min-width: 1200px) {
    .nav-primary__wrapper {
      margin-top: 24px; } }
  .nav-primary__wrapper .dropdown-toggle, .nav-primary__wrapper .nav-link {
    outline: 0;
    background: transparent;
    border: 0;
    cursor: pointer;
    font-family: "Montserrat", sans-serif;
    font-weight: 600;
    font-size: 15px;
    color: #FFFFFF;
    padding: 0 0 10px 0; }
  .nav-primary__wrapper .dropdown-toggle {
    position: relative;
    direction: block; }
    .nav-primary__wrapper .dropdown-toggle:before {
      content: "";
      display: block;
      width: 9px;
      height: 5px;
      background: transparent url("../images/arrow.png") left top no-repeat;
      background-size: contain;
      position: absolute;
      left: 50%;
      bottom: 0;
      transform: translateX(-50%);
      transition: all ease .3s; }
    .nav-primary__wrapper .dropdown-toggle:after {
      content: " ";
      top: 0;
      left: 50%;
      border: solid transparent;
      height: 0;
      width: 0;
      position: absolute;
      pointer-events: none;
      border-color: rgba(136, 183, 213, 0);
      border-bottom-color: #fff;
      border-width: 30px;
      margin-left: -30px;
      display: none; }
    .nav-primary__wrapper .dropdown-toggle:hover:before {
      transform: translateX(-50%) translateY(5px); }
    .nav-primary__wrapper .dropdown-toggle[aria-expanded="true"]:before {
      display: none; }
    .nav-primary__wrapper .dropdown-toggle[aria-expanded="true"]:after {
      display: block; }
  .nav-primary__wrapper .dropdown-menu {
    top: 40px !important;
    right: 0 !important;
    left: auto !important;
    transform: translateX(0) !important;
    border: 0;
    border-radius: 0;
    margin: 0;
    padding: 28px 39px; }
    .nav-primary__wrapper .dropdown-menu a {
      display: inline-block;
      font-family: "Open Sans", sans-serif;
      font-size: 12px;
      line-height: 1.2;
      color: #06204c;
      margin: 3px 0;
      padding: 0; }
      .nav-primary__wrapper .dropdown-menu a:active, .nav-primary__wrapper .dropdown-menu a:hover {
        background-color: transparent; }
      .nav-primary__wrapper .dropdown-menu a.col-title {
        font-family: "Montserrat", sans-serif;
        font-weight: 700;
        text-transform: uppercase;
        text-decoration: none;
        color: #0787e6; }
    .nav-primary__wrapper .dropdown-menu .sub-menu {
      padding: 0;
      list-style-type: none;
      padding-right: 20px;
      padding-top: 5px; }
      .nav-primary__wrapper .dropdown-menu .sub-menu > .menu-item {
        padding: 5px 0; }
    .nav-primary__wrapper .dropdown-menu .menu-item {
      padding: 5px 0;
      line-height: 1.2; }
      .nav-primary__wrapper .dropdown-menu .menu-item.no-title > a {
        display: none; }
      .nav-primary__wrapper .dropdown-menu .menu-item.no-title > .sub-menu {
        padding-top: 0;
        margin-top: -5px; }
      .nav-primary__wrapper .dropdown-menu .menu-item.col-title > a {
        font-family: "Montserrat", sans-serif;
        font-weight: 700;
        text-transform: uppercase;
        text-decoration: none; }
      .nav-primary__wrapper .dropdown-menu .menu-item.col-title.blue > a {
        color: #0787e6; }
      .nav-primary__wrapper .dropdown-menu .menu-item.col-title.orange > a {
        color: #F78505; }
      .nav-primary__wrapper .dropdown-menu .menu-item.col-title.red > a {
        color: #C61C78; }
      .nav-primary__wrapper .dropdown-menu .menu-item.col-title.hide {
        padding-top: 0; }
        .nav-primary__wrapper .dropdown-menu .menu-item.col-title.hide > a {
          display: none; }
        .nav-primary__wrapper .dropdown-menu .menu-item.col-title.hide > .sub-menu {
          padding-top: 0; }
      .nav-primary__wrapper .dropdown-menu .menu-item.bold > a {
        font-weight: 700;
        text-decoration: underline; }
    .nav-primary__wrapper .dropdown-menu .discover_navigation {
      flex-wrap: nowrap;
      margin-right: 180px; }
      .nav-primary__wrapper .dropdown-menu .discover_navigation > li {
        width: 180px; }
    .nav-primary__wrapper .dropdown-menu .programmes_navigation {
      flex-wrap: nowrap;
      margin-right: 180px; }
      .nav-primary__wrapper .dropdown-menu .programmes_navigation > li {
        width: 220px; }
    .nav-primary__wrapper .dropdown-menu .etudiant_navigation__wrapper {
      flex-wrap: nowrap;
      padding: 0;
      list-style-type: none;
      flex-direction: row;
      display: flex; }
      .nav-primary__wrapper .dropdown-menu .etudiant_navigation__wrapper > li {
        width: 220px; }
    .nav-primary__wrapper .dropdown-menu .documents_navigation {
      padding: 0;
      list-style-type: none; }
      .nav-primary__wrapper .dropdown-menu .documents_navigation a {
        font-weight: 700;
        text-decoration: underline; }

footer.content-info .footer-content .campus a {
  text-decoration: underline; }

footer.content-info .footer-content .copyrights {
  border-top: 2px solid #0787e6; }

body.home .news-home ul li a.brossard-tab {
  background-color: #0787e6; }

body.home .news-home ul li a.longueuil-tab {
  background-color: #F78505; }

body.home .news-home ul li a.candiac-tab {
  background-color: #C61C78; }

body.home .news-home .tab-content .tab-pane#brossard-news {
  background-color: #0787e6; }

body.home .news-home .tab-content .tab-pane#longueuil-news {
  background-color: #F78505; }

body.home .news-home .tab-content .tab-pane#candiac-news {
  background-color: #C61C78; }
