.faq{
    &__header{
        font-family: $font-secondary;
        font-size: 15px;
        font-weight: $font-secondary-bold;
        line-height: 1.2;
        color: $blue-primary;
        margin-bottom: 12px;
    }
    &__row{
        font-family: $font-family-base;
        font-size: 14px;
        line-height: 1.2;
        color: $blue-primary;
        margin-bottom: 10px;
    }
}
