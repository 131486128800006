.faq-accordian{
    &__wrapper{
        border-radius: 0;
        border: 0;
        &.mtl{
            .faq-accordian__single .card-button{
                color: $mtl-color;
            }
        }
        &.south-shore{
            .faq-accordian__single .card-button{
                color: $south-color;
            }
        }
    }
    &__single{
        border-radius: 0;
        border: 0;
        border-bottom: 2px solid $grey;
        margin-bottom: 30px;
        .card-header{
            background-color: #fff;
            padding: 16px 20px;
            border: 0;
            border-radius: 0;
            position: relative;
            .card-title{
                font-family: $font-family-base;
                font-size: 18px;
                font-weight: $font-base-bold;
                line-height: 1.2;
                color: $blue-primary;
            }
            .card-button{
                position: absolute;
                width: 53px;
                height: 53px;
                top: 0;
                right: 0;
                display: block;
                vertical-align: middle;
                &[aria-expanded="true"]{
                    &:before{
                        content: $icon-moins;
                    }
                }
                &:before{
                    content: $icon-plus;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    color: $primary;
                    font-family: '#{$icons-font-family}' !important;
                    font-size: 14px;
                    line-height: 0.75;
                }
            }
        }
        .card-body{
            padding: 15px 20px 20px;
        }
    }
}
