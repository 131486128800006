.filterAccordion{
    .card{
        background: transparent;
        border: 0;
    }
    .card-header, .card-body{
        bottom: 0;
        padding: 0;
        margin: 0;
        background: transparent;
        border: 0;
    }
    .card-header{
        .primary-button{
            &:focus{
                background-color: $primary;
            }
            i{
                font-size: 18px;
                padding-right: 10px;
            }
        }
    }
    .collapse {
        @include media-breakpoint-up(md) {
            display: block !important;
        }
    }
}

.programmeFilters{
    &__wrapper{
        display: flex;
        padding: 20px;
        background-color: $primary;
        margin-bottom: 30px;
        flex-wrap: wrap;
        @include media-breakpoint-up(md) {
            flex-wrap: nowrap;
        }
    }
    &__title{
        font-family: $font-secondary;
        font-size: 14px;
        font-weight: $font-secondary-bold;
        line-height: 1.2;
        color: $blue-primary;
        text-transform: uppercase;
        // margin-top: 45px;
        margin-bottom: 10px;
    }
    &__single{
        display: flex;
        flex-direction: column;
        width: 100%;
        margin: 0 10px 10px;
        @include media-breakpoint-up(md) {
            width: calc(33% - 20px);
            margin-bottom: 0;
        }
        &--title{
            font-family: $font-family-base;
            font-size: 14px;
            font-weight: $font-base-bold;
            line-height: 1.2;
            color: #fff;
            margin-bottom: 10px;
        }
    }
    &__button{
        @include reset-button;
        text-align: left;
        background: #fff;
        font-family: $font-family-base;
        font-size: 12px;
        line-height: 1.2;
        color: $blue-primary;
        padding: 10px 35px 10px 10px;
        position: relative;
        overflow: hidden;
        white-space: nowrap;
        width: 100%;
        height: 35px;
        &::after{
            content: $icon-down-arrow;
            font-family: '#{$icons-font-family}' !important;
            font-size: 15px;
            color: $primary;
            position: absolute;
            top: 50%;
            right: 10px;
            transform: translateY(-50%);
            border: 0;
            width: auto;
            height: auto;
            margin: 0;
            z-index: 2;
        }
        &::before{
            content: '';
            height: 100%;
            width: 35px;
            background: -moz-linear-gradient(left, rgba(0,0,0,0) 0%, rgba(255,255,255,0.3) 7%, rgba(255,255,255,1) 23%, rgba(255,255,255,1) 100%); /* FF3.6-15 */
            background: -webkit-linear-gradient(left, rgba(0,0,0,0) 0%,rgba(255,255,255,0.3) 7%,rgba(255,255,255,1) 23%,rgba(255,255,255,1) 100%); /* Chrome10-25,Safari5.1-6 */
            background: linear-gradient(to right, rgba(0,0,0,0) 0%,rgba(255,255,255,0.3) 7%,rgba(255,255,255,1) 23%,rgba(255,255,255,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
            filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#ffffff',GradientType=1 ); /* IE6-9 */
            position: absolute;
            right: 0;
            top: 0;
            z-index: 1;
        }
    }
    &__dropdown{
        margin: 0;
        border: 0;
        border-radius: 0;
        padding: 0;
        width: 100%;
        box-shadow: 0 13px 26px -9px rgba(0,0,0,0.4);
        ul{
            list-style-type: none;
            padding: 0;
            margin: 0;
            li{
                a{
                    display: block;
                    font-family: $font-family-base;
                    font-size: 12px;
                    line-height: 1.2;
                    color: $blue-primary;
                    padding: 7px 10px;
                    &:hover{
                        background-color: $grey;
                        text-decoration: none;
                    }
                }
            }
        }
    }
}
