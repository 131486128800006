footer.content-info {
    .footer-content {
        .campus a{
            text-decoration: underline;
        }
        .copyrights {
            border-top: 2px solid $primary;
        }
    }
}
