.EtudiantUnJour{
    &__intro{
        font-family: $font-family-base;
        font-size: 21px;
        line-height: 1.2;
        color: $blue-primary;
        text-align: center;
        padding-bottom: 20px;
        @include media-breakpoint-up(lg) {
            padding-bottom: 35px;
            font-size: 27px;
        }
        @include media-breakpoint-up(xl) {
            padding-bottom: 50px;
            font-size: 30px;
        }

    }
    &__wrapper{
        padding-top: 70px;
        padding-bottom: 90px;
        .usefullinks__wrapper{
            border: 2px solid $primary;
            padding: 30px 100px 20px;
            font-family: $font-secondary;
            font-size: 14px;
            font-weight: $font-secondary-normal;
            line-height: 1.3;
            // color: $primary;
            margin-top: 70px;
            margin-bottom: 30px;
            .primary-button{
                margin-top: -25px;
            }
        }
    }
}
