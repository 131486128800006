.listing-cards{
    background: $grey;
    padding: 50px 0 70px;
    .card-columns{
        column-gap: 30px;
    }
}
.tile-news{
    &__wrapper{
        background-color: #fff;
        border: 0;
        position: relative;
        display: block;
        text-decoration: none;
        padding-bottom: 0 !important;
        margin-bottom: 30px !important;
        overflow: hidden;
        border-radius: 0;
        width: 100%;
        @include media-breakpoint-up(sm) {
            padding-bottom: 31px!important;
        }
        @include media-breakpoint-up(xl) {
            padding-bottom: 21px!important;
        }
        &:hover{
            text-decoration: none;
            @include media-breakpoint-up(xl) {
                .primary-button{
                    opacity: 1;
                }
            }
        }
        .card-body{
            padding: 30px;
            @include media-breakpoint-up(md) {
                padding: 20px;
            }
            @include media-breakpoint-up(xl) {
                padding: 30px;
            }
        }
        .primary-button{
            padding: 10px 30px;
            text-align: left;
            transition: all ease .3s;
            @include media-breakpoint-up(sm) {
                position: absolute;
                bottom: 0
            }
            @include media-breakpoint-up(xl) {
                opacity: 0;
            }
        }
    }
    &__image{
        display: block;
    }
    &__date{
        font-family: $font-secondary;
        font-size: 14px;
        font-weight: $font-secondary-semibold;
        line-height: 1.2;
        color: $primary;
        margin-bottom: 10px;
        @include media-breakpoint-up(md) {
            font-size: 13px;
            margin-bottom: 15px;
        }
        @include media-breakpoint-up(xl) {
            font-size: 14px;
            margin-bottom: 10px;
        }
    }
    &__title{
        font-family: $font-secondary;
        font-size: 18px;
        font-weight: $font-secondary-bold;
        line-height: 1.2;
        color: $blue-primary;
        margin-bottom: 20px;
        display: inline-block;
        @include media-breakpoint-up(md) {
            font-size: 15px;
            margin-bottom: 10px;
        }
        @include media-breakpoint-up(xl) {
            font-size: 18px;
            margin-bottom: 20px;
        }
        &:hover{
            color: $blue-primary;
        }
    }
    &__text{
        font-family: $font-secondary;
        font-size: 14px;
        font-weight: $font-secondary-normal;
        line-height: 1.2;
        color: $blue-primary;
    }
}
