body{
    &.page-template-template-faq{
        .detailsService{
            &__wrapper{
                padding-top: 74px;
                padding-bottom: 115px;
            }
            &__type{
                font-family: $font-secondary;
                font-size: 15px;
                font-weight: $font-secondary-bold;
                line-height: 1.2;
                color: $primary;
                margin-bottom: 15px;
                span{
                    font-weight: $font-secondary-normal;
                    &:before{
                        content: '—';
                        display: inline-block;
                        padding-left: 20px;
                        padding-right: 5px;
                    }
                }
            }
        }
        .wysiwyg{
            margin-bottom: 40px;
            ul{
                li{
                    padding-bottom: 15px;
                    padding-left: 2.2em;
                }
                & > li{
                    &:before{
                        content: $icon-check;
                        font-family: '#{$icons-font-family}' !important;
                        padding-right: 5px;
                        color: $primary;
                    }
                }
            }
        }
    }
}
