.page-banner-flat {
    background: url('../images/fond_bleu_degrade.jpg') repeat-x;
    background-size: auto 100%;
    &__wrapper {
        background-repeat: no-repeat;
        background-size: auto 100%;
        background-position: center;
        overflow: hidden;
        & > .container{
            position: relative;
            &:before{
                content: '';
                width: 100vw;
                height: 100%;
                position: absolute;
                left: 50%;
                top: 0;
                transform: translateX(-50%);
                background-color: rgba(6,32,76,.7);
                @include media-breakpoint-up(lg) {
                    background: none;
                    display: none;
                }
            }
        }
    }
    &__container {
        margin-top: 100px;
        @include media-breakpoint-up(lg) {
            background: none;
            padding-top: 0;
            position: relative;
            padding-bottom: 33%;
        }
    }
    &__content {
        color: $white;
        font-size: 12px;
        line-height: 1.125rem;
        padding-bottom: 25px;
        @include media-breakpoint-up(lg) {
            position: absolute;
            width: 50%;
            top: 50%;
            transform: translateY(-30%);
            padding-bottom: 0;
            font-size: 14px;
        }
        h1, .primary-title{
            font-size: 23px;
            color: $white;
            text-transform: uppercase;
            @include media-breakpoint-up(lg) {
                font-size: 36px;
            }
            // margin-bottom: 20px;
        }
        a {
            color: #fff;
            text-decoration: underline;
        }
        .teccart-breadcrumb{
            margin-bottom: 13px;
            @include media-breakpoint-up(lg) {
                margin-bottom: 20px;
            }
        }
    }
}
