.post-navigation{
    &__menu-wrapper{
        background-color: $grey;
        display: flex;
        justify-content: center;
        flex-direction: row;
        list-style-type: none;
        margin-bottom: 0;
        li{
            margin: 0 3px;
            &.active a{
                color: #fff;
                background-color: $blue-primary;
            }
            a{
                @extend .primary-button;
                padding: 9px 16px;
            }
        }
    }
}
