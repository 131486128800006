@import "common/variables";
@font-face {
    font-family: '#{$icons-font-family}';
    src:  url('../../../teccart/dist/fonts/#{$icons-font-family}.eot?c12jqb');
    src:  url('../../../teccart/dist/fonts/#{$icons-font-family}.eot?c12jqb#iefix') format('embedded-opentype'),
      url('../../../teccart/dist/fonts/#{$icons-font-family}.ttf?c12jqb') format('truetype'),
      url('../../../teccart/dist/fonts/#{$icons-font-family}.woff?c12jqb') format('woff'),
      url('../../../teccart/dist/fonts/#{$icons-font-family}.svg?c12jqb##{$icons-font-family}') format('svg');
    font-weight: normal;
    font-style: normal;
}

// Automatically injected Bower dependencies via wiredep (never manually edit this block)
// bower:scss
@import "../../bower_components/bootstrap/scss/bootstrap.scss";
@import "../../bower_components/slick-carousel/slick/slick.scss";
// endbower

@import "../../../teccart/assets/styles/common/fonts.scss";
@import "../../../teccart/assets/styles/base.scss";

@import "../../../teccart/assets/styles/layouts/header";
@import "layouts/footer";
@import "pages/home";
