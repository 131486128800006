.programmeTeaser{
    &__wrapper{
        background-color: #fff;
        position: relative;
        overflow: hidden;
        padding: 20px 30px 20px 15px;
        margin-bottom: 30px;
        display: flex;
        flex-direction: column;
        @include media-breakpoint-up(lg) {
            padding: 32px 180px 50px 29px;
        }
        // height: 190px;
        &:before{
            content: '';
            width: 165px;
            height: 100%;
            position: absolute;
            top: 0;
            right: 10px;
            transform: translateX(165px);
            transition: transform ease .3s;
            background-color: $primary;
        }
        &:hover{
            @include media-breakpoint-up(xl) {
                &::before{
                    transform: translateX(10px);
                }
                .programmeTeaser__infos{
                    color: #fff;
                    .details-link{
                        color: #fff;
                    }
                }
            }
        }
        &.mtl{
            &:before{
                background-color: $mtl-color;
            }
            .programmeTeaser__type{
                color: $mtl-color;
            }
        }
        &.south-shore{
            &:before{
                background-color: $south-color;
            }
            .programmeTeaser__type{
                color: $south-color;
            }
        }
    }
    &__type{
        font-family: $font-secondary;
        font-size: 12px;
        font-weight: $font-secondary-bold;
        line-height: 1.2;
        color: $primary;
        margin-bottom: 5px;
        @include media-breakpoint-up(lg) {
            font-size: 15px;
        }
    }
    &__title{
        font-family: $font-secondary;
        font-size: 15px;
        font-weight: $font-secondary-bold;
        line-height: 1.2;
        color: $blue-primary;
        margin-bottom: 15px;
        display: inline-block;
        @include media-breakpoint-up(lg) {
            font-size: 24px;
        }
        &:hover{
            color: $blue-primary;
        }
    }
    &__infos{
        transition: color ease .2s;
        font-family: $font-family-base;
        font-size: 14px;
        line-height: 1.2;
        color: $blue-primary;
        order: 2;
        margin-top: 15px;
        @include media-breakpoint-up(lg) {
            width: 150px;
            position: absolute;
            top: 50%;
            right: 33px;
            transform: translateY(-50%);
            text-align: right;
            margin-top: 0;
        }
        .duration{
            padding-bottom: 5px;
        }
        .campus{
            margin-bottom: 20px;
            @include media-breakpoint-up(lg) {
                margin-bottom: 35px;
            }
        }
        .details-link{
            color: transparent;
            transition: color ease .2s;
            position: relative;
            padding-right: 10px;
            font-family: $font-secondary;
            font-size: 14px;
            font-weight: $font-secondary-bold;
            line-height: 1.2;
            &.mtl{
                color: $mtl-color;
            }
            &.south-shore{
                color: $south-color;
            }
            &:before{
                content: $icon-right-arrow;
                font-family: '#{$icons-font-family}' !important;
                position: absolute;
                top: 50%;
                left: 100%;
                transform: translateY(-50%);
            }
        }
    }
    &__description{
        font-family: $font-family-base;
        font-size: 14px;
        color: $blue-primary;
    }
}
