body.page-template-template-groupe-teccart, body.page-template-template-groupe-teccart-child{
    .groupe-teccart{
        &__wrapper{
            padding-top: 35px;
            padding-bottom: 50px;
            @include media-breakpoint-up(lg) {
                padding-top: 50px;
                padding-bottom: 100px;
            }
            @include media-breakpoint-up(xl) {
                padding-top: 66px;
                padding-bottom: 115px;
            }
        }
        &__intro{
            font-family: $font-secondary;
            font-size: 21px;
            font-weight: $font-secondary-light;
            line-height: 1.2;
            color: $blue-primary;
            padding-bottom: 20px;
            @include media-breakpoint-up(lg) {
                padding-bottom: 35px;
                font-size: 27px;
            }
            @include media-breakpoint-up(xl) {
                padding-bottom: 50px;
                font-size: 30px;
            }
        }
        &__single{
            margin-bottom: 50px;
            @include media-breakpoint-up(lg) {
                margin-bottom: 0;
            }
            &--title{
                font-family: $font-secondary;
                font-size: 18px;
                font-weight: $font-secondary-bold;
                line-height: 1.2;
                color: $blue-primary;
                text-transform: uppercase;
                position: relative;
                margin-top: 35px;
                @include blue-underline;
                @include media-breakpoint-up(lg) {
                    font-size: 22px;
                }
            }
            &--etablissement{
                margin-top: 30px;
                @include media-breakpoint-up(lg) {
                    margin-top: 40px;
                }
                &--type{
                    font-family: $font-family-base;
                    font-size: 14px;
                    font-weight: 700;
                    color: $blue-primary;
                    padding-bottom: 3px;
                }
                &--nom{
                    font-family: $font-secondary;
                    font-size: 18px;
                    font-weight: 700;
                    line-height: 1.2;
                    color: $blue-primary;
                    padding-bottom: 12px;
                }
                &--adresse{
                    font-family: $font-secondary;
                    font-size: 14px;
                    font-weight: 400;
                    color: $blue-primary;
                    p{
                        margin-bottom: 12px;
                    }
                }
                &--website{
                    font-family: $font-family-base;
                    font-size: 14px;
                    font-weight: 700;
                    color: $primary;
                    text-decoration: underline;
                    display: inline-block;
                }
                &--map{
                    font-family: $font-family-base;
                    font-size: 14px;
                    font-weight: 700;
                    color: $primary;
                    text-decoration: underline;
                    display: inline-block;
                    margin-bottom: 8px;
                }
            }
        }
    }
}
