.services{
    &__sidebar{
        margin-top: 70px;
        margin-bottom: 115px;
        display: block;
        & > * {
            margin-bottom: 33px;
            display: block;
        }
    }
}
