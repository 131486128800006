.news-details{
    &__wrapper{
        padding: 30px 0;
        @include media-breakpoint-up(lg) {
            padding: 50px 0;
        }
        @include media-breakpoint-up(xl) {
            padding: 65px 0 85px;
        }
        .news-date{
            font-family: $font-secondary;
            font-size: 14px;
            font-weight: $font-secondary-semibold;
            line-height: 1.2;
            color: $primary;
            margin-bottom: 10px;
        }
        .article-share__wrapper{
            margin: 20px 0 30px;
            @include media-breakpoint-up(xl) {
                margin: 30px 0 40px;
            }
        }
    }
}
