body.page-template-template-content_et_menu{
    .content__wrapper {
        padding: 30px 0;
        @include media-breakpoint-up(lg) {
            padding: 65px 0;
        }
        @include media-breakpoint-up(xl) {
            padding: 65px 15px 65px 4vw;
        }
    }
}
