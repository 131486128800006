.informations{
    &__wrapper{
        padding: 0;
        margin: 10px 0 20px;
        list-style-type: none;
        @include media-breakpoint-up(lg) {
            border-top: 1px solid $primary;
            margin: 45px 0 25px 0;
        }
        li{
            position: relative;
            padding-right: 30px;
            padding-top: 15px;
            padding-bottom: 15px;
            border-bottom: 1px solid $primary;
            @include media-breakpoint-up(xl) {
                padding-top: 25px;
                padding-bottom: 25px;
            }
            a{
                font-family: $font-secondary;
                font-size: 15px;
                font-weight: $font-secondary-bold;
                line-height: 1.2;
                color: $blue-primary;
                display: block;
                @include media-breakpoint-up(xl) {
                    font-size: 18px;
                }
                &:before{
                    content: $icon-pdf;
                    font-family: '#{$icons-font-family}' !important;
                    font-size: 24px;
                    font-weight: 400;
                    position: absolute;
                    top: 50%;
                    right: 0;
                    transform: translateY(-50%);
                    color: $primary;
                }
            }
        }
    }
}
