.frais{
    &__header{
        font-family: $font-secondary;
        font-size: 15px;
        font-weight: $font-secondary-bold;
        line-height: 1.2;
        color: #fff;
        background: $primary;
        padding: 10px 0;
        margin: 0;
    }
    &__row{
        font-family: $font-family-base;
        font-size: 14px;
        line-height: 1.2;
        color: $blue-primary;
        padding-top: 12px;
        padding-bottom: 12px;
        margin: 0;
        border-left: 1px solid $grey;
        border-right: 1px solid $grey;
        &:last-child{
            border-bottom: 1px solid $grey;
        }
    }
}
