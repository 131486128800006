.page-banner-big {
    background: url('../images/home-banner-tile.jpg') repeat-x;
    background-size: auto 100%;
    &__wrapper {
        background-repeat: no-repeat;
        background-size: auto 100%;
        background-position: center;
    }
    &__container {
        position: relative;
        padding-bottom: 58%;
    }
    &__content {
        width: 50%;
        position: absolute;
        top: 50%;
        transform: translateY(-53%);
        color: $white;
        font-size: 0.875rem;
        line-height: 1.125rem;
        p {
            margin-bottom: 8px;
        }
        strong {
            font-family: $font-secondary;
            font-weight: $font-secondary-light;
            font-size: 1.125rem;
        }
        h1 {
            text-transform: uppercase;
            font-family: $font-secondary;
            font-weight: $font-secondary-bold;
            font-size: 2.25rem;
            margin-bottom: 20px;
        }
        a {
            color: #fff;
            text-decoration: underline;
        }
        .teccart-breadcrumb{
            margin-bottom: 20px;
        }
    }
}
