.nav-primary__wrapper{
    .menu-teaser__wrapper{
        position: absolute;
        width: 180px;
        height: 100%;
        top: 0;
        right: 0;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        background-color: $primary;
        .menu-item{
            height: 33.33333%;
            border-bottom: 1px solid #fff;
            display: flex;
            align-items: center;
            padding: 0 !important;
            &:last-child{
                border: 0;
            }
            a{
                font-family: $font-secondary;
                font-weight: $font-secondary-bold;
                font-size: 13px;
                color: $white !important;
                padding-left: 26px;
                padding-right: 20px;
            }
        }
    }
}
