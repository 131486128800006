.page-banner-home {
    background: url('../images/home-banner-tile.jpg') repeat-x;
    background-size: auto 100%;
    padding-top: 70px;
    @include media-breakpoint-up(md) {
        padding-top: 0;
    }
    &__wrapper {
        background-repeat: no-repeat;
        background-size: auto 100%;
        background-position: center;
    }
    &__container {
        position: relative;
        padding-bottom: 50px;
        @include media-breakpoint-up(lg) {
            padding-bottom: 58%;
        }
    }
    &__content {
        color: $white;
        font-size: 0.875rem;
        line-height: 1.125rem;
        padding-top: 50px;
        @include media-breakpoint-up(md) {
            padding-top: 100px;
        }
        @include media-breakpoint-up(lg) {
            width: 41%;
            position: absolute;
            top: 50%;
            transform: translateY(-53%);
            padding-top: 0px;
        }
        p {
            margin-bottom: 8px;
        }
        strong {
            font-family: $font-secondary;
            font-weight: $font-secondary-light;
            font-size: 1.125rem;
        }
        h1 {
            text-transform: uppercase;
            font-family: $font-secondary;
            font-weight: $font-secondary-bold;
            margin-bottom: 20px;
            font-size: 25px;
            line-height: 1.2;
            @include media-breakpoint-up(lg) {
                font-size: 31px;
            }
            @include media-breakpoint-up(xl) {
                font-size: 35px;
            }
        }
        a {
            @extend .primary-button;
            @extend .primary-button.arrow;
            margin-top: 15px;
            position: relative;
            @include media-breakpoint-down(sm) {
                width: 100%;
                max-width: 240px;
            }
            &:first-child{
                margin-top: 40px;
            }
        }
    }
}
