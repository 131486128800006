body.page-template-template-activites {
    .content__wrapper {
        padding: 30px 0;
        @include media-breakpoint-up(lg) {
            padding: 65px 0;
        }
        @include media-breakpoint-up(xl) {
            padding: 65px 15px 65px 4vw;
        }
    }
    .activity {
        &__single{
            padding: 20px 0;
            &:first-child{
                padding-top: 0;
            }
            &:last-child{
                padding-bottom: 0;
            }
            & > .row {
                padding-bottom: 35px;
                &:first-child{
                    padding-bottom: 15px;
                }
            }
            &--title{
                @extend .primary-subtitle;
                &.mtl{
                    color: $mtl-color;
                }
                &.south-shore{
                    color: $south-color;
                }
            }
            .activity-gallery{
                &__wrapper{
                    position: relative;
                }
                &__arrow{
                    @include reset-button;
                    width: 30px;
                    height: 30px;
                    position: absolute;
                    bottom: 0;
                    right: 0;
                    color:#fff;
                    background-color: $primary;
                    transition: all ease 0.3s;
                    &:hover{
                        background-color: lighten($color: $primary, $amount: 8%)
                    }
                    i{
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                    }
                    &.slick-prev{
                        right: 30px;
                        transform: rotate(180deg);
                    }
                }
            }
            .col-lg-6.wysiwyg {
                padding-top: 15px;
                @include media-breakpoint-up(lg) {
                    padding-top: 0;
                }
            }
        }
    }
}
