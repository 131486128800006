[class^="icon-"], [class*=" icon-"] {
  font-family: '#{$icons-font-family}' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-equalizer:before {
    content: "\e90e";
}
.icon-menu:before {
    content: "\e90d";
}
.icon-close:before {
    content: "\e90c";
}
.icon-bus:before {
    content: "\e90b";
}
.icon-check {
    &:before{
        content: $icon-check;
    }
}
.icon-facebook {
    &:before{
        content: $icon-facebook;
    }
}
.icon-mail {
    &:before{
        content: $icon-mail;
    }
}
.icon-moins {
    &:before{
        content: $icon-moins;
    }
}
.icon-plus {
    &:before{
        content: $icon-plus;
    }
}
.icon-share {
    &:before{
        content: $icon-share;
    }
}
.icon-pdf {
    &:before{
        content: $icon-pdf;
    }
}
.icon-down-arrow {
    &:before{
        content: $icon-down-arrow;
    }
}
.icon-right-arrow {
    &:before{
        content: $icon-right-arrow;
    }
}
.icon-facebook_logo {
    &:before {
        content: $icon-facebook_logo;
    }
}
.icon-instagram_logo {
    &:before {
        content: $icon-instagram_logo;
    }
}
