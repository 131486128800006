body{
    &.page-template-template-history{
        .history{
            &__wrapper{
                padding-top: 75px;
                padding-bottom: 75px;
            }
        }
        .boite_sidebar{
            &__wrapper{
                margin-top: 75px;
                margin-bottom: 75px;
            }
        }
    }
}
