body.error404{
    .page-content{
        padding: 60px 0;
        @include media-breakpoint-up(sm) {
            padding: 130px 0 200px;
        }
        h1{
            font-size: 30px;
            color: $black;
            text-transform: uppercase;
            margin-top: 40px;
            margin-bottom: 15px;
            @include media-breakpoint-up(md) {
                font-size: 40px;
                margin-top: 0;
            }
        }
        .content-404{
            text-align: center;
            color: $black;
            font-size: 13px;
            @include media-breakpoint-up(md) {
                text-align: left;
            }
            .primary-button{
                margin: 30px 0;
            }
        }
    }
}
