@mixin reset-button{
    outline: 0;
    background: transparent;
    border: 0;
    cursor: pointer;
}

@mixin blue-underline {
    margin-bottom: 35px;
    padding-bottom: 15px;
    @include media-breakpoint-up(lg) {
        margin-bottom: 46px;
        padding-bottom: 26px;
    }
    &:before{
        content: '';
        position: relative;
        width: 60px;
        height: 3px;
        background-color: $blue;
        position: absolute;
        top: 100%;
        left: 0;
        @include media-breakpoint-up(lg) {
            width: 83px;
        }
    }
}
