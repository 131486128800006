footer.content-info {
	.more-infos {
		background-color: #f2f2f2;
        color: $blue-primary;
        &__bg{
            background-position: top center;
            background-size: auto 34vw;
            background-repeat: no-repeat;
            padding-top: 34vw;
            @include media-breakpoint-up(lg) {
                background-position: top right;
                background-size: auto 100%;
                padding-top: 0;
            }
        }
        &__content{
            padding: 30px 0;
            @include media-breakpoint-up(lg) {
                margin-right: auto;
                padding: 40px 0;
                width: 33%;
            }
            @include media-breakpoint-up(xl) {
                padding: 95px 0;
            }
        }
		.actions-text {
			font-family: $font-secondary;
			font-weight: $font-secondary-light;
            font-size: 15px;
            line-height: 1.2;
            @include media-breakpoint-up(lg) {
                font-size: 18px;
            }
            p{
                margin-bottom: 10px;
            }
			strong {
				display: block;
				font-weight: $font-secondary-bold;
				font-size: 17px;
                margin-bottom: 17px;
                @include media-breakpoint-up(lg) {
                    font-size: 25px;
                    margin-bottom: 12px;
                }
			}
			em {
				font-family: $font-family-base;
				font-style: normal;
				font-size: 0.875rem;
			}
		}
		.footer_actions {
            display: flex;
            flex-direction: column;
            padding-top: 25px;
            @include media-breakpoint-up(md) {
                flex-direction: row;
            }
            @include media-breakpoint-up(lg) {
                display: block;
                padding-top: 10px;
            }
            a{
                display: block;
                width: 100%;
                margin: 5px 0;
                @extend .primary-button.arrow;
                @include media-breakpoint-only(md) {
                    width: 50%;
                    margin: 5px;
                }
                @include media-breakpoint-only(lg) {
                    width: 100%;
                    margin: 5px;
                }
                @include media-breakpoint-up(xl) {
                    width: auto;
                    display: inline-block;
                    padding: 14px 19px;
                    &:first-child {
                        margin-right: 30px;
                    }
                    &:before{
                        display: none;
                    }
                }
            }
		}
	}
	.footer-content {
		padding: 20px 0 35px;
		background-color: $blue-primary;
        color: $white;
        @include media-breakpoint-up(lg) {
            padding-top: 50px;
            padding-bottom: 20px;
        }
		.brand {
            margin-bottom: 20px;
            @include media-breakpoint-up(lg) {
                margin-bottom: 38px;
            }
		}
		.tagline {
            margin: 10px 0 40px;
            @include media-breakpoint-up(lg) {
                margin-bottom: 15px;
                padding-right: 60px;
            }
            h5{
                font-family: $font-secondary;
                font-size: 15px;
                font-weight: $font-secondary-bold;
                line-height: 1.2;
                color: $white;
                text-transform: uppercase;
                @include media-breakpoint-up(lg) {
                    font-size: 18px;
                }
            }
        }
        a{
            color: #fff;
        }
		.campus {
            font-weight: 600;
            font-size: 12px;
            padding-bottom: 10px;
            text-align: center;
            @include media-breakpoint-up(md) {
                text-align: left;
                font-size: 14px;
                padding-bottom: 20px;
            }
            @include media-breakpoint-up(lg) {
                padding-bottom: 80px;
                line-height: 1.5;
            }
            h5{
                font-family: $font-secondary;
                font-weight: $font-secondary-bold;
                font-size: 13px;
                margin-bottom: 7px;
                @include media-breakpoint-up(md) {
                    font-size: 15px;
                }
            }
            a{
                display: inline-block;
                @include media-breakpoint-up(lg) {
                    margin-top: 25px;
                }
            }
			&.campus-mtl, .longueil {
                color: $mtl-color;
				a {
					color: $mtl-color;
				}
			}
			&.campus-south, .longueuil {
                color: $south-color;
				a {
					color: $south-color;
				}
            }
            &.institut, .brossard {
                color: $primary;
				a {
					color: $primary;
				}
			}
		}
		.socials {
			p {
				font-family: $font-secondary;
				font-weight: $font-secondary-bold;
				font-size: 1.125rem;
				line-height: 1.375rem;
				margin-bottom: 0.75rem;
			}
			ul {
				list-style: none;
				padding: 0;
				margin: 0;
				li {
                    display: inline-block;
                    a{
                        font-size: 27px;
                        transition: opacity ease .3s;
                        opacity: 1;
                        margin-right: 17px;
                        display: inline-block;
                        padding: 2px;
                        &:hover{
                            text-decoration: none;
                            opacity: .6;
                        }
                    }
				}
			}
        }
        .phone, .phone a{
            color :#fff;
            font-size: 13px;
            font-weight: 700;
            text-decoration: none;
            text-align: center;
            @include media-breakpoint-up(md) {
                text-align: left;
            }
            @include media-breakpoint-up(xl) {
                font-size: 15px;
            }
            p{
                margin: 0;
            }
        }
        .footer_navigation{
            flex-direction: column;
            padding: 30px 0 0;
            @include media-breakpoint-up(md) {
                flex-direction: row;
                padding: 0;
            }
            .menu-item{
                text-align: center;
                @include media-breakpoint-up(md) {
                    padding-right: 20px;
                    text-align: left;
                }
                a{
                    color :#fff;
                    font-size: 13px;
                    font-weight: 600;
                    text-decoration: none;
                    text-transform: uppercase;
                }
            }
        }
		.copyrights {
            width: 100%;
			padding-top: 30px;
            font-size: 10px;
            margin-top: 30px;
            @include media-breakpoint-up(lg) {
                margin-top: 50px;
                padding-top: 20px;
                font-size: 12px;
            }
			.confidential {
                margin: 15px 0;
                display: inline-block;
                @include media-breakpoint-up(md) {
                    margin: 0;
                }
                @include media-breakpoint-up(xl) {
                    margin-left: 35px;
                }
			}
		}
	}
}
