.detailsProgramme{
    &__wrapper{
        padding: 30px 15px;
        @include media-breakpoint-up(lg) {
            padding: 65px 0;
        }
        @include media-breakpoint-up(xl) {
            padding: 65px 15px 65px 4vw;
        }
        &.mtl{
            .detailsProgramme{
                &__type{
                    color: $mtl-color;
                }
                &__objectif{
                    background-color: $mtl-color;
                }
                &__advantages--listing li::before{
                    color: $mtl-color;
                }
            }
            .frais__header{
                background-color: $mtl-color;
            }
            .faq-accordian__single .card-header .card-button:before{
                color: $mtl-color;
            }
            .wysiwyg ul>li:before{
                color: $mtl-color;
            }
        }
        &.south-shore{
            .detailsProgramme{
                &__type{
                    color: $south-color;
                }
                &__objectif{
                    background-color: $south-color;
                }
                &__advantages--listing li::before{
                    color: $south-color;
                }
            }
            .frais__header{
                background-color: $south-color;
            }
            .faq-accordian__single .card-header .card-button:before{
                color: $south-color;
            }
            .wysiwyg ul>li:before{
                color: $south-color;
            }
        }
        .wysiwyg{
            padding: 20px 0 40px;
            h1{
                font-size: 24px;
                text-transform: uppercase;
            }
            h2{
                margin-bottom: 30px;
            }
        }
    }
    &__type{
        font-family: $font-secondary;
        font-size: 15px;
        font-weight: $font-secondary-bold;
        line-height: 1.2;
        color: $primary;
        margin-bottom: 15px;
        &.mtl{
            color: $mtl-color;
        }
        &.south-shore{
            color: $south-color;
        }
        span{
            font-weight: $font-secondary-normal;
            &:before{
                content: '—';
                display: inline-block;
                padding-left: 20px;
                padding-right: 5px;
            }
        }
    }
    &__objectif{
        width: 100%;
        padding: 30px 15px;
        background-color: $primary;
        @include media-breakpoint-up(lg) {
            padding: 48px 30px;
        }
        &.mtl{
            background-color: $mtl-color;
        }
        &.south-shore{
            background-color: $south-color;
        }
        &--title{
            font-family: $font-secondary;
            font-size: 15px;
            font-weight: $font-secondary-bold;
            line-height: 1.2;
            color: #fff;
            margin-bottom: 10px;
            @include media-breakpoint-up(lg) {
                margin-bottom: 17px;
            }
        }
        &--quote{
            font-family: $font-secondary;
            font-size: 18px;
            font-weight: $font-secondary-bold;
            line-height: 1.2;
            color: #fff;
            letter-spacing: -0.1px;
            @include media-breakpoint-up(lg) {
                font-size: 24px;
            }
        }
    }
    &__advantages{
        &--title{
            font-family: $font-secondary;
            font-size: 15px;
            font-weight: $font-secondary-bold;
            line-height: 1.2;
            color: $blue-primary;
            margin-bottom: 20px;
        }
        &--listing{
            ul {
                list-style-type: none;
                padding: 0;
                margin: 0;
                li{
                    padding-left: 2.2em;
                    position: relative;
                    padding-bottom: 12px;
                    &:before{
                        content: $icon-check;
                        font-family: '#{$icons-font-family}' !important;
                        padding-right: 5px;
                        color: $primary;
                        position: absolute;
                        left: 0;
                    }
                }
            }
        }
    }
}
