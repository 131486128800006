.responsive-menu {
    &__toggle {
        @include reset-button;
        color: #fff;
        font-size: 23px;
        @include media-breakpoint-up(sm) {
            font-size: 26px;
        }
    }
    &__wrapper {
        .modal-dialog {
            margin: 0 auto;
            height: 100vh;
            background-color: $blue-primary;
            max-width: none;
        }
        .modal-content{
            background-color: $blue-primary;
            border: 0;
        }
        .modal-header{
            border: 0;
            padding: 20px 15px 0;
            .logo{
                max-width: 180px;
                width: 100%;
                @include media-breakpoint-up(lg) {
                    max-width: 265px;
                }
            }
        }
        .modal-body{
            border: 0;
            padding: 30px 0 0 0;
            margin: 0;
        }
        .close {
            color: #fff;
            text-shadow: none;
            font-style: normal;
            opacity: 1 !important;
            padding: 0;
            margin: 0 0 0 auto;
            &:hover{
                color: #fff !important;
            }
        }
    }
}
